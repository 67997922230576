import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { counselingApi } from '../pages/councelling/counsellingApiSlice';
import thunk from 'redux-thunk';
import counselorSlice from '../pages/councelling/counselingSlice';
import overlayLoadingSlice from './overlayloader/overlayLoadingSlice';
import commonSlice from './common/commonSlice';
import { commonApiSlice } from './common/commonApiSlice';



const store = configureStore({
  reducer: {
    ...rootReducer,
    [counselingApi.reducerPath]: counselingApi.reducer,
    [commonApiSlice.reducerPath]: commonApiSlice.reducer,
    counsellor : counselorSlice,
    overlayloader : overlayLoadingSlice,
    common:commonSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk, counselingApi.middleware, commonApiSlice.middleware),
  devTools: true,
});

export default store;
