import React, { useState, useEffect } from "react";
import classes from "./styles.module.scss";
import { Pagination, Stack } from "@mui/material";

const BasicTable = (props) => {
    // console.log(props)
    const [allLabels, setAllLabels] = useState([])
    useEffect(() => {
        if (props.columns && props.columns.length > 0) {
            let allLabels = props.columns.map((c) => { return c.name });
            // console.log(allLabels);
            setAllLabels(allLabels)
        }
    }, [props.rows])

    return (
        <>
            <h4>{props.title}</h4>
            <table className={classes.tableDesign}>
                <thead>
                    <tr>
                        {
                            props.columns.map((c, i) => (
                                c.name === 'subject' && !props.autoWidth ? <th style={{width:"400px"}} key={i}>{c.label}</th> : <th key={i}>{c.label}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.rows && props.rows.length > 0 && props.rows.map((r, i) => (
                            <tr onClick={() => props?.customTable ? props.navigateInvoice(r.id) : ""}>
                                {
                                    allLabels && allLabels.length > 0 && allLabels.map((a, j) => (
                                        <td>
                                            {r[a]}
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className={classes.pagination}>
                <div className={classes.count}>
                    {/* <span>Records Per Page</span>
                    <FormControl sx={{ m: 1, minWidth: 70 }} size="small">
                        <InputLabel id="demo-select-small">set</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={count}
                            label=""
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                        </Select>
                    </FormControl> */}
                </div>
                {
                    props.hidePagination
                        ?
                        null
                        :
                        <div>
                            <Stack spacing={2}>
                                <Pagination
                                    count={props.total}
                                    variant="outlined"
                                    shape="rounded"
                                    page={props.page}
                                    onChange={props.handlePage}
                                />
                            </Stack>
                        </div>
                }

            </div>
        </>
    );
};

export default BasicTable;
