import React, { useState, useEffect } from "react";
import classes from "./styles.module.scss";

const PlanButton = (props) => {
    // console.log(props)
    return (
        <>
            <button onClick={() => props.handleDisplay(props.value)} className={props.color}><i className={props.icons}></i>&nbsp;&nbsp;{props.text}</button>
        </>
    );
};
export default PlanButton;
