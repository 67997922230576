import React, { useEffect } from "react";
import classes from "./styles.module.scss";
import GridViewIcon from '@mui/icons-material/GridView';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import EmailIcon from '@mui/icons-material/Email';
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded';
import logoIcon from "../../../assets/customAssets/images/logo-icon-dot.png";
import { NavLink, Link } from "react-router-dom";
import "./active.css";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { useCallback } from "react";
import { setIsLeftBarExtended } from "../../../store/common/commonSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const LeftBar = () => {

    const dispatch = useDispatch()

    const isExtended = useSelector((state) => state.common.isLeftBarExtended)

    const handleExtendToggle = useCallback(() => {
        dispatch(setIsLeftBarExtended(!isExtended))
    }, [isExtended]);


    return (
        <div className={`${classes.left_bar} ${isExtended ? classes.extend : null}`}>

            {/* <div className={classes.short_logo}>
                <img src={logoIcon} alt="double dot" />
                <span>P</span>
            </div> */}
            <div onClick={handleExtendToggle} className={classes.extend_btn}> <ExpandCircleDownIcon />
                <span class={classes.tooltip}>Expand Menu</span>
            </div>

            <div className={classes.menu_list}>
                <ul className={classes.icons_list}>

                    {/* <NavLink to="/dashboard/dash">
                        <li>
                            <GridViewIcon />
                            <p>Dashboard</p>
                            <div className={classes.triangle}></div>
                        </li>
                    </NavLink> */}
                    <NavLink to="/dashboard/question">
                        <li className={`${isExtended ? "extend" : null}`}>
                            <AssignmentIcon />
                            {!isExtended ? <p>Question</p> : <div>Question</div>}
                            {/* <div className={classes.triangle}></div> */}
                        </li>
                    </NavLink>
                    <NavLink to="/dashboard/upload-answer">
                        <li>
                            <CloudUploadIcon />
                            {!isExtended ? <p>Upload Answer</p> : <div>Upload Answer</div>}
                            {/* <div className={classes.triangle}></div> */}
                        </li>
                    </NavLink>
                    <NavLink to="/dashboard/answer">
                        <li>
                            <CalendarMonthIcon />
                            {!isExtended ? <p>Answer</p> : <div>Answer</div>}
                            {/* <div className={classes.triangle}></div> */}
                        </li>
                    </NavLink>



                    {/* <NavLink to="/dashboard/feedback">
                        <li>
                            <RateReviewRoundedIcon />
                            <p>Feedback</p>
                            <div className={classes.triangle}></div>
                        </li>
                    </NavLink> */}





                    <NavLink to="/dashboard/evaluated">
                        <li>
                            <PictureAsPdfIcon />
                            {!isExtended ? <p>Evaluated Answer Sheet</p> : <div>Evaluated Answer</div>}
                            {/* <div className={classes.triangle}></div> */}
                        </li>
                    </NavLink>
                    <NavLink to="/dashboard/orders">
                        <li>
                            <AssignmentTurnedInIcon />
                            {!isExtended ? <p>My Subscription</p> : <div>My Subscription</div>}
                            {/* <div className={classes.triangle}></div> */}
                        </li>
                    </NavLink>
                    <NavLink to="/dashboard/counseling/created">
                        <li>
                            <PeopleAltIcon />
                            {!isExtended ? <p>Counseling</p> : <div>Counseling</div>}
                        </li>
                    </NavLink>

                    {/* <NavLink to="/dashboard/refer">
                        <li>
                            <SupervisorAccountIcon />
                            <p>Referral</p>
                            <div className={classes.triangle}></div>
                        </li>
                    </NavLink> */}

                    <NavLink to="/dashboard/download-center">
                        <li>
                            <SimCardDownloadIcon />
                            {!isExtended ? <p>Download</p> : <div>Download</div>}
                        </li>
                    </NavLink>
                    <NavLink to="/dashboard/planner">
                        <li>
                            <CalendarTodayIcon style={{ marginLeft: "3px" }} fontSize="small" />
                            {!isExtended ? <p>Planner</p> : <div>Planner</div>}
                        </li>
                    </NavLink>

                    <NavLink className={classes.account} to="/dashboard/account">
                        <li>
                            <AccountBoxIcon />
                            {!isExtended ? <p>Account</p> : <div>Account</div>}
                        </li>
                    </NavLink>



                </ul>
            </div>

            <div className={classes.log_out}>
                <ul className={classes.icons_list}>
                    <NavLink to="/logout">
                        <li>
                            <p>Logout</p>
                            <LogoutRoundedIcon />
                            {!isExtended ? null : <div>Logout</div>}
                        </li>
                    </NavLink>
                </ul>
            </div>
        </div>
    )
}
export default LeftBar;