import { apiFunction } from "../../apiCalls/functions/department";
import { PROFILE, STATE_DETAILS, STATE_DISTRICT, DISTRICT_DETAILS, COURSES, SYLLABUS, SESSION, PROFILE_UPDATE, UPDATE_EMAIL, EMAIL_OTP } from "../../apiCalls/urls/profile";
import { apiLoading } from "../../store/notification/action";
import * as actionTypes from "./actionTypes";
import { actionNotifier } from "../../components/ui/toast";
import { apiLoadingOverlay, apiLoadingOverlayEnd } from "../../store/overlayloader/overlayLoadingSlice";




export const getProfile = async () => {
    //alert("profile")
    return await apiFunction(PROFILE, 'get', {}, false, true)
}

export const fetchState = async () => {
    return await apiFunction(STATE_DETAILS, 'get', {}, false, true)
}

export const fetchStateDistrict = async (stID) => {
    return await apiFunction(STATE_DISTRICT + stID + '/districts', 'get', {}, false, true)
}

export const fetchDistrictCity = async (id) => {
    return await apiFunction(DISTRICT_DETAILS + id + '/cities', 'get', {}, false, true)
}

export const fetchCourse = async () => {
    return await apiFunction(COURSES, 'get', {}, false, true)
}
export const getSubjectAndPortion = async (syllabusId) => {
    return await apiFunction(`/data/syllabus/${syllabusId}/groups`, 'get', {}, false, false)
}
export const fetchCourseSyllabus = async (id) => {
    return await apiFunction(SYLLABUS + id + '/syllabus', 'get', {}, false, true)
}

export const editProfileData = async (data) => {
    return await apiFunction(PROFILE, 'PUT', data, false, true)
}
export const fetchSession = async (id) => {
    return await apiFunction(SESSION + id, 'get', {}, false, true)
}

export const editProfileImage = async (data) => {
    return await apiFunction(PROFILE_UPDATE, 'post', data, false, true, "file")
}
export const emailOTP = async (data) => {
    return await apiFunction(EMAIL_OTP, 'POST', data, false, true)
}
export const emailSubmit = async (data) => {
    return await apiFunction(UPDATE_EMAIL, 'POST', data, false, true)
}


export const onLoad = () => {
    return async (dispatch) => {
        dispatch(onGetProfile())
    }
}


export const onGetProfile = () => {
    return async (dispatch) => {
        let result = await getProfile();

        let _defaultData = {
            "institutionId": 1,
            "syllabusId": 3,
            "Syllabus": {
                "name": "FINAL",
                "courseId": 1,
                "Course": {
                    "name": "CA"
                }
            },
            "courseId": 1,
            "Course": {
                "name": "CA"
            },
            "currentSession": {
                "id": 3,
                "name": "May 2024",
                "description": null,
                "startDate": "2023-10-01T00:00:00.000Z",
                "endDate": "2024-06-30T00:00:00.000Z",
                "institutionId": 1,
                "createdAt": "2023-10-02T09:35:10.243Z",
                "updatedAt": "2023-10-02T09:35:10.243Z"
            }
        }

        
        if (result.status) {
            dispatch(updateProfile({...result.data, currentSession:result.data.currentSession || {
                "id": 2,
                "name": "May 2024",
                "description": null,
                "startDate": "2023-11-30T00:00:00.000Z",
                "endDate": "2024-05-01T00:00:00.000Z",
                "institutionId": 1,
                "createdAt": "2023-07-28T09:10:01.494Z",
                "updatedAt": "2023-11-22T10:29:39.071Z"
            }}))
        }

        if (result.data) {
            if (!result.data.syllabusId && !result.data.Syllabus && !result.data.syllabusId && !result.data.Course && result.data.currentSession) {
                _defaultData = { ...result.data, ..._defaultData}
                if (result.status) {
                    dispatch(updateProfile(_defaultData))
                }
            } else {
                if (result.status) {
                    dispatch(updateProfile(result.data))
                }
            }
        }        
    }
}

export function onEmailSubmit(data, close) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())
        let result = await emailSubmit(data);
        if (result.status) {
            dispatch(onGetProfile())
            actionNotifier.success("Email Updated!")
            close()
        } else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingOverlayEnd())
    }
}
export function onEmailOTP(data) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())
        let result = await emailOTP(data);
        if (result.status) {
            actionNotifier.success("OTP sent you email.")
        } else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingOverlayEnd())
    }
}

export function onEditProfileImage(data) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())
        //console.log(data)
        let formData = new FormData();
        formData.append('image', data);
        let result = await editProfileImage(formData);
        // console.log(result)
        if (result.status) {
            actionNotifier.success("Profile Updated")
            dispatch(onGetProfile())

        }
        dispatch(apiLoadingOverlayEnd())
    }
}


export function onEditProfile(editProfile, close) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())

        // console.log(editProfile)
        let data = {
            firstName: editProfile.firstName,
            middleName: editProfile.middleName,
            lastName: editProfile.lastName,
            email: editProfile.email,
            courseId: Number(editProfile.courseId),
            syllabusId: Number(editProfile.syllabusId),
            stateId: Number(editProfile.stateId),
            districtId: Number(editProfile.districtId),
            cityId: Number(editProfile.cityId),
        }

        let result = await editProfileData(data);
        // console.log(result)
        if (result.status) {
            dispatch(onGetProfile())
            actionNotifier.success("Profile Updated")
            close()
        } else {
            actionNotifier.error("Error in saving data!")
        }
        dispatch(apiLoadingOverlayEnd())
    }
}


// export function onSaveProfile(editProfile){
//     return async(dispatch) => {
//         let data = {
//             name: editProfile.firstName,
//             email: editProfile.email,
//             course: editProfile.courseId,
//             syllabusId: Number(editProfile.syllabusId),
//             state: Number(editProfile.state),
//             districtId: Number(editProfile.districtId),
//             cityId: Number(editProfile.cityId),
//         }
//         let result = await saveProfile(data);

//         if(result.status){
//             dispatch(updateProfileDetails(result.data))
//         }
//     }
// }







//ACTIONS
export const updateProfile = (profile) => {
    return {
        type: actionTypes.UPDATE_PROFILE,
        payload: profile
    }
}

export const updateProfileDetails = (data) => {
    return {
        type: actionTypes.UPDATE_PROFILE_DETAILS,
        payload: { data }
    }
}