import React, { useEffect, useState } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    position:'absolute',
    inset:0,
    zIndex:998,
    height: 6,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: "0px",
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
    '&.progress-fade': {
        // transition: 'opacity 0.3s ease-in-out',
        opacity: 0,
    },
}));

const ReactProgressBar = ({ loading }) => {
    const [progress, setProgress] = useState(0);
    const [hidden, setHidden] = useState(!loading);

    useEffect(() => {
        let timer;
        let isUnmounted = false;

        if (loading) {
            setProgress(0);
            setHidden(false);
            timer = setInterval(() => {
                if (!isUnmounted) {
                    setProgress((prevProgress) => {
                        const newProgress = prevProgress + 10;
                        if (newProgress >= 100) {
                            clearInterval(timer);
                            setTimeout(() => setHidden(true), 300);
                            return 100;
                        }
                        return newProgress;
                    });
                }
            }, 25);
        } else {
            setHidden(true);
            isUnmounted = true;
            setProgress(0)
        }

        return () => {
            isUnmounted = true;
            clearInterval(timer);
        };
    }, [loading]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <BorderLinearProgress
                className={hidden ? 'progress-fade' : ''}
                variant="determinate"
                value={progress}
            />
        </Box>
    );
};

export default ReactProgressBar;
