import React, { useState, useEffect } from "react";
import BasicModal from "../../basicModal";
import CustomCheckbox from "../customCheckbox";
import classes from "./styles.module.scss";

const Table = (props) => {
    //console.log(props)
    const [allLabels, setAllLabels] = useState([])

    useEffect(() => {
        if (props.rows && props.rows.length > 0) {
            let allLabels = Object.keys(props.rows[0]);
            setAllLabels(allLabels)
        }
    }, [props.rows])


    let find = props?.checked?.find((f) => f.groupId == props?.groupId)
    //console.log(find)
    return (
        <>

            <span className={classes.title}>{props.title}</span>
            <label style={{ float: "right" }}>
                <CustomCheckbox checked={props?.checked?.length > 0 && find ? find?.checked : false} onClick={(e) => props.handleAllCheckBox(e)} text="Select All" />
            </label>
            {/* <input type="checkbox" onClick={(e) => props.handleAllCheckBox(e)} /> */}
            <table className={classes.category_table}>
                <thead>
                    <tr>
                        {
                            props.columns.map((c, i) => (
                                <th title={i == 0 ? "Click on portion to select all subject" : "Click to select all subject"} key={i} className={classes.tableHeadingText} >{c.label}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.rows && props.rows.length > 0 && props.rows.map((r, i) => (
                            <tr>
                                {
                                    allLabels && allLabels.length > 0 && allLabels.map((a, j) => (
                                        <td title={j != 0 ? "" : "Click to select all potion"}>
                                            {r[a]}
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    );
};

export default Table;
