import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../apiCalls/rtkBaseQuery';

export const commonApiSlice = createApi({
  baseQuery,
  reducerPath: 'commonApiSlice',
  endpoints: (builder) => ({
    getReasons: builder.query({
      query: () => ({
        url: '/data/reasons',
        method: 'GET',
      }),
    }),
    getConfiguration: builder.query({
      query: () => ({
        url: '/data/configuration',
        method: 'GET',
      }),
    }),
    getLanguages: builder.query({
      query: () => ({
        url: '/data/languages',
        method: 'GET',
      })
    }),
    getPendingOrderCRM: builder.query({
      query: () => ({
        url: '/order/pending-order-crm',
        method: 'GET',
      }),
    }),
    getPlanner: builder.query({
      query: () => ({
        url: '/order/planners',
        method: 'POST',
      }),
    }),
    getDownloadCenter: builder.query({
      query: () => ({
        url: '/order/download-centre',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetReasonsQuery,
  useGetConfigurationQuery,
  useGetLanguagesQuery,
  useGetPendingOrderCRMQuery,
  useGetPlannerQuery,
  useGetDownloadCenterQuery
} = commonApiSlice;

