import React, { useState } from "react";
import classes from "./styles.module.scss";
import logo from '../../../assets/customAssets/images/logo.png';
import { dateIndian } from '../../../shared/dateFunction';
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
const BillHeader = (props) => {
    const navigate = useNavigate();
    const companyData = props.companyData;
    const billerData = props.billerData;
    const invoiceData = props.invoiceData;

    const dashboard = () => {
        if (props.order?.id)
            navigate("/dashboard/question")
    }
    const goBack = () => {
        // window.location.href = "/dashboard/question";
        navigate(-1)
    }
    const downloadInvoice = () => {
        //window.print()
        props.handlePrint()
    }
    return (
        <>
            {
                !props.from && props.order?.id ?

                    <table className={classes.logo_body}>
                        <tr className={classes.print}>
                            <td colspan="2" className={classes.text_align}>
                                {/* <DownloadIcon className={classes.home_logo} onClick={() => downloadInvoice()} /> */}

                                <div className={classes.download_bundle_plan_invoice}>
                                    <button onClick={() => goBack()}>
                                        Go Back
                                    </button>
                                    <button onClick={() => downloadInvoice()}>
                                        Download Order Details
                                    </button>
                                </div>

                            </td>
                        </tr>
                    </table>
                    : ""
            }
            <div className={classes.biller_details_div}>
                <div>
                    {/* <img onClick={(e) => dashboard()} src={logo} alt="..." /> */}
                    <img style={{ height: "78px" }} src={logo} alt="..." />
                    <h3>Lime Learn Eduserv Private Limited</h3>
                    {companyData.address1}
                    <br />
                    {companyData.address2}
                    <br />GSTIN: {companyData.gstin}
                    <br />CIN: {companyData.cin}
                    <br />PAN Number: {companyData.panNumber}
                </div>
                <div></div>
                {/*<div>
                    Email - {companyData.email}
                    <br />
                    Website- {companyData.website}
                    <br />
                    Phone: {companyData.phone}
                </div>*/}
            </div>
            <h3 className={classes.heading}>{props.order?.id ? "" : 'PROFORMA '} ORDER DETAILS</h3>
            <div className={classes.biller_details_div}>
                <div>
                    {
                        props?.addressDetails?.length != 0 ?

                            <p className={classes.biller_address}>
                                <div className={classes.biller_address_details} >
                                    <b>Bill To</b>
                                    <br />
                                    {
                                        props.selectedAddress ?
                                            <span>
                                                {props.profile.firstName} {props.profile.middleName ? props.profile.middleName : ""} {props.profile.lastName ? props.profile.lastName : ""}<br />
                                                {props.selectedAddress?.address + ","} {props.selectedAddress?.City?.name} <br />
                                                {props.selectedAddress?.District?.name}, {props.selectedAddress?.District?.State?.name}, {props.selectedAddress?.pincode}<br />
                                            </span> : <span>
                                                {props.profile.firstName} {props.profile.middleName ? props.profile.middleName : ""} {props.profile.lastName ? props.profile.lastName : ""}<br />
                                                {props.order?.address + ","} {props.order?.city} <br />
                                                {props.order?.District?.name + ","} {props.order?.District?.State?.name + ","} {props.order?.pincode}<br />
                                                {props?.order?.gstNumber ? "GST Number: " + props?.order?.gstNumber : ""}
                                            </span>
                                    }

                                </div>
                            </p>
                            : <p className={classes.biller_address}>
                                <div className={classes.biller_address_details} >
                                    <b>Bill To</b>
                                    <br />
                                    <span>
                                        Mayank Agarwal<br />
                                        7428007394
                                    </span>
                                </div>
                            </p>
                    }
                </div>
                <div>
                    <p className={classes.biller_address}>
                        <b>{props.order?.id ? <>Order No : {props.order?.id}<br />
                            Payment Date : <b>{dateIndian(props.order?.createdAt)}</b></> : ""}</b>

                    </p>
                </div>
            </div>
            <div className={classes.biller_details_div}>
                <div>
                    <b>Test Details</b><br />
                    Exam Level: <b>{[...props.courseSyllabus?.course].map((e) => e).join(", ")}</b> (<b>{[...props.courseSyllabus?.syllabus].map((e) => e).join(", ")}</b>)<br />
                    Attempt: <b>{props.order?.sessions?.map(item => item.name).join(', ')}</b><br />
                    Test Preference: <b style={{ textTransform: "capitalize" }}>{props?.centerDetails?.preference == 'home' ? props?.centerDetails?.preference : <>{props?.centerDetails?.centerName?.city?.name} ({props?.centerDetails?.centerName?.name})</>}</b>
                </div>
            </div>
            <br />
        </>
    );
};

export default BillHeader;
