import React from "react";
import { Grid, Box } from "@mui/material/";
import classes from "./styles.module.scss";
import InvoiceBundleComponent from '../../../components/invoiceComponent/invoiceBundleComponent'
import { invoiceData } from './data'
const InvoiceBundleContainer = (props) => {

    //console.log(props);
    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                className={classes.invoice_conatiner}
            >
                <InvoiceBundleComponent invoiceData={invoiceData} />
            </Grid>

            {/* <div  className={classes.download_bundle_plan_invoice}>
                <button>
                    Download Invoice
                </button>
            </div> */}
        </>
    );
};

export default InvoiceBundleContainer;
