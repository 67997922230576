import { connect } from "react-redux";
import React, { useState } from "react";
import classes from "./styles.module.scss";
import profile from "../../../../assets/images/user.png";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BasicModal from "../../../../components/modal";
import { onLoad } from "../../../../pages/profile/action";
import { useEffect } from "react";
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import { onEditProfileImage } from "../../../../pages/profile/action";
import ProfileModal from "../../../../components/profileModal";
import { onSingUp } from "../../../../store/auth/actions";
import { checkPath } from "../../../../shared/social";
const NameCard = (props) => {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        props.onLoad()
    }, [])
    //console.log(props)
    const handleFileInput = (e) => {
        // handle validations        
        const file = e.target.files[0];
        const fileSize = Math.round((file.size / 1024));
        if (fileSize >= 4096)
            alert("File size cannot exceed more than 1MB");
        else
            props.onEditProfileImage(file)

    }
    return (
        <div className={classes.profileSection}>
            <div className={classes.profile}>
                <div className={classes.card}>
                    <>
                        {/* {
                     props.profile.allProfiles && props.profile.allProfiles.map((c, i) => {
                        return(
                            <>
                                <span className={classes.text_hello}>Hello,</span>
                                <br />
                                <span className={classes.name}>{c.firstName}</span>
                                <br />
                                <p className={classes.quote}>"Success consist of going from failure to failure without loosing enthusiasm"</p>
                                <div className={classes.edit} onClick={() => setShowModal(true)}> <ModeEditIcon /> &nbsp; Edit Profile</div>
                                <BasicModal open={showModal} close={() => setShowModal(false)} />
                            </>
                        )
                    })
                   } */}
                    </>
                    <span className={classes.text_hello}>Hello,</span>
                    <br />
                    <span className={classes.name}>{props.profile.allProfiles.firstName}</span>
                    <br />
                    <p className={classes.quote}>"Success consist of going from failure to failure without loosing enthusiasm"</p>
                    <div className={classes.edit} onClick={() => setShowModal(true)}> <ModeEditIcon /> &nbsp; Edit Profile</div>
                    <ProfileModal profile={props?.profile} onSingUp={props.onSingUp} open={showModal} setShowModal={setShowModal} close={() => setShowModal(false)} />
                    {/* <BasicModal open={showModal} close={() => setShowModal(false)} /> */}
                </div>

                {/* <div>
                    <img src={profile} />
                </div> */}


                <div className={classes.profile_upload}>
                    <div className={classes}>
                        <img src={checkPath(props?.profile?.allProfiles?.image)} />
                    </div>
                    <label for="profile"><AddAPhotoRoundedIcon /></label>
                    <input accept="image/png, image/gif, image/jpeg" type="file" id="profile" name="profile" onChange={handleFileInput} />
                </div>


            </div>

            {/* <div className={classes.link}>
                <div className={classes.url}>
                    <div> {process.env.REACT_APP_WEBSITE_NAME}.com/jonathon &nbsp; &nbsp; <span>|</span></div>&nbsp; <ContentCopyIcon />
                </div>
                <div className={classes.invite}>
                    <a>Invite Friends</a>
                </div>
            </div> */}
            {/* <div className={classes.logoutDiv}>
                <a href="/logout" className={classes.logout}><i className="fa fa-sign-out"></i> Logout</a>
            </div> */}
        </div>
    )
}

// export default NameCard;

const mapStateToProps = (state) => {
    return {
        profile: state.profile
    }
}

const mapDispatchToProps = { onLoad, onEditProfileImage, onSingUp }

export default connect(mapStateToProps, mapDispatchToProps)(NameCard);

