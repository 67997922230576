import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import classes from './styles.module.scss';
import InputField from '../inputField';
import Select from "react-select";
import PrimaryButton from '../button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect } from 'react';
import { useState } from 'react';
import { onLoad, fetchState, fetchStateDistrict, fetchDistrictCity, fetchCourse, fetchCourseSyllabus, onEditProfile } from "../../pages/profile/action";
import { connect } from "react-redux";
import InputSelect from '../inputSelect';
import CloseIcon from '@mui/icons-material/Close';
import $ from 'jquery';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4,
    p: 0,
    outline: "none"
};

const BasicModal = (props) => {
    //console.log(props)
    useEffect(() => {
        props.onLoad()
    }, [])

    const [state, setState] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        courseId: "",
        syllabusId: "",
        stateId: "",
        cityId: "",
        districtId: "",
        mobileNo: ""
    })
    // console.log(props)

    // const [profileCount, setProfileCount] = useState(0);
    // const [length, setLength] = useState(0)

    useEffect(() => {
        //console.log(props.profile.allProfiles)
        setState({
            firstName: props?.profile?.allProfiles?.firstName,
            middleName: props?.profile?.allProfiles?.middleName,
            lastName: props?.profile?.allProfiles?.lastName,
            email: props?.profile?.allProfiles?.email,
            courseId: props?.profile?.allProfiles?.courseId,
            syllabusId: props?.profile?.allProfiles?.syllabusId,
            stateId: props?.profile?.allProfiles?.District?.stateId,
            districtId: props?.profile?.allProfiles?.districtId,
            cityId: props?.profile?.allProfiles?.cityId,
            mobileNo: props?.profile?.allProfiles?.mobileNo
        })

        // let length = Object.keys(props.profile.allProfiles).length
        // let profileCount = 0;

        // Object.entries(props.profile.allProfiles).map(([key,value])=>{
        //     if(value == null)
        //     profileCount++
        // })

        // setProfileCount(profileCount);
        // setLength(length);

    }, [props?.profile?.allProfiles]);



    let length = Object.keys(state).length - 2
    let profileCount = 0;

    Object.entries(state).map(([key, value]) => {
        if (key != 'middleName' && key != 'lastName') {
            if (!value) {
                //console.log(key, value, profileCount);
                profileCount++
            }
        }
    })
    //console.log(profileCount, length)
    const [uState, setUState] = useState([])
    const [uStateDistrict, setUStateDistrict] = useState([])
    const [uStateDistrictCity, setUStateDistrictCity] = useState([])
    const [uCourse, setUCourse] = useState([])
    const [uCourseSyllabus, setUCourseSyllabus] = useState([])


    // State
    const fetchInitialData = async () => {
        let res = await fetchState()
        if (res.status) {
            setUState(res.data)
        }
    }
    useEffect(() => {
        fetchInitialData()
    }, [])

    //District
    const fetchDistrict = async (e) => {
        if (e) {
            let res = await fetchStateDistrict(e)
            if (res.status) {
                setUStateDistrict(res.data)
            }
        }
    }
    useEffect(() => {
        fetchDistrict(Number(state?.stateId))
    }, [state?.stateId])

    //City
    const fetchCity = async (e) => {
        if (e) {
            let res = await fetchDistrictCity(e)
            if (res.status) {
                setUStateDistrictCity(res.data)
            }
        }
    }
    useEffect(() => {
        fetchCity(Number(state?.districtId))
    }, [state?.districtId])




    //Course
    const fetchOriginData = async () => {
        let res = await fetchCourse()
        if (res.status) {
            setUCourse(res.data)
        }
    }
    useEffect(() => {
        fetchOriginData()
    }, [])

    //Syllabus
    const fetchSyllabus = async (e) => {
        // alert(e)
        if (e) {
            let res = await fetchCourseSyllabus(e)
            //console.log(res)
            if (res.status) {
                setUCourseSyllabus(res.data)
            }
        }
    }
    useEffect(() => {
        fetchSyllabus(Number(state?.courseId))
    }, [state?.courseId])






    const handleChange = (name, value) => {
        let _state = { ...state };
        _state[name] = value;
        setState(_state)
    }

    const handleSubmit = (e) => {
        //console.log(state)
        e.preventDefault();
        if (state) {
            props.onEditProfile(state, props.close)
        }
        //props.onLoad()
    }
    //let length = Object.keys(state).length
    let c = 0
    Object.entries(state).map(([key, value]) => {
        if (value == null)
            c++
    })
    //console.log(c)
    //Profile Calculation

    $('input[required]').on('keyup', function () {
        var required = $('input[required]').addClass('required'),
            completed = required.filter(function () {
                return this.value.trim().length > 0;
            }).removeClass('required');


        //if (completed.length) {
        //console.log((profileCount / length) * 100);
        $('#progress').val((profileCount / length) * 100);
        //}
    }).addClass('required')


    //console.log(profileCount, length)
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
            >
                <Box sx={style}>
                    <div className={classes.content}>
                        <div className={classes.header}>
                            <p><span>Update Your Details</span><span onClick={props.close}><CloseIcon /></span></p>
                        </div>
                        <div className={classes.form}>
                            <form className={classes.form_content} onSubmit={handleSubmit} >
                                <div className={classes.first_content}>
                                    <div className={classes.first_content_left}>
                                        <InputField
                                            required={true}
                                            type="text"
                                            width="100%"
                                            height="36px"
                                            placeholder="first name"
                                            label="First Name"
                                            value={state.firstName}
                                            onChange={(e) => handleChange("firstName", e.target.value)}
                                        />

                                        <InputField
                                            required={false}
                                            type="text"
                                            width="100%"
                                            height="36px"
                                            placeholder="last name"
                                            label="Last Name"
                                            value={state.lastName}
                                            onChange={(e) => handleChange("lastName", e.target.value)}
                                        />

                                        <InputField
                                            disabled={true}
                                            type="text"
                                            width="100%"
                                            height="36px"
                                            placeholder="Mobile No"
                                            label="Mobile No"
                                            value={state.mobileNo}
                                        />
                                        <div className={classes.select_field}>
                                            <InputSelect
                                                // required={true}
                                                value={state.syllabusId}
                                                options={uCourseSyllabus}
                                                name="syllabusId"
                                                placeholder="Enter Syllabus"
                                                label="Syllabus"
                                                onChange={(e) => handleChange("syllabusId", e.target.value)}
                                            />
                                        </div>

                                    </div>

                                    <div className={classes.first_content_right}>
                                        <InputField
                                            required={false}
                                            type="text"
                                            width="100%"
                                            height="36px"
                                            placeholder="middle name"
                                            label="Middle Name"
                                            value={state.middleName}
                                            onChange={(e) => handleChange("middleName", e.target.value)}
                                        />

                                        <InputField
                                            required={true}
                                            type="email"
                                            marginTop="0px"
                                            width="100%"
                                            height="36px"
                                            placeholder="eg abcd@gmail.com"
                                            label="Email"
                                            value={state.email}
                                            onChange={(e) => handleChange("email", e.target.value)}
                                        />
                                        <div className={classes.select_field}>
                                            <InputSelect
                                                // required={true}
                                                value={state.courseId}
                                                options={uCourse}
                                                name="course"
                                                placeholder="Enter Course"
                                                label="Course"
                                                onChange={(e) => handleChange("courseId", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>


                                <div className={classes.second_content}>
                                    <p>Address</p>
                                    <div className={classes.select_field}>
                                        <InputSelect
                                            // required={true}
                                            value={state.stateId}
                                            options={uState}
                                            name="stateId"
                                            placeholder="Enter State"
                                            label="State"
                                            onChange={(e) => handleChange("stateId", e.target.value)}
                                        />
                                    </div>
                                    <div className={classes.second_sub_content}>
                                        <div className={classes.second_sub_content_left}>
                                            <InputSelect
                                                // required={true}
                                                value={state.districtId}
                                                options={uStateDistrict}
                                                name="districtId"
                                                placeholder="Enter District"
                                                label="District"
                                                onChange={(e) => handleChange("districtId", e.target.value)}
                                            />
                                            <InputSelect
                                                // required={true}
                                                value={state.districtId}
                                                options={uStateDistrict}
                                                name="districtId"
                                                placeholder="Enter District"
                                                label="District"
                                                onChange={(e) => handleChange("districtId", e.target.value)}
                                                maxlength={6}
                                            />
                                        </div>
                                        <div className={classes.second_sub_content_right}>
                                            <InputSelect
                                                // required={true}
                                                options={uStateDistrictCity}
                                                value={state.cityId}
                                                name="cityId"
                                                placeholder="Enter City"
                                                label="City"
                                                onChange={(e) => handleChange("cityId", e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className={classes.calculation}>
                                        <meter id="progress" min="0" max={100} value={Math.round(100 - profileCount / length * 100)} title={Math.round(profileCount / length * 100) + "% left"} />
                                        <p>Profile is <span>{Math.round(100 - profileCount / length * 100)}%</span> completed.</p>
                                        {/* <meter id="progress" main="0" max={length} value={length-profileCount} title={Math.round(profileCount/length*100) + "% left"} />
                                        <p>Profile is <span>{Math.round(100 - profileCount/length*100)}%</span> completed.</p> */}
                                    </div>

                                </div>



                                <PrimaryButton
                                    buttonText={"save"}
                                    fontSize="12px"
                                    width="100%"
                                    height="38px"
                                    color="#fff"
                                    backgroundColor="#005ca8"
                                // onClick={alert(8)}
                                />
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

// export default BasicModal;

const mapStateToProps = (state) => {
    return {
        profile: state.profile
    }
}

const mapDispatchToProps = { onLoad, onEditProfile }

export default connect(mapStateToProps, mapDispatchToProps)(BasicModal);
