export const ADD_CART_CUSTOM = `/cart/custom-plan`
export const DELETE_CART_CUSTOM_RECORD = `/cart/custom-plan/custom-plan-record`
export const ADD_CART_BUNDLE = `/cart/bundle-plan`
export const CART_DETAILS = `/cart/`
export const CART_REFERRAL = `/data/referrals/`
export const APPLY_COUPON = `/cart/apply-coupon`
export const UPDATE_CENTER_PREFERENCE = `/cart/update-centre-preference-referral`
export const DELETE_COUPON = `/cart/remove-coupon`
export const GET_COUPON = `/cart/coupon/suggested/SITE`
export const CART_EMPTY = `/cart/empty-cart`
//edit order api url
export const EDIT_CART_ORDER = `/order/edit-order/`
export const EDIT_CART_CONFIRM_ORDER = `/order/confirm-order/`
export const EDIT_CART_PAYMENT_LINK = `/order/send-payment-link/`
export const EDIT_CART_ADD_CUSTOM = `/order/custom-plan/`
export const EDIT_CART_ADD_BUNDLE = `/order/bundle-plan/`
export const EDIT_CART_DELETE_CUSTOM = `/order/custom-plan/`
export const EDIT_CART_DELETE_CUSTOM_ID = `/order/custom-plan/custom-plan-record/`
export const EDIT_CART_DELETE_BUNDLE = `/order/bundle-plan/`