export const PROFILE = `/profile/`;

export const STATE_DETAILS = `/data/states/`;
export const STATE_DISTRICT = '/data/state/';
export const DISTRICT_DETAILS = '/data/district/';

export const COURSES = `/data/courses/`;
export const SYLLABUS = `/data/course/`;
export const SESSION = `/data/sessions/`;
export const PROFILE_UPDATE = `/profile/profile-picture`;

export const UPDATE_EMAIL = `/profile/update-email`;
export const EMAIL_OTP = `/profile/send-update-email-otp`;


