import { PropaneSharp } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import LeftBar from "./leftBar";
import RightBar from "./rightBar";
import TopBar from "./topBar";
import { setIsRightProfileOpen } from "../../store/common/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetPendingOrderCRMQuery } from "../../store/common/commonApiSlice";
import classes from "./styles.module.scss";
const Layout = (props) => {

    const dispatch = useDispatch();
    const isRightProfileOpen = useSelector(state => state.common.isRightProfileOpen);

    const handleClick = () => {
        dispatch(setIsRightProfileOpen(!isRightProfileOpen));

    };

    const { data } = useGetPendingOrderCRMQuery();
    const [pendingOrder, setPendingOrder] = useState("aDasfsg")

    // useEffect(() => {
    //     if (data?.paymentPending) {
    //         setPendingOrder(data?.message)
    //     } else {
    //         setPendingOrder(null)
    //     }
    // }, [data])

    const handleClose = () => {
        setPendingOrder(null);
    };

    useEffect(() => {
        if (isRightProfileOpen) {
            Promise.resolve(100)
            window.scrollTo({ left: 800, behavior: 'smooth' });
        }
    }, [isRightProfileOpen]);

    return (
        <div style={{ display: "flex" }}>
            <LeftBar />
            <TopBar handleClick={handleClick}>
                {props.children}
            </TopBar>
            {
                isRightProfileOpen ? <RightBar handleClick={handleClick} /> : null
            }

        </div>
    )
}

export default Layout;