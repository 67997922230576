import React from "react";
import classes from "./styles.module.scss";
const CustomCheckbox = (props) => {
    //console.log(props)
    return (
        <>
            <label className={props.size == 'small' ? classes.small_checkbox : classes.normal_checkbox}><input onClick={(e) => props.onClick(e)} checked={props.checked} className={props.size == 'small' ? classes.custom_checkbox_small : classes.custom_checkbox} type="checkbox" /> <span className={props.size == 'small' ? "" : classes.checkText}><b>{props.text ? props.text : ""}</b></span></label>
        </>
    );
};

export default CustomCheckbox;
