export const getReason = (status) => {
    const reasons = status?.slice()?.reverse()?.find((s, i) => s?.status === "CANCELLED_BY_STUDENT" || s?.status === "REJECTED_BY_COUNSELOR" || s?.status === "RESCHEDULED_BY_STUDENT" || s?.status === "RESCHEDULED_BY_COUNSELOR")
    // const reasons = status?.slice()?.reverse()?.find((s, i) => s?.status === activeTab)
    return reasons ? reasons?.extra?.reason : null
}

export const getNotes = (status) => {
    const reasons = status?.slice()?.reverse()?.find((s, i) => s?.status === "APPLIED")
    // const reasons = status?.slice()?.reverse()?.find((s, i) => s?.status === activeTab)
    return reasons ? reasons?.extra?.notes : null
}

export const getOnDateByStatus = (data, type) => {
    if (type === "acceptedOn") {
        const onDate = data.find((s) => s.status === "ACCEPTED_BY_COUNSELOR")?.onDate
        return onDate
    } else if (type === "completedAt") {
        const onDate = data.find((s) => s.status === "COMPLETED")?.onDate
        return onDate
    }
}

export const convertToTitleCase = (str) => {
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

export const toSentenceCase = (str) => {
    const words = str
        .replace(/_/g, ' ')
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .split(' ');

    return words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const addSpaces = (str) => {
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/_/g, ' ');
};
