import React from "react";
import classes from "./styles.module.scss";
import BasicTable from "../../components/basicTable";
import PageTableMobile from "../pageTableMobile";

const PageTable = (props) => {
    return (
        <div className={classes.qna}>
            <div className={classes.desktop}>
                <BasicTable
                    hidePagination={true}
                    rows={props.rows}
                    columns={props?.columns}
                />
            </div>
            <div className={classes.mobile}>
                <PageTableMobile
                    rows={props?.mobileRows}
                />
            </div>
        </div>
    )
}

export default PageTable;