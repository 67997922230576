import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button, DialogActions, Box, Icon } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import AssignmentIcon from '@mui/icons-material/Assignment';

const PopupDialog = ({
    open,
    onClose,
    title,
    subtitle,
    details,
    width,
    primaryActionLabel,
    onPrimaryAction
}) => {

    const dialogSx = {
        borderRadius: '12px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        padding: '20px',
    };

    const iconSx = {
        color: '#007bff',
        fontSize: '2rem',
        // marginBottom: '10px',
    };

    const titleSx = {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        marginBottom: '8px',
    };

    const subtitleSx = {
        fontSize: '1rem',
        color: '#6c757d', // muted text color
        marginBottom: '20px',
    };

    const detailsBoxSx = {
        border: '1px solid #dee2e6',
        borderRadius: '8px',
        padding: '15px',
        textAlign: 'left',
        backgroundColor: '#f8f9fa',
    };

    const detailRowSx = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        gap: '10px',
    };

    const detailIconSx = {
        color: '#6c757d',
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ sx: dialogSx, style: { width: width || '450px' } }}
        >
            <DialogTitle>
                <Box sx={titleSx}>
                    <CheckCircleOutlineIcon sx={iconSx} />
                    {title}
                </Box>
                <Typography variant="body2" sx={subtitleSx}>
                    {subtitle}
                </Typography>
            </DialogTitle>
            {details.length > 0 ? <DialogContent>
                <Box sx={detailsBoxSx}>
                    {details.map((detail, index) => (
                        <Box key={index} sx={detailRowSx}>
                            {detail?.icon && <Icon component={detail.icon} sx={detailIconSx} />}
                            <Typography variant="body1">{detail.text}</Typography>
                        </Box>
                    ))}
                </Box>
            </DialogContent> : null}
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button onClick={onPrimaryAction} variant="contained">
                    {primaryActionLabel || 'Done'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopupDialog;
