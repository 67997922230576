import * as actionTypes from './actionTypes'

const initialState = {
    allOrders: [],
    electiveSubject: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_QUESTIONS:
            state = {
                ...state,
                allOrders: action.payload.data,
            }
            break;
        case actionTypes.UPDATE_ELECTIVE_SUBJECT:
            state = {
                ...state,
                electiveSubject: action.payload.data,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default reducer;
