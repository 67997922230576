import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material/";
import classes from "./styles.module.scss";
import InvoiceCustomizeComponent from '../../../components/invoiceComponent/invoiceCustomizeComponent'
import { invoiceData } from './data'
import { connect } from "react-redux";
import { generateCustomizeInvoice } from "../../../shared/invoice";
import { calculateCustomizeDatas } from "../helper";


const InvoiceCustomizeContainer = (props) => {

    const [planName, setPlanName] = useState(null)
    const [rows, setRows] = useState(null)
    const [customizedData, setCustomizedData] = useState(null)


    useEffect(() => {
        if (props.invoice.orderData?.all_portions) {
            if (props.invoice.orderData.order?.OrderedCustomPlans?.length > 0) {
                let data = generateCustomizeInvoice(props.invoice.orderData.all_portions, props.invoice.orderData.order.OrderedCustomPlans, props.invoice.orderData.all_service)
                setRows(data.allData)
                setPlanName("custom")
            }
        }

    }, [props.invoice.orderData?.all_portions])

   

    useEffect(() => {
        if (rows) {
            const data = calculateCustomizeDatas(rows)
            setCustomizedData(data)
        }
    }, [rows])

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                className={classes.invoice_conatiner}
            >
                <InvoiceCustomizeComponent data={customizedData} invoiceData={invoiceData} />
            </Grid>

            {/* <div  className={classes.download_customize_plan_invoice}>
                <button>
                    Download Invoice
                </button>
            </div> */}
        </>
    );
};




const mapStateToProps = (state) => {
    return {
        invoice: state.Invoice,
    }
}

export default connect(mapStateToProps)(InvoiceCustomizeContainer);