import React, { useState, useEffect } from "react";
import classes from "./styles.module.scss";
import { Grid, MenuItem, Select } from '@mui/material/';
const MuiSelect = (props) => {
    return (
        <div className={`${""} ${classes.syllabusSelect}`}>
            {props.label ? <><label htmlFor="demo-simple-select" style={{ color: props.ltextColor }} >{props.label}</label><br /></> : null}
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.value || " "}
                className={`${classes.mui_select} ${props.color === 'grey' ? classes.mui_select_grey : ''}`}
                onChange={props.onChange}
            >
                {props.firstValue ? <MenuItem value={" "}><em>Please Select</em></MenuItem> : null}
                {props.options.map((c, i) => (
                    <MenuItem key={i} value={c.id || c.value}>
                        {c.name && c.name?.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, char => char.toUpperCase()) || c.label}
                    </MenuItem>
                ))}
            </Select>
            {props.error ? <><label style={{ color: "red", fontSize: "12px" }} >{props.error}</label><br /></> : null}
        </div >
    );
};
export default MuiSelect;
