import React from "react";
import classes from "./styles.module.scss";

const PageHeading = (props) =>{
    return(
        <span>
            <p className={classes.heading}>{props.head}</p>
        </span>
    )
}

export default PageHeading;