import * as actionTypes from './actionTypes';

const initialState = {
    allProfiles: [],
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.UPDATE_PROFILE:
            state = {
                ...state,
                allProfiles: action.payload,
            }
            break;
        case actionTypes.UPDATE_PROFILE_DETAILS:
            state = {
                ...state.allProfiles,
                allProfiles: action.payload,
            }
            break;
    }
    return state;
}

export default reducer;