import { apiFunction } from "../../apiCalls/functions/department"
import { QUESTION_LIST, DOWNLOAD_ALL_QUESTION, DOWNLOAD_ALL_ANSWER, GET_ELECTIVE_BY_SUBJECT_ID, SELECT_ELECTIVE_BY_SUBJECT_ID, UPLOAD_ANSWER_BY_SUBJECT_ID, UPDATE_LAST_VIEW, UPLOAD_ANSWER_PAGE, GET_UPLOAD_ANSWER_DATA } from "../../apiCalls/urls/questions";
import { actionNotifier } from "../../components/ui/toast";
import { apiLoading, apiLoadingEnd } from "../../store/notification/action";
import * as actionTypes from './actionTypes'
import fileDownload from 'js-file-download';
import { openInNewTab } from "../../shared/functions";
import { apiLoadingOverlay, apiLoadingOverlayEnd } from "../../store/overlayloader/overlayLoadingSlice";
import { throwError } from "../../shared/throwError";
export const fetchQuestions = async (data) => {
    return await apiFunction(QUESTION_LIST, 'POST', data, false, true)
}
export const downloadAll = async (data) => {
    return await apiFunction(DOWNLOAD_ALL_QUESTION, 'POST', data, false, true, 'blob')
}
export const downloadAllAnswer = async (data) => {
    return await apiFunction(DOWNLOAD_ALL_ANSWER, 'POST', data, false, 'blob')
}
export const getElectiveSubject = async (id) => {
    return await apiFunction(GET_ELECTIVE_BY_SUBJECT_ID + id, 'GET', {}, false, true)
}
export const setElectiveSubject = async (id) => {
    return await apiFunction(SELECT_ELECTIVE_BY_SUBJECT_ID + id, 'POST', {}, false, true)
}
export const uploadAnswerPage = async (subjectId, portionId, sessionId, questionId, data) => {
    // console.log(subjectId, portionId, sessionId, data);
    let formData = new FormData();
    formData.append("file", data.data)
    formData.append("feedback", JSON.stringify(data.feedback))
    return await apiFunction(UPLOAD_ANSWER_PAGE + subjectId + "/" + portionId + "/" + sessionId + "/" + questionId, 'POST', formData, false, true, "file")
}
export const getUploadPageData = async (questionId) => {
    return await apiFunction(GET_UPLOAD_ANSWER_DATA + questionId, 'GET', {}, false, true)
}
export const updateLastView = async (questionId) => {
    return await apiFunction(UPDATE_LAST_VIEW + questionId, 'POST', {}, false, true)
}

//AllAPIS
export function onLoad(data) {
    return async (dispatch) => {
        dispatch(onFetchQuestion(data))
    }
}

export function onGetElectiveSubject(id) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await getElectiveSubject(id);
        if (result.status) {
            dispatch(updateElectiveData(result.data))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onSetElectiveSubject(id, data) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())
        let result = await setElectiveSubject(id);
        if (result.status) {
            actionNotifier.success("Elective Paper Selected")
            dispatch(onFetchQuestion(data))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingOverlayEnd())
    }
}
export function onUpdateLastView(questionId, pdf) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())
        let result = await updateLastView(questionId);
        if (result.status) {
            actionNotifier.success("Last View Updated")
            openInNewTab(pdf)
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingOverlayEnd())
    }
}
export function onUploadAnswerPage(subjectId, portionId, sessionId, data, questionId, setFiles, fetchUploadData, setOpen, handleSubmitFeedback, onClose) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())

        try {
            const feedback = await handleSubmitFeedback();
            // if (!feedback || Object.keys(feedback).length === 0) {
            //     actionNotifier.error("Feedback is required before uploading.");
            //     dispatch(apiLoadingOverlayEnd());
            //     return;
            // }

            const _data = { feedback, data };

            let result = await uploadAnswerPage(subjectId, portionId, sessionId, questionId, _data);

            if (result?.data?.id) {
                fetchUploadData();
            } else {
                throwError(result?.data?.message || "Upload failed.");
            }
        } catch (error) {
            // console.error("🚀 ~ Upload Error:", error);
            throwError(error.message, "Answer Not Uploaded");
        } finally {
            setFiles({ file: null, portionId: null });
            onClose();
            dispatch(apiLoadingOverlayEnd());
        }
    }
}
//API ACTIONS
export function onDownloadAll(data, name) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())
        if (name != 'answer') {
            let result = await downloadAll(data);
            // console.log(result);
            let fileName = name + Date.now() + '.zip';
            fileDownload(result, fileName);
        } else {
            let result = await downloadAllAnswer(data);
            // console.log(result);
            let fileName = name + Date.now() + '.zip';
            fileDownload(result, fileName);
        }
        dispatch(apiLoadingOverlayEnd())
    }
}
export function onFetchQuestion(data) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchQuestions(data);
        if (result.status) {
            dispatch(updateQuestions(result.data))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}

//ACTIONS
export const updateQuestions = (data) => {
    return {
        type: actionTypes.UPDATE_QUESTIONS,
        payload: { data }
    };
}
export const updateElectiveData = (data) => {
    return {
        type: actionTypes.UPDATE_ELECTIVE_SUBJECT,
        payload: { data }
    };
}
