export const COUNSELLOR_LIST = `/counselings/list`;
export const CANCEL_COUNSELLING = `/counselings/cancel-counseling/`;
export const RESHEDULE_COUNSELING = `/counselings/reschedule-counseling/`;
export const COUNSELING_DETAILS = `/counselings/details/`;
export const REQUEST_COUNSELING = `/counselings/apply`;
export const GET_SUGGESTED_COUNSELLOR = `/counselings/request`;
export const GET_LANGUAGE_ID = `/data/dropdown`;
export const GET_COUNSELOR_DETAILS = `/counselings/counselor-details/`;
export const GET_COUNSELING_PRICES = `/counselings/update-counseling-prices/`;
export const ACCEPT_RESCHEDULED_COUNSELING = `/counselings/accept-reschedule-counseling/`;
export const GET_COUNSELING_TYPE_PRICES = `/data/counseling-type-and-prices`;
export const GET_ORDERS = `/counselings/orders`;
export const PURCHASE_COUNSELING = `/counselings/purchase`;
export const SAVE_COUNSELING_STATUS = `/payment/save-status-counseling/`;
export const GET_ORDER_GROUP_SUBJECT = `/counselings/subjects/`;
export const GET_COUNSELLING_LIST_COUNT = `/counselings/count`;
export const SAVE_COUNSELING_RESCHEDULE_STATUS = `/payment/save-status-counseling/`;
export const GET_GENERAL_SLOT = `/counselings/general-slots-config`;
export const GET_ALL_COUNSELLING_FEEDBACK = `/counselings/feedback-pending-details`;