import Auth from "./auth/reducer";
import Notification from "./notification/reducer";
import Address from "../pages/checkout/reducer";
import PlanService from "../pages/planPage/reducer";
import CartData from "./cart/reducer";
import OrderDetails from "../pages/myOrders/reducer";
import Invoice from "../pages/invoice/reducer";
import Questions from "../pages/questions/reducer";
import feedback from "../pages/feedback/reducer"
import ReferDetails from "../container/referralForm/reducer"
import profile from "../pages/profile/reducer";
// import counselingReducer from '../pages/councelling/counselingSlice';

const rootReducer = {
  Auth,
  Address,
  PlanService,
  CartData,
  OrderDetails,
  Notification,
  Invoice,
  Questions,
  feedback,
  ReferDetails,
  profile,
};

export default rootReducer;
