import * as actionTypes from './actionTypes';

const initialState = {
  cartDetails: [],
  cartReferral: [],
  cartCoupon: []
}

const reducer = (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case actionTypes.UPDATE_CART:
      state = {
        ...state,
        cartDetails: action.payload,
      }
      break;
    case actionTypes.UPDATE_CART_REFERRAL:
      state = {
        ...state,
        cartReferral: action.payload.data,
      }
      break;
    case actionTypes.UPDATE_CART_COUPON:
      state = {
        ...state,
        cartCoupon: action.payload.data,
      }
      break;
    default:
      state = { ...state };
      break;

  }
  // console.log(state);
  return state;
}

export default reducer;