import React, { useEffect, useState } from 'react';
import classes from "./styles.module.scss";
import { Box } from '@mui/material';
import PrimaryButton from '../../../components/button';
import { useGetCounselingPricesQuery } from '../../../pages/councelling/counsellingApiSlice';
import { useDispatch } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import useApiTopLoading from '../../../hooks/useApiTopLoading';
import { dateIndian, dateTimeFormatFull, timeFormat } from '../../../shared/dateFunction';

const MakePayment = (props) => {

    const [state, setState] = useState({
        orderId: "",
    })

    const clearState = () => {
        setState({
            orderId: "",
        })
    }

    useEffect(() => {
        if (props.open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
            clearState();
        };
    }, [props.open]);

    if (!props.open) {
        return null;
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setState((prev) => ({ ...prev, [name]: value }))
    };


    const handleMakePayment = () => {
        props.handleBuyCounselling(state.orderId, clearState)
    };

    const handleClose = () => {
        props.close()
        clearState()
    }

    return (
        <div className={classes.main_container}>
            <div className={classes.overlay} onClick={handleClose}></div>
            <Box className={`${classes.filters_container} ${props.open ? classes.open : ""}`} style={{ paddingBottom: "100px" }}>
                <div className={classes.head_container}>
                    <div className={classes.head_container_item}>
                        <div className={classes.title}>Select Order</div>
                        {/* <CancelIcon onClick={handleClose} style={{color:"red"}}/> */}
                    </div>
                </div>
                {/* Reason and Comment Form */}
                <div className={classes.reason_comment_form}>
                    <div className={classes.form_row}>
                        <select
                            name='orderId'
                            id="order"
                            value={state.orderId}
                            onChange={handleChange}
                        >
                            <option key={""} value={""}>
                                Select Order
                            </option>
                            {[...props.orderData]
                                .sort((a, b) => b.id - a.id)
                                .map((option) => (
                                    <option key={option.id} value={option.id}>
                                        Invoice: {option.id}, Amount: {option.totalAmountAfterGST}, Date: {dateIndian(option?.createdAt)}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <div className={classes.btn}>
                        <PrimaryButton onClick={handleMakePayment} backgroundColor={"Blue"} width={"100%"} buttonText={props.isMasterLogin ? "Order Now" : "Make Payment"} />
                    </div>
                </div>

            </Box>
        </div>
    );
}

export default MakePayment;
