import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import classes from "./styles.module.scss";
import PrimaryButton from "../button";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import pdf from "../../assets/docs.pdf";
import Iframe from 'react-iframe';
import { openInNewTab } from "../../shared/functions";
import { connect } from "react-redux";
import { onUpdateLastView } from "../../pages/questions/actions";
import { useState } from "react";
import { Document, Page } from 'react-pdf';
import { checkPath } from "../../shared/social";
const style = {
    position: 'absolute',
    top: '0%',
    left: '50%',
    transform: 'translate(-50%, 3%)',
    borderRadius: 4,
    outline: 'none'
};

const ViewQuestionModal = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    // console.log(encodeURIComponent(process.env.REACT_APP_IMG_URL + props.pdf));
    return (
        <div className={classes.modal_main}>
            <Modal open={props.open} onClose={props.close}>
                <Box sx={style}>
                    <div className={classes.view_question_container}>
                        <div className={classes.view_question_header}>
                            <p>Subject Name</p>
                            <CloseRoundedIcon onClick={props.close} />
                        </div>
                        <div className={classes.view_question_content}>

                            <embed aria-label="PDF viewer" src={checkPath(props.pdf) + "#toolbar=0"} type="application/pdf" width="100%" height="450px" />

                            {/* <Document
                                file={encodeURIComponent(process.env.REACT_APP_IMG_URL + props.pdf)}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} />
                            </Document> */}
                            {/* <Iframe
                                className={classes.view_field}
                                src={process.env.REACT_APP_IMG_URL + props.pdf + "#toolbar=0"}
                                width="100%"
                                height="100%"
                                id=""
                                display="block"
                                frameborder="0"
                                allow="fullscreen"
                                position="relative"
                            /> */}
                        </div>

                        <div className={classes.question_download}>
                            <PrimaryButton
                                type="submit"
                                buttonText="Download"
                                backgroundColor="#005ca8"
                                icon={<FileDownloadRoundedIcon />}
                                // onClick={() => { props.questionId ? props.onUpdateLastView(props.questionId, props.pdf) : openInNewTab(props.pdf) }}
                                onClick={() => openInNewTab(props.pdf)}
                            />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {

    }
}
const mapDispatchToProps = { onUpdateLastView }
export default connect(mapStateToProps, mapDispatchToProps)(ViewQuestionModal);