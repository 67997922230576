import * as actionTypes from './actionTypes';

const initialState = {
    allFeedbackQuestions: [],
    allFeedbackDropdown: [],
    feedbackDetails: [],
    allFeedback: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_FEEDBACK_QUESTIONS:
            state = {
                ...state,
                allFeedbackQuestions: action.payload,
            }; break;
        case actionTypes.UPDATE_FEEDBACK_DROPDOWN:
            state = {
                ...state,
                allFeedbackDropdown: action.payload,
            }
            break;
        case actionTypes.UPDATE_FEEDBACK_DETAILS:
            state = {
                ...state,
                feedbackDetails: action.payload,
            }
            break;

        case actionTypes.UPDATE_FEEDBACK:
            state = {
                ...state,
                allFeedback: action.payload,
            }
    }
    return state;
}

export default reducer;