import React, { useEffect } from "react";
import { importStudentDataFromLocal } from "./store/auth/actions";
import { connect, useSelector } from "react-redux";
import Toast from "./components/ui/toast";
import Loader from "./components/ui/overlay"
import Routes from "./routes"
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ReactProgressBar from "./components/toploader";


function App(props) {
    const navigate = useNavigate();
    const loading = useSelector((state) => state.overlayloader.loading);
    useEffect(() => {
        //console.log("check")
        props.importStudentDataFromLocal();
    }, [])
    return (
        <>
            <div className="App">
                <Routes
                    auth={props.auth.authDetails}
                />
                <Toast />
                {loading ? <Loader active={loading} /> : null} {/* loads while mutation */}
                <ReactProgressBar loading={props.notification.loading} />
                {/* <Loader active={true} /> */}
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        notification: state.Notification,
        auth: state.Auth
    }
}

const mapDispatchToProps = { importStudentDataFromLocal }

export default connect(mapStateToProps, mapDispatchToProps)(App);
