import React, { useState } from "react";
import classes from "./styles.module.scss";
// import DownloadIcon from '@mui/icons-material/Download';
import ViewQuestionModal from "../../ViewQuestionModal";

const ViewQuestionButton = (props) => {
    // console.log(props);
    const [viewQuestion, setViewQuestion] = useState(false);

    return (
        <div className={props.multi ? classes.download_multiple_button : classes.download_button}>
            <button onClick={() => setViewQuestion(true)}>{props.text ? props.text : "View"}</button>
            <ViewQuestionModal questionId={props.questionId} pdf={props?.pdf} open={viewQuestion} close={() => setViewQuestion(false)} />
        </div>
    )
}

export default ViewQuestionButton;