export const invoiceData = {
    companyDetails: {
        cin: "U80904MH2018PTC312541",
        gstin: "27AADCL6768N1ZQ",
        panNumber: "AADCL6768N",
        address1: "SECOND FLOOR, FL A/202, SNO 108/1S, ADI REGENCY,NIRAKANT BHAVAN,",
        address2: "NIRANKANT BHAVAN KLD, PIMPRI CHINCHWAD PUNE, Maharashtra, 411017 INDIA",
        email: "info@Prepca.com",
        website: "www.prepca.com",
        phone: "9028526247"
    },
    billerDetails: {
        biller_name: "Rohan Valia",
        level: "CA Final",
        preference: "CA Final",
        group: "Both Group",
        discount: "10%",
        address: "Floor no 1, Urban Bliss, 2,3, Samarth Colony, Baner, Pune, Maharashtra 411045, Pune, MH, IN - 411045"
    },
    invoiceDetails: { invoice_number: "A001", shipping_state: "Maharashtra", payment_date: "23/04/2022" },
    planDetails: [{
        title: "50%",
        columns: [{ name: "paper", label: "Paper" },
        { name: "program", label: "Program" },
        { name: "rate1", label: "Rate (₹)" },
        { name: "hsnsac", label: "HSN / SAC" },
        { name: "rate2", label: "Rate (₹)" }],
        rows: [{
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }, {
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }, {
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }, {
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }]
    }, {
        title: "100A",
        columns: [{ name: "paper", label: "Paper" },
        { name: "program", label: "Program" },
        { name: "rate1", label: "Rate (₹)" },
        { name: "hsnsac", label: "HSN / SAC" },
        { name: "rate2", label: "Rate (₹)" }],
        rows: [{
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }, {
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }, {
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }, {
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }]
    }, {
        title: "100B",
        columns: [{ name: "paper", label: "Paper" },
        { name: "program", label: "Program" },
        { name: "rate1", label: "Rate (₹)" },
        { name: "hsnsac", label: "HSN / SAC" },
        { name: "rate2", label: "Rate (₹)" }],
        rows: [{
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }, {
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }, {
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }, {
            paper: 'Principles and Practice of Accounting (E-book)',
            program: 'Standard',
            rate1: '270.50',
            hsnsac: ['4820', '9983', '-'],
            rate2: ['270.50', '46.00', '00.00']
        }]
    }],

};
