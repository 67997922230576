import React, { useState, useEffect } from "react";
import classes from "./styles.module.scss";

const InvoiceComponentTable = (props) => {
    const planData = props.planData;
    //console.log(props)
    const columns = [
        {
            name: "paper",
            label: "Paper"
        }, {
            name: "session",
            label: "Attempt"
        }, {
            name: "level",
            label: "Level"
        },
        {
            name: "program",
            label: "Program"
        },
        {
            name: "hsnsac",
            label: "HSN/SAC"
        },
        // {
        //     name: "rate",
        //     label: "Rate (₹)"
        // },
        // {
        //     name: "total_rate",
        //     label: "Rate (₹)"
        // }
    ];

    return (
        <>
            {
                props.rows && Object.keys(props.rows).length > 0 && Object.keys(props.rows).map((c, i) => (
                    <table className={classes.bill_table}>
                        <tr className={classes.bill_title}>
                            <td colspan="7">{c}</td>
                        </tr>
                        <tr>
                            {
                                columns.map((c, i) => (
                                    <th>{c.label}</th>
                                ))
                            }
                        </tr>
                        {
                            props.rows[c] && props.rows[c].length > 0 && props.rows[c].map((r, i) => (
                                <tr>
                                    {/* {console.log(c.columns)} */}
                                    {
                                        columns.map((a, j) => (
                                            <td style={{ whiteSpace: 'pre-wrap' }}>
                                                {/* {r[a.name]} */}
                                                {a.name === 'rate' || a.name === 'total_rate' ? "" : r[a.name]}
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </table>
                ))
            }
        </>
    );
};

export default InvoiceComponentTable;
