export const priceInWords = (price) => {
    price = price.toString();
    var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
        dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
        tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
        handle_tens = function (dgt, prevDgt) {
            return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
        },
        handle_utlc = function (dgt, nxtDgt, denom) {
            return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        };

    var str = "",
        digitIdx = 0,
        digit = 0,
        nxtDigit = 0,
        words = [];
    var re = /(0|([1-9]\d*))(\.\d+)?/g
    if (re.test(price)) {
        var arr = price.split('.');
        price = arr[0];
        for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) {
            switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
                case 0:
                    words.push(handle_utlc(digit, nxtDigit, ""));
                    break;
                case 1:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 2:
                    words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
                    break;
                case 3:
                    words.push(handle_utlc(digit, nxtDigit, "Thousand"));
                    break;
                case 4:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 5:
                    words.push(handle_utlc(digit, nxtDigit, "Lakh"));
                    break;
                case 6:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 7:
                    words.push(handle_utlc(digit, nxtDigit, "Crore"));
                    break;
                case 8:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;

                case 9:
                    words.push(handle_utlc(digit, nxtDigit, "Arab"));
                    break;
                case 10:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;

                case 11:
                    words.push(handle_utlc(digit, nxtDigit, "Kharab"));
                    break;
                case 12:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;

                case 13:
                    words.push(handle_utlc(digit, nxtDigit, "Nil"));
                    break;
                case 14:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;

                case 15:
                    words.push(handle_utlc(digit, nxtDigit, "Padma"));
                    break;
                case 16:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;

                case 17:
                    words.push(handle_utlc(digit, nxtDigit, "Shankh"));
                    break;
                case 18:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;

                case 19:
                    words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
                    break;
                case 20:
                    words.push(handle_utlc(digit, nxtDigit, "Thousand"));
                    break;
                case 21:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 22:
                    words.push(handle_utlc(digit, nxtDigit, "Lakh"));
                    break;
                case 23:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
            }
        }
        str = words.reverse().join("")
    } else str = "";
    return str
}