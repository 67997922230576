import * as actionTypes from './actionTypes';
import { apiFunction } from "../../apiCalls/functions/department"
import { ADD_CART_CUSTOM, GET_COUPON, ADD_CART_BUNDLE, CART_DETAILS, CART_REFERRAL, DELETE_CART_CUSTOM_RECORD, APPLY_COUPON, UPDATE_CENTER_PREFERENCE, DELETE_COUPON, CART_EMPTY } from "../../apiCalls/urls/cart"
import { EDIT_CART_ORDER, EDIT_CART_CONFIRM_ORDER, EDIT_CART_DELETE_CUSTOM_ID, EDIT_CART_PAYMENT_LINK, EDIT_CART_ADD_CUSTOM, EDIT_CART_ADD_BUNDLE, EDIT_CART_DELETE_CUSTOM, EDIT_CART_DELETE_BUNDLE } from "../../apiCalls/urls/cart";
import { apiLoading, apiLoadingEnd } from "../notification/action";
import { actionNotifier } from "../../components/ui/toast";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
//api related to edit cart order

export const fetchEditCartOrder = async (orderID, token) => {
    return await apiFunction(EDIT_CART_ORDER + orderID + "/" + token, 'post', {}, false, false)
}
export const addToCartEditCustom = async (data, cartId, token) => {
    return await apiFunction(EDIT_CART_ADD_CUSTOM + cartId + "/" + token, 'post', data, false, false)
}
export const addToCartEditBundle = async (data, cartId, token) => {
    return await apiFunction(EDIT_CART_ADD_BUNDLE + cartId + "/" + token, 'post', data, false, false)
}
export const deleteCartEditCustomId = async (id, cartId, token) => {
    return await apiFunction(EDIT_CART_DELETE_CUSTOM_ID + id + "/" + cartId + "/" + token, 'delete', {}, false, false)
}
export const deleteCartEditCustom = async (id, cartId, token) => {
    return await apiFunction(EDIT_CART_DELETE_CUSTOM + id + "/" + cartId + "/" + token, 'delete', {}, false, false)
}
export const deleteCartEditBundle = async (id, cartId, token) => {
    return await apiFunction(EDIT_CART_DELETE_BUNDLE + id + "/" + cartId + "/" + token, 'delete', {}, false, false)
}
export const confirmEditOrder = async (cartId, token) => {
    return await apiFunction(EDIT_CART_CONFIRM_ORDER + cartId + "/" + token, 'post', {}, false, false)
}
export const paymentEditOrder = async (cartId, token) => {
    return await apiFunction(EDIT_CART_PAYMENT_LINK + cartId + "/" + token, 'post', {}, false, false)
}
export function onFetchEditCartOrder(orderID, token, fileName) {
    return async (dispatch) => {
        dispatch(apiLoading())
        if (orderID) {
            let result = await fetchEditCartOrder(orderID, token);
            // console.log(result);
            if (result.status) {
                dispatch(updateCart(result.data, fileName))
            }
            else {
                // actionNotifier.error(result.message)
            }
        }
        dispatch(apiLoadingEnd())
    }
}
export function onDeleteCartEditCustomId(id, cartId, orderID, token) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await deleteCartEditCustomId(id, cartId, token);
        if (result.status) {
            actionNotifier.success("Plan Deleted !")
            dispatch(updateCart(result.data, 'cartData'))
            dispatch(onFetchEditCartOrder(orderID, token))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onDeleteCartEditCustom(id, cartId, orderID, token) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await deleteCartEditCustom(id, cartId, token);
        if (result.status) {
            actionNotifier.success("Plan Deleted !")
            dispatch(updateCart(result.data, 'cartData'))
            dispatch(onFetchEditCartOrder(orderID, token))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onDeleteCartEditBundle(id, cartId, orderID, token) {
    return async (dispatch) => {
        dispatch(apiLoading())
        console.log(cartId, orderID, token);
        let result = await deleteCartEditBundle(id, cartId, token);
        if (result.status) {
            actionNotifier.success("Plan Deleted !")
            dispatch(updateCart(result.data, 'cartData'))
            dispatch(onFetchEditCartOrder(orderID, token))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onConfirmEditOrder(orderID, token, navigate) {
    return async (dispatch) => {
        dispatch(apiLoading())
        
        let result = await confirmEditOrder(orderID, token);
        // console.log(result);
        if (result.status) {
            actionNotifier.success("Edit order confirmed!")
            window.location.href = process.env.REACT_APP_ADMIN_URL
        }
        else {
            // actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
const copyLinkToClipboard = (linkToCopy, navigate) => {
    navigator.clipboard.writeText(linkToCopy).then(function () {
        actionNotifier.success("Link copied to clipboard")
        navigate("/logout");
    }).catch(function () {
        // actionNotifier.error('Unable to copy link to clipboard');
    });

};
export function onPaymentEditOrder(orderID, token, fileName, navigate) {
    return async (dispatch) => {
        dispatch(apiLoading())
        // console.log(orderID, token)


        let result = await paymentEditOrder(orderID, token);
        // console.log(result?.data?.payementUrl);
        if (result.status) {
            actionNotifier.success("Edit order payment send!")
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert-body">
                            <h4>Payment Link</h4>
                            {/* <h5>"Edit Payment Link"</h5> */}
                            <div className="react-confirm-alert-button-group">
                                {result?.data?.payementUrl}
                                <i onClick={() => { copyLinkToClipboard(result?.data?.payementUrl, navigate); }} style={{ cursor: "pointer" }} class="fa fa-copy"></i>
                            </div>
                        </div>
                    );
                }
            });
        }
        else {
            // actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onAddToCartEditCustom(data, orderID, cartId, token) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await addToCartEditCustom(data, cartId, token);
        if (result.status) {
            actionNotifier.success("Item added to cart.")
            dispatch(onFetchEditCartOrder(orderID, token))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onAddToCartEditBundle(data, orderID, cartId, token) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await addToCartEditBundle(data, cartId, token);
        if (result.status) {
            actionNotifier.success("Item added to cart.")
            dispatch(onFetchEditCartOrder(orderID, token))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
//AllAPIS
export const updateCenterPreference = async (data) => {
    return await apiFunction(UPDATE_CENTER_PREFERENCE, 'PUT', data, false, true)
}
export const applyCoupon = async (data) => {
    return await apiFunction(APPLY_COUPON, 'POST', data, false, true)
}
export const getCoupon = async () => {
    return await apiFunction(GET_COUPON, 'get', {}, false, true)
}
export const fetchReferral = async (data) => {
    return await apiFunction(CART_REFERRAL, 'get', {}, false, true)
}
export const addToCart = async (data) => {
    return await apiFunction(ADD_CART_CUSTOM, 'post', data, false, true)
}
export const addToCartBundle = async (data) => {
    return await apiFunction(ADD_CART_BUNDLE, 'post', data, false, true)
}
export const fetchCartData = async (data) => {
    return await apiFunction(CART_DETAILS, 'get', {}, false, true)
}
export const deleteCustomCartData = async (id) => {
    return await apiFunction(DELETE_CART_CUSTOM_RECORD + "/" + id, 'delete', {}, false, true)
}
export const deleteCustomCartDataByPlan = async (id) => {
    return await apiFunction(ADD_CART_CUSTOM + "/" + id, 'delete', {}, false, true)
}
export const deleteBundleCartData = async (id) => {
    return await apiFunction(ADD_CART_BUNDLE + "/" + id, 'delete', {}, false, true)
}
export const deleteCoupon = async () => {
    return await apiFunction(DELETE_COUPON, 'delete', {}, false, true)
}
export const emptyCart = async () => {
    return await apiFunction(CART_EMPTY, 'DELETE', {}, false, true)
}
export function onUpdateCenterPreference(data, type, navigate) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let obj = {
            centreInCityId: data.testPreferenceId == 1 ? null : data.centreInCityId,
            referralId: data.referralId == 99999 ? null : data.referralId,
            referredFromOther: data.referredFromOther,
            testPreferenceId: data.testPreferenceId
        }
        let result = await updateCenterPreference(obj);
        // console.log(result);
        if (result.message == 'Success') {
            navigate(type == 'bundle' ? "/checkout/bundle" : "/checkout/custom");
            //alert('Center Update')
            //actionNotifier.success("Center Update")
        }
        else {
            //alert(result.message)
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onApplyCoupon(data) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await applyCoupon(data);
        // console.log(result);
        if (result.status) {
            //alert('Coupon Applied')
            actionNotifier.success("Coupon Applied successfully")
            dispatch(onFetchCartData())
        }
        else {
            //alert(result.message)
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onGetCoupon(data) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await getCoupon();
        //console.log(result);
        if (result.status) {
            dispatch(updateCartCoupon(result.data))
        }
        dispatch(apiLoadingEnd())
    }
}
export function onEmptyCart() {
    return async (dispatch) => {
        dispatch(apiLoading())
        //alert('empty cart')
        let result = await emptyCart();
        if (result.status) {
            actionNotifier.success("Item's deleted !")
            dispatch(onFetchCartData())
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onDeleteToCartCustomByPlan(id) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await deleteCustomCartDataByPlan(id);
        // console.log(result, "asdasdasd");
        if (result.status) {
            actionNotifier.success("Plan Deleted !")
            dispatch(updateCart(result.data, 'cartData'))
            dispatch(onFetchCartData())
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onDeleteToCartCustom(id) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await deleteCustomCartData(id);
        // console.log(result, "asdasdasd");
        if (result.status) {
            actionNotifier.success("Plan Deleted !")
            dispatch(updateCart(result.data, 'cartData'))
            dispatch(onFetchCartData())
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onDeleteToCartBundle(id) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await deleteBundleCartData(id);
        // console.log(result);
        if (result.status) {
            actionNotifier.success("Plan Deleted !")
            dispatch(updateCart(result.data, 'cartData'))
            dispatch(onFetchCartData())
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onDeleteCoupon() {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await deleteCoupon();
        // console.log(result);
        if (result.status) {
            actionNotifier.success("Coupon Deleted !")
            dispatch(onFetchCartData())
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}

export function onAddToCart(data) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await addToCart(data);
        if (result.status) {
            actionNotifier.success("Item added to cart.")
            dispatch(updateCart(result.data, 'cartData'))
            dispatch(onFetchCartData())
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onAddToCartBundle(data) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await addToCartBundle(data);
        // console.log(data);
        if (result.status) {
            actionNotifier.success("Item added to cart.")
            // dispatch(updateCart(result.data, 'cartData'))
            dispatch(onFetchCartData())
            // if (window.innerWidth < 678) {
            //     window.location.reload()
            // }
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchCartData(fileName) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchCartData();
        if (result.status) {
            dispatch(updateCart(result.data, fileName))
        }
        else {
            // actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchReferral(fileName) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchReferral();
        if (result.status) {
            dispatch(updateCartReferral(result.data, fileName))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}

export const updateCart = (data, name) => {
    return {
        type: actionTypes.UPDATE_CART,
        payload: { data, name }
    };
}
export const updateCartReferral = (data, name) => {
    return {
        type: actionTypes.UPDATE_CART_REFERRAL,
        payload: { data, name }
    };
}
export const updateCartCoupon = (data, name) => {
    return {
        type: actionTypes.UPDATE_CART_COUPON,
        payload: { data, name }
    };
}
