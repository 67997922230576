import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../apiCalls/rtkBaseQuery';
import * as API from '../../apiCalls/urls/counsellor';


// const customErrorHandler = async (error) => {
//   if (error.status === 200 && error.data === null) {
//     throw new Error('Empty Response Received');
//   }
//   throw error;
// };


export const counselingApi = createApi({
  baseQuery,
  tagTypes: ['counselling', 'counsellingCount'],
  reducerPath: 'counselingApi',
  endpoints: (builder) => ({
    // All Counseling Bought with The Order / Added by Admin Side
    getCounsellingList: builder.query({
      query: ({ page = 1, items = 10, filters }) => ({
        // url: COUNSELLOR_LIST,
        url: `${API.COUNSELLOR_LIST}?page=${page}&items=${items}`,
        method: 'POST',
        body: filters || {}
      }),
      providesTags: (result, error, { page, items, filters }) => [
        { type: 'counselling', page, items, filters },
      ],
      refetchOnMountOrArgChange: true,
      keepUnusedDataFor: 0
    }),

    // get all the counsellor student booked
    getCounselorList: builder.query({
      query: (bodyData) => ({
        url: 'admin/counselor/list',
        method: 'POST',
        body: bodyData || {}
      }),
      providesTags: ['counselor'],
      keepUnusedDataFor: 0
    }),
    cancelCounseling: builder.mutation({
      query: ({ id, data }) => ({
        url: `${API.CANCEL_COUNSELLING}${id}`,
        method: 'POST',
        body: data
      }),
      // onError:customErrorHandler,
      // invalidatesTags: ['counselling'],
      invalidatesTags: ['counsellingCount'],
    }),
    rescheduleCounseling: builder.mutation({
      query: ({ data }) => ({
        url: API.RESHEDULE_COUNSELING,
        method: 'POST',
        body: data,
      }),
      // invalidatesTags: ['counselling'],
      invalidatesTags: ['counsellingCount'],
    }),
    getCounselingDetails: builder.query({
      query: (counselingId) => `${API.COUNSELING_DETAILS}${counselingId}`,
      providesTags: ['counsellingDetails'],
      keepUnusedDataFor: 0
    }),
    requestCounseling: builder.mutation({
      query: (counselingData) => ({
        url: API.REQUEST_COUNSELING,
        method: 'POST',
        body: counselingData,
      }),
      invalidatesTags: ['counselling', 'counsellingCount'],

    }),
    getSuggestedCounselor: builder.query({
      query: (counselingData) => ({
        url: `/counselings/suggest-counselor`,
        method: 'POST',
        body: counselingData,
      }),
      keepUnusedDataFor: 0
    }),
    getLanguageId: builder.query({
      query: (dropdown) => ({
        url: API.GET_LANGUAGE_ID,
        method: 'POST',
        body: dropdown,
      }),
    }),
    getCounselorDetails: builder.query({
      query: ({ id, counselingType }) => {
        if (id) {
          return {
            url: `${API.GET_COUNSELOR_DETAILS}${id}/${counselingType}`,
            method: 'GET',
            // body: counselingdata,
          };
        }
        // If id is not available, return undefined or null to prevent the API call
        return undefined;
      },
    }),
    getCounselingPrices: builder.query({
      query: (id) => {
        if (id) {
          return {
            url: `${API.GET_COUNSELING_PRICES}${id}`,
            method: 'GET',
          };
        }
        return undefined;
      },
      keepUnusedDataFor: 0
    }),
    acceptRescheduledCounseling: builder.mutation({
      query: (counselingId) => ({
        url: API.ACCEPT_RESCHEDULED_COUNSELING + counselingId,
        method: 'POST',
      }),
      invalidatesTags: ['counselling', "counsellingCount"],
    }),
    getCounsellingTypePrices: builder.query({
      query: () => ({
        url: API.GET_COUNSELING_TYPE_PRICES,
        method: 'GET',
      }),
      keepUnusedDataFor: 0
    }),
    getOrders: builder.query({
      query: () => ({
        url: API.GET_ORDERS,
        method: 'GET',
      }),
      keepUnusedDataFor: 0
    }),
    buyCounseling: builder.mutation({
      query: ({ data }) => ({
        url: API.PURCHASE_COUNSELING,
        method: 'POST',
        body: data,
      }),
    }),
    saveCounselingBuyStatus: builder.mutation({
      query: (id) => ({
        url: API.SAVE_COUNSELING_STATUS + id,
        method: 'POST',
        // body: data,
      }),
      invalidatesTags: ['counselling', "counsellingCount"],
    }),
    getOrderGroupsSubjects: builder.query({
      query: (id) => ({
        url: API.GET_ORDER_GROUP_SUBJECT + id,
        method: 'GET',
      }),
    }),
    getCounsellingListCount: builder.query({
      query: () => ({
        url: API.GET_COUNSELLING_LIST_COUNT,
        method: 'GET',
      }),
      providesTags: ['counsellingCount'],
      keepUnusedDataFor: 0
    }),
    saveCounselingRescheduleStatus: builder.mutation({
      query: (id) => ({
        url: API.SAVE_COUNSELING_RESCHEDULE_STATUS + id,
        method: 'POST',
        // body: data,
      }),
      invalidatesTags: ['counselling', "counsellingCount"],
    }),
    getGeneralSlotConfig: builder.mutation({ //for getting startTime EndTime length
      query: () => ({
        url: API.GET_GENERAL_SLOT,
        method: 'GET',
        // body: data,
      }),
    }),
    getAllCounselingPendingFeedback: builder.mutation({
      query: () => ({
        url: API.GET_ALL_COUNSELLING_FEEDBACK,
        method: 'GET',
        // body: data,
      }),
    }),
  }),
});

export const {
  useGetCounsellingListQuery,
  useCancelCounselingMutation,
  useRescheduleCounselingMutation,
  useGetCounselingDetailsQuery,
  useRequestCounselingMutation,
  useGetCounselorListQuery,
  useGetSuggestedCounselorQuery,
  useGetLanguageIdQuery,
  useGetCounselorDetailsQuery,
  useGetCounselingPricesQuery,
  useAcceptRescheduledCounselingMutation,
  useGetCounsellingTypePricesQuery,
  useGetOrdersQuery,
  useBuyCounselingMutation,
  useSaveCounselingBuyStatusMutation,
  useGetOrderGroupsSubjectsQuery,
  useGetCounsellingListCountQuery,
  useSaveCounselingRescheduleStatusMutation,
  useGetGeneralSlotConfigMutation,
  useGetAllCounselingPendingFeedbackMutation
} = counselingApi;

