import React, { useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { Grid } from "@mui/material";
import MuiSelect from "../../components/mui/muiSelect";
import PrimaryButton from "../../components/button";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useDispatch, useSelector } from "react-redux";
import { fetchCourse, onGetProfile } from "../../pages/profile/action";
import MakePayment from "./makePayment";
import { actionNotifier } from "../../components/ui/toast";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import SubjectIcon from '@mui/icons-material/Subject';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import { checkEnvVariable } from "../../shared/throwError";
import PopupDialog from "../../components/popupDialog";
import { formatUTCtoUTCDateMnameYearTime } from "../../shared/dateFunction";
import { toSentenceCase } from "../../shared/counsellorFunction";

const BuyCounsellingContainer = (props) => {
  // const [counsellingWise, setCounsellingWise] = useState("");
  // const [subjects, setSubjects] = useState([]);
  const Razorpay = useRazorpay();
  const navigate = useNavigate()
  const [state, setState] = useState(
    {
      counselingType: "POST_ORDER",
      counselingSubType: "",
      meetType: "",
      quantity: "1",
      // orderId: ""
    }
  )

  const isMasterLogin = localStorage.getItem('masterLogin') && localStorage.getItem('masterLogin') !== 'false'


  const clearState = () => {
    setState({
      counselingSubType: "",
      meetType: "",
      quantity: "1",
    })
  }

  const [error, setError] = useState({})

  const uniqueNames = [...new Set(props?.counsellingTypePricesData?.types?.map(item => item?.name))];
  const uniqueNamesText = [...new Set(props?.counsellingTypePricesData?.types?.map(item => item?.text))];
  const uniqueTypes = [...new Set(props?.counsellingTypePricesData?.types?.map(item => item?.type))];

  const nameOptions = uniqueNames.map((name, i) => ({ value: name, label: uniqueNamesText[i] }));
  const typeOptions = uniqueTypes.map(type => ({ value: type, label: type }));

  const counsellingPrice = props?.counsellingTypePricesData?.types?.find((p) => p.name === state.counselingSubType && p.type === state.meetType)

  useEffect(() => {

  }, [])


  const handleChange = (event, fieldName) => {
    setState((prev) => ({ ...prev, [fieldName]: event.target.value }));
    setError((prev) => ({ ...prev, [fieldName]: " " }));
  };
  const handleQTYChange = (fieldName) => {
    if (state.quantity <= 1 && fieldName === "decrease") {
      return
    }
    if (fieldName === "increase") {
      setState((prev) => ({ ...prev, quantity: Number(state.quantity) + 1 }));
    } else if (fieldName === "decrease") {
      setState((prev) => ({ ...prev, quantity: Number(state.quantity) - 1 }));
      console.log("Selected subject:", "dec");
    }
  };

  const [makePaymentModal, setMakePaymentModal] = useState(null)
  const [responseData, setResponseData] = useState({})


  const handleBuyCounselling = async (id, clearId) => {

    const orderId = Number(id);
    const quantity = Number(state.quantity);
    const { counselingType, counselingSubType, meetType } = state;

    if (isNaN(orderId) || orderId <= 0) {
      actionNotifier.error("Please Select order ID.");
      return;
    }

    if (isNaN(quantity) || quantity <= 0) {
      actionNotifier.error("Quantity must be a positive number.");
      return;
    }

    if (!counselingType) {
      actionNotifier.error("Counseling type cannot be empty.");
      return;
    }

    if (!counselingSubType) {
      actionNotifier.error("Counseling sub-type cannot be empty.");
      return;
    }

    if (!meetType) {
      actionNotifier.error("Meeting type cannot be empty.");
      return;
    }


    const _data = { orderId: Number(id), ...state, quantity: Number(state.quantity) }

    if (isMasterLogin) {
      try {
        const razorPayResult = await props.buyCounseling({ data: _data }).unwrap();
        console.log("🚀 ~ handleBuyCounselling ~ razorPayResult:", razorPayResult)
        setResponseData(razorPayResult.createdCounselings)
        clearId()
        clearState()
        setOpenBuyCounsellingPopup(true);
        // navigate("/dashboard/counseling/created")
      } catch (error) {
        actionNotifier.error(error?.data?.message || "Unable to " + isMentorShipStudent ? "Buy Mentorship" : "Buy Counseling")
        return;
      }
      return;
    }
    try {
      const razorPayResult = await props.buyCounseling({ data: _data }).unwrap();
      // actionNotifier.success("Purchased Sucessfully")
      clearId()
      clearState()
      setMakePaymentModal(null)


      const options = {
        key: process.env.REACT_RAZOR_PAY_KEY,
        name: "PREPCA",
        description: "Test Counseling Buy Transaction",
        image: "https://prepca.com/img/prepca-logo.png",
        order_id: razorPayResult?.orderId,
        handler: async (res) => {
          const id = razorPayResult?.orderId
          const resp = await props.saveCounselingBuyStatus(id).unwrap()
          console.log(resp);
          setResponseData(resp)
          actionNotifier.success("Payment Sucessfull")
          setOpenBuyCounsellingPopup(true);
          // navigate("/dashboard/counseling/created")
          document.body.style.overflow = "auto"
        },
        prefill: {
          name: razorPayResult?.student?.firstName + " " + razorPayResult?.student?.middleName + " " + razorPayResult?.student?.lastName,
          email: razorPayResult?.student?.email,
          contact: razorPayResult?.student?.mobileNo,
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: function () {
            console.log("Checkout form closed");
            document.body.style.overflow = "auto"
            // Handle the case when the user closes the payment modal
            // handlePaymentCancelled();
          }
        },
        events: {
          PaymentResponse
        }
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    } catch (error) {
      // actionNotifier.error(error.data.error)
      document.body.style.overflow = "auto"
      console.error('Error Buying the counselling:', error);
    }
  }

  const handleMakePaymentClick = () => {
    const { counselingSubType, meetType } = state
    let error = {}
    if (counselingSubType === "" || counselingSubType === " ") {
      error.counselingSubType = "Please Choose Counseling"
    }
    if (meetType === "" || meetType === " ") {
      error.meetType = "Please Choose Meet Type"
    }
    setError(error)
    if (Object.keys(error).length > 0) {
      return;
    } else {
      setMakePaymentModal(true)
    }
  }

  const navigateToCreated = async () => {
    navigate('/dashboard/counseling/created')
  }

  const [openBuyCounsellingPopup, setOpenBuyCounsellingPopup] = useState(false);

  const detailsScheduled = [
    {
      icon: SubjectIcon,
      text: `Type: ${responseData[0]?.type && toSentenceCase(responseData[0]?.type)}`,
    },
    {
      icon: SubjectIcon,
      text: `Subtype: ${responseData[0]?.subType && toSentenceCase(responseData[0]?.subType)}`,
    },
    {
      icon: SubjectIcon,
      text: `Meet Type: ${responseData[0]?.meetType && toSentenceCase(responseData[0]?.meetType)}`,
    },
    {
      icon: PeopleIcon,
      text: `Quantity: ${responseData.length}`,
    },
    {
      icon: AccessTimeIcon,
      text: `${responseData[0]?.meetingTime || checkEnvVariable("REACT_APP_MEETING_TIME")} min`,
    },
    {
      icon: AssignmentIcon,
      text: 'Counseling Order details provided upon confirmation.',
    },
  ];

  const isMentorShipStudent = useSelector((state) => state.Auth?.authDetails?.isMentorShipStudent)

  return (
    <div className={classes.container}>
      <div className={classes.select_container}>
        <label htmlFor="">Select Councelling for</label>
        <div className={classes.select_options_amount}>
          <Grid container gap={1} spacing={2}>
            <Grid item xs={12} md={4} sm={4} className={classes.miui_grid}>
              <MuiSelect
                value={state.counselingSubType}
                onChange={(event) => handleChange(event, "counselingSubType")}
                ltextColor={"black"}
                color={"white"}
                firstValue={true}
                options={nameOptions}
                label="Counseling Type"
                error={error.counselingSubType}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={4} className={classes.miui_grid}>
              <MuiSelect
                value={state.meetType}
                onChange={(event) => handleChange(event, "meetType")}
                ltextColor={"black"}
                color={"white"}
                firstValue={true}
                options={typeOptions}
                label="Meet Type"
                error={error.meetType}
              />
            </Grid>
          </Grid>
          <div className={classes.amount}>{`${counsellingPrice?.prices?.buy ? (counsellingPrice?.prices?.buy * Number(state.quantity)) + "/-" : ""}`}</div>
        </div>

      </div>

      <div className={classes.description}>
        <div className={classes.head}>
          What we're offering
        </div>
        <div className={classes.sub_heading}>
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took
          a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
        </div>
        <div className={classes.list}>
          <ul>
            <li>Took a galley of type and scrambled it to make a
              type specimen book.</li>
            <li>Took a galley of type and scrambled it to make a
              type specimen book.</li>
            <li>Took a galley of type and scrambled it to make a
              type specimen book.</li>
            <li>Took a galley of type and scrambled it to make a
              type specimen book.</li>
            <li>Took a galley of type and scrambled it to make a
              type specimen book.</li>
            <li>Took a galley of type and scrambled it to make a
              type specimen book.</li>
          </ul>
        </div>
      </div>
      {/* Btns for buying, Quantity */}
      <div className={classes.btns}>
        <div className={classes.quantity}>
          <PrimaryButton onClick={() => handleQTYChange("decrease")} fontSize={"36px"} color={"black"} border={"1px solid gray"} width={"40px"} height={"40px"} backgroundColor={"transparent"} buttonText={"-"} />
          <PrimaryButton fontSize={"30px"} color={"black"} buttonText={state.quantity || 1} width={"40px"} backgroundColor={"transparent"} />
          <PrimaryButton onClick={() => handleQTYChange("increase")} fontSize={"36px"} color={"black"} border={"1px solid gray"} width={"40px"} height={"40px"} backgroundColor={"transparent"} buttonText={"+"} />
        </div>
        <PrimaryButton onClick={() => handleMakePaymentClick(true)} iconRight={<PlayArrowIcon sx={{ color: "white" }} />} buttonText={isMentorShipStudent ? "Buy Mentorship" : "Buy Counseling"} width={"150px"} backgroundColor={"#1D3EA7"} />
      </div>

      <MakePayment isMasterLogin={isMasterLogin} handleBuyCounselling={handleBuyCounselling} close={() => setMakePaymentModal(null)} open={makePaymentModal} orderData={props.orderData} />

      <PopupDialog
        open={openBuyCounsellingPopup}
        onClose={() => setOpenBuyCounsellingPopup(false)}
        title="You have Bought an Counseling"
        // subtitle="A calendar invitation has been sent to your email address."
        details={detailsScheduled}
        width="500px"
        primaryActionLabel="Done"
        onPrimaryAction={() => { navigateToCreated(); setOpenBuyCounsellingPopup(false) }}
      />
    </div>
  );
};

export default BuyCounsellingContainer;