import React, { useState } from "react";
import classes from "./styles.module.scss";
import NameCard from "./nameCard";
import SubscriptionCard from "./subscriptionCard";
import RankCard from "./rankCard";
import Overview from "./overview";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useEffect } from "react";
import { onLoad } from "../../../pages/profile/action";
import { onFetchOrder } from "../../../pages/myOrders/actions";
import { connect } from "react-redux";



const RightBar = (props) => {

    // const [profileDetails, setProfileDetails] = useState([]);

    useEffect(() => {
        props.onFetchOrder()
    }, [])

    //console.log(props)

    // useEffect(() => {
    //     let _profileDetails = []
    //     props.profile.allProfile && props.profile.allProfile.map((c, i) =>{
    //         _profileDetails.push({
    //             first_name:c.firstName,
    //         })
    //     })
    //     setProfileDetails(_profileDetails)
    // }, [props.profile.allProfile])

    // console.log(profileDetails);

    return (
        <div id="right" className={classes.right_bar}>
            <div className={classes.rightContent}>
                <span className={classes.hide}><ArrowCircleRightIcon onClick={props.handleClick} /></span>
                <NameCard />
                {/* <SubscriptionCard orderData={props?.order?.allOrders} />
                <RankCard />
                <Overview /> */}
                <a href="/logout" className={classes.logout}><i className="fa fa-sign-out"></i> Logout</a>
            </div>
        </div>
    )
}

// export default RightBar;
const mapStateToProps = (state) => {
    return {
        profile: state.profile,
        order: state.OrderDetails
    }
}

const mapDispatchToProps = { onLoad, onFetchOrder }

export default connect(mapStateToProps, mapDispatchToProps)(RightBar);