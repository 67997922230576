import { PropaneSharp } from "@mui/icons-material";
import React from "react";
import classes from "./styles.module.scss"

const PrimaryButton = ({ disabled, float, onClick, type, buttonText, width, height, backgroundColor, color, padding, marginTop, margin, fontSize, border, icon, icon_two, fontWight, iconRight }) => {

    return (
        <button onClick={onClick} className={classes.button} type={type} disabled={disabled ? disabled : ""}
            style={{ float, width, height, backgroundColor, color, marginTop, margin, fontSize, border, padding, fontWight }}
        >
            {icon}
            {icon_two}
            {buttonText}
            {iconRight}
        </button>
    )
}

export default PrimaryButton;