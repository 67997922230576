import { actionNotifier } from "../components/ui/toast";

let runCount = 0;
let timeout;

export const throwError = (error, msg) => {
    if (error?.data?.statusCode === 400) {
        actionNotifier.error(`${error.data.message}`)
    } else {
        actionNotifier.error(`${msg}`)
    }
};

export const checkEnvVariable = (envVar) => {
    if (runCount >= 3) {
        console.warn("checkEnvVariable has already run 3 times.");
        return;
    }

    clearTimeout(timeout);

    timeout = setTimeout(() => {
        const value = process.env[envVar];
        if (!value) {
            const errorMessage = `Environment variable ${envVar} is not set.`;
            actionNotifier.error(errorMessage);
        }
        runCount++;
        return value;
    }, 300);
};
