import { apiFunction } from "../../apiCalls/functions/department"
import { ADDRESS, STATE_DETAILS, STATE_DISTRICT, DISTRICT_DETAILS } from "../../apiCalls/urls/address";
import { GET_ORDER, ORDER_PREVIEW, ORDER_CHECKOUT, ORDER_PAYMENT } from "../../apiCalls/urls/order";
import { actionNotifier } from "../../components/ui/toast";
import { apiLoading, apiLoadingEnd } from "../../store/notification/action";
import { apiLoadingOverlay, apiLoadingOverlayEnd } from "../../store/overlayloader/overlayLoadingSlice";
import * as actionTypes from './actionTypes';
//AllAPIS
export function onLoad(navigate) {
    return async (dispatch) => {
        dispatch(onFetchAddress("addressDetails"))
        dispatch(onFetchOrderPreview("orderDetails", navigate))
    }
}
export const getOrderPaymentId = async (orderId) => {
    return await apiFunction(GET_ORDER + orderId + "/", 'get', {}, false, true)
}
export const orderPayment = async (razorPayOrderId) => {
    return await apiFunction(ORDER_PAYMENT + razorPayOrderId, 'post', {}, false, true)
}
export const fetchOrderPreview = async () => {
    return await apiFunction(ORDER_PREVIEW, 'get', {}, false, true)
}
export const fetchAddress = async () => {
    return await apiFunction(ADDRESS, 'get', {}, false, true)
}
export const fetchState = async () => {
    return await apiFunction(STATE_DETAILS, 'get', {}, false, true)
}
export const fetchStateDistrict = async (stID) => {
    return await apiFunction(STATE_DISTRICT + stID + '/districts', 'get', {}, false, true)
}
export const fetchDistrictCity = async (diID) => {
    return await apiFunction(DISTRICT_DETAILS + diID + '/cities', 'get', {}, false, true)
}
export const saveAddress = async (data) => {
    return await apiFunction(ADDRESS, 'post', data, false, true)
}
export const deleteAddress = async (id) => {
    return await apiFunction(ADDRESS + id + "/", 'DELETE', {}, false, true)
}
export const editAddress = async (id, data) => {
    return await apiFunction(ADDRESS + id + "/", 'PUT', data, false, true)
}
export const checkoutOrder = async (data) => {
    return await apiFunction(ORDER_CHECKOUT, 'POST', data, false, true)
}

export function onFetchOrderPreview(fileName, navigate) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchOrderPreview();
        // console.log(result)
        if (result.status) {
            dispatch(updateOrderPreview(result.data, fileName))
        }
        // else {
        //     navigate("/plans/bundle");
        // }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchAddress() {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchAddress();
        if (result.status) {
            let data = result.data.filter((e) => e.pincode.length == 6)
            dispatch(updateAddress(data))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onEditAddress(id, addressState, isShown) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())

        let data = {
            name: addressState.name,
            phone: addressState.phone,
            address: addressState.address,
            cityId: Number(addressState.cityId),
            districtId: Number(addressState.districtId),
            pincode: addressState.pincode,
            landmark: "landmark"
        }
        let result = await editAddress(id, data);
        //console.log(result)
        if (result.status) {
            dispatch(onFetchAddress())
            actionNotifier.success("Address Update")
            isShown(false)
        }
        else {
            actionNotifier.error(result.message[0])
        }
        dispatch(apiLoadingOverlayEnd())
    }

}
export function onSaveStatus(razorPayOrderId, navigate, id) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())
        let result = await orderPayment(razorPayOrderId);
        if (result.status) {
            actionNotifier.success("Payment Successful")
            navigate("/invoice/" + id);
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingOverlayEnd())
    }

}
export function onDeleteAddress(id) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())
        let result = await deleteAddress(id);
        //console.log(result)
        if (result.status) {
            dispatch(onFetchAddress())
            actionNotifier.success("Address Deleted")
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingOverlayEnd())
    }

}
export function onSaveAddress(addressState, isShown) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())

        let data = {
            name: addressState.name,
            phone: addressState.phone,
            address: addressState.address,
            cityId: Number(addressState.cityId),
            districtId: Number(addressState.districtId),
            pincode: addressState.pincode
        }
        let result = await saveAddress(data);
        //console.log(result)
        if (result.status) {
            dispatch(onFetchAddress())
            actionNotifier.success("Address Saved")
            isShown(false)
        }
        else {
            actionNotifier.error(result.message[0])
        }
        dispatch(apiLoadingOverlayEnd())
    }
}

export function onOrderCheckout(addressId, gstNumber, navigate, Razorpay, masterLogin) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())
        let data = {
            studentAddressId: addressId,
            gstNumber: gstNumber ? gstNumber : undefined,
            cod: masterLogin ? Boolean(masterLogin) : undefined
        }
        let result = await checkoutOrder(data);
        // console.log(result)


        if (masterLogin === null || masterLogin === 'false') {
            let razorPayResult = await getOrderPaymentId(result.data.id);
            // console.log(razorPayResult)
            // if (razorPayResult.status) {
            //     let saveStatus = await orderPayment(razorPayResult?.data?.orderId);
            //     console.log(saveStatus)
            // }

            //let paymentResult = paymentURL(result.data.id, result.data.transactionKey, "/invoice/" + result.data.id, "/invoice/" + result.data.id)        
            //let url = window.location.href.substring(0, window.location.pathname.length)
            //let successURL = url + "/invoice/" + result.data.id
            //let failureURL = url + "/invoice/" + result.data.id
            //window.location.href = `${process.env.REACT_APP_API_URL}/payment/order/${result.data.id}/?transactionKey=${result.data.transactionKey}&successUrl=${successURL}&failureUrl=${failureURL}`

            const options = {
                key: process.env.REACT_RAZOR_PAY_KEY,
                name: "PREPCA",
                description: "Test Transaction",
                image: "https://prepca.com/img/prepca-logo.png",
                order_id: razorPayResult?.data?.orderId,
                handler: (res) => {
                    //console.log(res);
                    dispatch(onSaveStatus(res?.razorpay_order_id, navigate, result.data.id));
                },
                prefill: {
                    name: razorPayResult?.data?.student?.firstName + " " + razorPayResult?.data?.student?.middleName + " " + razorPayResult?.data?.student?.lastName,
                    email: razorPayResult?.data?.student?.email,
                    contact: razorPayResult?.data?.student?.mobileNo,
                },
                theme: {
                    color: "#3399cc",
                }
            };

            const rzpay = new Razorpay(options);
            rzpay.open();
        } else {
            navigate("/invoice/" + result.data.id)
        }
        /*if (result.status) {
            navigate("/invoice/" + result.data.id)
        }
        else {
            actionNotifier.error(result.message)
        }*/
        dispatch(apiLoadingOverlayEnd())
    }
}

export const updateAddress = (data) => {
    // console.log(data);
    return {
        type: actionTypes.UPDATE_ADDRESS,
        payload: { data }
    };
}
export const updateOrderPreview = (data, name) => {
    return {
        type: actionTypes.UPDATE_ORDER_PREVIEW,
        payload: { data, name }
    };
}
