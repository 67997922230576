import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import BillHeader from "../billHeader";
import classes from "./styles.module.scss";
import { Container, Grid } from '@mui/material/';

import { generateBundleInvoice } from "../../../shared/invoice";
import { priceInWords } from '../../../shared/priceToWord';


const InvoiceBundleComponent = (props) => {
    console.log(props)
    const companyData = props.invoiceData.companyDetails;
    const billerData = props.invoiceData.billerDetails;
    const invoiceData = props.invoiceData.invoiceDetails;
    const planData = props.invoiceData.planDetails;

    const [planName, setPlanName] = useState(null)
    const [rows, setRows] = useState(null)
    const [isIgst, setIgst] = useState(false)

    useEffect(() => {
        if (props.invoice.orderData?.all_portions) {
            if (props.invoice.orderData.order?.OrderedBundlePlans?.length) {
                let data = generateBundleInvoice(props.invoice.orderData.order.OrderedBundlePlans, props?.invoice?.orderData?.order?.District?.stateId)
                //console.log(data);
                setRows(data.allData)
                setIgst(data.isIGST)
                setPlanName("bundle")
            }
        }

    }, [props.invoice.orderData])

    //console.log(rows, planName, isIgst);

    // console.log(planData)
    return (
        <>

            {/* <BillHeader order={props.invoice.orderData?.order} companyData={companyData} billerData={billerData} invoiceData={invoiceData} /> */}
            <table className={classes.bundle_bill_table}>
                <tr>
                    <th>Particulars</th>
                    {/* <th>Unit</th> */}
                    <th>Quantity</th>
                    {/* <th>Discount</th> */}
                    <th>HSN/SAC</th>
                    {/* <th>Rate/unit </th>
                    <th>Total</th> */}
                </tr>
                {
                    rows?.map((r, i) => (
                        <tr key={i}>
                            <td>{r.paper}</td>
                            {/* <td>{r.program}</td> */}
                            <td>{r.quantity}</td>
                            {/* <td>{r.discount}</td> */}
                            <td>{r.hsnsac}</td>
                            {/* <td>{r.rate}</td>
                            <td>

                                <b>{r.total}</b>
                                <br /><span style={{ fontSize: "10px" }}>Tax Exclusive</span>
                            </td> */}
                        </tr>
                    ))
                }

            </table>


        </>
    );
};

const mapStateToProps = (state) => {
    return {
        invoice: state.Invoice,
    }
}

export default connect(mapStateToProps)(InvoiceBundleComponent);