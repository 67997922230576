import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material/";
import classes from "./styles.module.scss";
import { connect } from "react-redux";
import { useState } from "react";
import { generateCustomizeInvoice } from "../../../shared/invoice";
import { calculateCustomizeDatas } from "../helper";

const InvoiceCustomizeContainer = (props) => {

    const columns = ["Name", "Quantity"]
    const [planName, setPlanName] = useState(null)
    const [rows, setRows] = useState(null)
    const [customizedData, setCustomizedData] = useState(null)
    const [allSyllubus, setAllSyllabus] = useState(null)

    useEffect(() => {
        if (props.invoice.orderData?.all_portions) {
            if (props.invoice.orderData.order?.OrderedCustomPlans?.length > 0) {
                let data = generateCustomizeInvoice(props.invoice.orderData.all_portions, props.invoice.orderData.order.OrderedCustomPlans, props.invoice.orderData.all_service)
                setAllSyllabus(data.syllabus)
                setRows(data.allData)
                setPlanName("custom")
            }
        }

    }, [props.invoice.orderData?.all_portions])

    useEffect(() => {
        if (rows) {
            const data = calculateCustomizeDatas(rows)
            // console.log("🚀 ~ file: index.js:85 ~ useEffect ~ data:", data)
            setCustomizedData(data)
        }
    }, [rows])

    return (
        <>
            <table className={classes.bill_table}>
                <tr className={classes.bill_title}>
                    {
                        columns.map((c, i) => (
                            <th>{c}</th>
                        ))
                    }
                </tr>
                <tr className={classes.bill_desc}>
                    <td style={{ whiteSpace: 'pre-wrap' }}>
                        Customize({allSyllubus?.join(',')})
                    </td>
                    <td>
                        {props.allSyllabus?.quantity}
                    </td>
                    {/* <td>
                        {customizedData?.taxableCustomizedTotal?.toFixed(2)}<br /><span style={{ fontSize: "10px" }}>Tax Exclusive</span>
                    </td>
                    <td>
                        {customizedData?.totalCustomizedIGST?.toFixed(2)}<br />
                        <span style={{ fontSize: "10px" }}>{props.stateId == 21 ? "CGST + SGST " : "IGST "}</span>
                    </td>
                    <td>
                        {customizedData?.finalCustomizedTotal?.toFixed(2)}
                    </td> */}
                </tr>
            </table>
        </>
    );
};


// export default InvoiceCustomizeContainer;


const mapStateToProps = (state) => {
    return {
        invoice: state.Invoice,
    }
}

export default connect(mapStateToProps)(InvoiceCustomizeContainer);