import DownloadButton from "../components/tableButton/downloadButton";
import BuyNowButton from "../components/tableButton/buyNowButton";
import WaitingButton from "../components/tableButton/waitingButton";
import { encryptString, openInNewTab } from "./functions";
import TableDotDropdown from "../components/tableButton/tableDotDropdown";
import { width } from "@mui/system";
import ViewQuestionModal from "../components/ViewQuestionModal";
import ViewQuestionButton from "../components/tableButton/downloadButton";
import BundleElectiveData from "../pages/answers/bundleElectiveData";

const colorCodes = [
    { status: undefined, background: "#005ca8", foreground: "#fff", message: "Upload", },
    { status: "new", background: "#fddc00", foreground: "#000", message: "Upload Complete", },
    { status: "pending", background: "#fddc00", foreground: "#000", message: "Under Evaluation" },
    { status: "completed", background: "#54951f", foreground: "#fff", message: "Evaluated" },
    { status: "student_answer_rejected", background: "#800000", foreground: "#fff", message: "Rejected" }
]

const getStatusMessageAndColor = (answerState) => {
    const statusObj = colorCodes.find(item => item.status === answerState);
    return statusObj ? { message: statusObj.message, background: statusObj.background, foreground: statusObj.foreground } : { message: "Waiting For Paper Upload", background: "#fddc00", foreground: "#000" };
};

export const generateColumn = (portions) => {
    return portions.map((m) => {
        return (
            {
                name: m.id,
                label: m.name
            }
        )
    })
}

export const generateDataFromBundle = (data) => {
    let _subject = []
    // console.log(data)
    data?.forEach((d) => {
        d.Portions.forEach((p) => {
            d.Subjects.forEach((s) => {
                _subject.push({ groupId: d.groupId, portionId: p.id, subjectId: s.id })
            })
        })
    })
    // console.log(_subject)
    return _subject;
}


export const generateRowsEvaluated = (columns, subjects, navigate, section, state, subjectsInfo) => {

    let _all = []
    //console.log(columns);
    subjects.forEach((s, i) => {
        // console.log(s);
        let OrderedBundlePlanGroups = generateDataFromBundle(s.OrderedBundlePlanGroups)
        let _singleSubject = [...OrderedBundlePlanGroups, ...s.OrderedCustomPlanRecords]
        // console.log(_singleSubject, columns, s.Questions.StudentAnswers);
        let _single = {}
        _single.subject = <>{s.name}{s.parentId != null && s.subjectType == 'elective' ? <b><br />({subjects.find((a) => a.id == s.parentId)?.name})</b> : ""}{s.parentId == null && s.subjectType == 'elective' ? <><br /><BundleElectiveData subjectsInfo={subjectsInfo} index={i} subjectId={s.id} state={state} /></> : ""}</>;
        columns.forEach((c) => {
            if (c.name != "subject") {
                let index = _singleSubject.findIndex((f) => f.portionId === c.name)
                let evaluatedData = s.Questions?.find((f) => f.portionId === c.name)
                // console.log(s.Questions, evaluatedData, c.name);
                let evaluated = evaluatedData?.StudentAnswers[evaluatedData?.StudentAnswers?.length - 1]
                // console.log(evaluated);
                if (evaluated) {
                    _single[c.name] = (
                        <div style={{ display: "flex", justifyContent: "center", minWidth: "200px" }}>
                            {evaluated.answerState === 'completed' ? (
                                <button style={{
                                    backgroundColor: "#6aba2a",
                                    border: "solid 1px #e2e7ee",
                                    padding: "8px 25px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    fontSize: "11px",
                                    color: " #ffffff",
                                    fontWeight: "600"
                                }} onClick={() => { navigate("/dashboard/question/upload/evaluated/" + c.name + "/" + encryptString(evaluated.questionId) + "/" + encryptString(state?.sessionId)) }}>View</button>
                            ) : (
                                <button style={{
                                    backgroundColor: "#eee",
                                    border: "solid 1px #e2e7ee",
                                    padding: "8px 25px",
                                    borderRadius: "5px",
                                    cursor: "disabled !important",
                                    fontSize: "11px",
                                    color: "#000",
                                    fontWeight: "600"
                                }} >Waiting</button>
                            )
                            }
                        </div >
                    );
                } else {
                    _single[c.name] =
                        <div style={{ display: "flex", justifyContent: "center", minWidth: "200px" }}>
                            <button style={{
                                backgroundColor: "#eee",
                                border: "solid 1px #e2e7ee",
                                padding: "8px 25px",
                                borderRadius: "5px",
                                cursor: "disabled !important",
                                fontSize: "11px",
                                color: "#000",
                                fontWeight: "600"
                            }} >Waiting</button>
                        </div>
                }


            }
        })
        // console.log(_single);
        _all.push(_single)

    })
    // console.log(_all);
    return _all;

}


export const generateRowsUploadAnswer = (columns, subjects, navigate, section, state, subjectsInfo) => {

    let _all = []
    //console.log(columns);
    subjects.forEach((s, i) => {
        // console.log(s);
        let OrderedBundlePlanGroups = generateDataFromBundle(s.OrderedBundlePlanGroups)
        let _singleSubject = [...OrderedBundlePlanGroups, ...s.OrderedCustomPlanRecords]
        // console.log(_singleSubject)
        // console.log(subjects);
        // console.log(subjects.find((a) => a.id == 38).name)

        let _single = {}
        _single.subject = <>{s.name}{s.parentId != null && s.subjectType == 'elective' ? <b><br />({subjects.find((a) => a.id == s.parentId)?.name})</b> : ""}{s.parentId == null && s.subjectType == 'elective' ? <><br /><BundleElectiveData subjectsInfo={subjectsInfo} index={i} subjectId={s.id} state={state} /></> : ""}</>;
        columns.forEach((c) => {
            if (c.name != "subject") {
                let index = _singleSubject.findIndex((f) => f.portionId === c.name)
                // console.log(s.Questions)
                let filterQuestion = s.Questions?.filter((f) => f.portionId === c.name)
                let findQuestion = filterQuestion[filterQuestion.length - 1]
                let data = findQuestion?.StudentAnswers[findQuestion?.StudentAnswers?.length - 1]
                // console.log(data);
                if (index >= 0) {
                    let checkQuestion = s.Questions.findIndex((f) => f.portionId === c.name)
                    // console.log(findQuestion, c.name, section)
                    //console.log(findQuestion)
                    if (checkQuestion >= 0) {
                        _single[c.name] = <div style={{ display: "flex", justifyContent: "center", minWidth: "200px" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                                {
                                    section == 'question' ?
                                        findQuestion?.question ?
                                            <div >
                                                {/* <button style={{
                                                    backgroundColor: getStatusMessageAndColor(data?.answerState).background,
                                                    border: "solid 1px #e2e7ee",
                                                    padding: "8px 25px",
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                    fontSize: "11px",
                                                    color: getStatusMessageAndColor(data?.answerState).foreground,
                                                    fontWeight: "600",
                                                    height: "40px",
                                                    width: "100px"
                                                }} onClick={() => navigate("/dashboard/question/upload/" + encryptString(findQuestion?.id) + "/" + encryptString(state?.sessionId) + "/" + c.name)}>{getStatusMessageAndColor(data?.answerState).message}</button> */}
                                                <WaitingButton iconColor={getStatusMessageAndColor(data?.answerState).background} onClick={() => navigate("/dashboard/question/upload/" + encryptString(findQuestion?.id) + "/" + encryptString(state?.sessionId) + "/" + c.name)} text={getStatusMessageAndColor(data?.answerState).message} />
                                            </div>
                                            : <WaitingButton text="Question pending" />
                                        :
                                        _single[c.name] = <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <WaitingButton text="Question pending" />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    }
                    else {
                        _single[c.name] = <div style={{ display: "flex", justifyContent: "center", minWidth: "200px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <WaitingButton text="Question pending" />
                            </div>
                        </div>
                    }
                }
                else {
                    _single[c.name] = <div style={{ display: "flex", justifyContent: "center", minWidth: "200px" }}>
                        {s.subjectType != 'elective' || s.parentId ?
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                <div >
                                    <BuyNowButton text={"Buy Now"} onClick={() => navigate("/plans/customize/" + state.courseId + "/" + state.sessionId + "/" + state.syllabusId)} />
                                </div>
                            </div>
                            : ""
                        }
                    </div>
                }
            }
        })
        // console.log(_single);
        _all.push(_single)

    })
    // console.log(_all);
    return _all;

}
export const generateRows = (columns, subjects, navigate, section, state, subjectsInfo) => {

    let _all = []
    //console.log(columns);
    subjects.forEach((s, i) => {
        // console.log(s);
        let OrderedBundlePlanGroups = generateDataFromBundle(s.OrderedBundlePlanGroups)
        let _singleSubject = [...OrderedBundlePlanGroups, ...s.OrderedCustomPlanRecords]
        // console.log(_singleSubject)
        // console.log(subjects);
        // console.log(subjects.find((a) => a.id == 38).name)

        let _single = {}
        _single.subject = <>{s.name}{s.parentId != null && s.subjectType == 'elective' ? <b><br />({subjects.find((a) => a.id == s.parentId)?.name})</b> : ""}{s.parentId == null && s.subjectType == 'elective' ? <><br /><BundleElectiveData subjectsInfo={subjectsInfo} index={i} subjectId={s.id} state={state} /></> : ""}</>;
        columns.forEach((c) => {
            if (c.name != "subject") {
                let index = _singleSubject.findIndex((f) => f.portionId === c.name)
                let filterQuestion = s.Questions?.filter((f) => f.portionId === c.name)
                let findQuestion = filterQuestion[filterQuestion.length - 1]
                let studentAnswer = findQuestion?.StudentAnswers?.[0]?.answer
                if (index >= 0) {
                    let checkQuestion = s.Questions.findIndex((f) => f.portionId === c.name)
                    if (checkQuestion >= 0) {
                        _single[c.name] = <div style={{ display: "flex", justifyContent: "center", minWidth: "200px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                                {
                                    section === 'question' ? (
                                        findQuestion?.question ? (
                                            <>
                                                <div>
                                                    <ViewQuestionButton from="question" questionId={findQuestion?.id} pdf={findQuestion?.question} />
                                                </div>
                                                {/* <TableDotDropdown state={state} questionId={findQuestion?.id} /> */}
                                            </>
                                        ) : (
                                            <WaitingButton text="Waiting for Paper" />
                                        )
                                    ) : section === 'answer' ? (
                                        findQuestion?.answer ? (
                                            <div>
                                                <ViewQuestionButton from="answer" questionId={findQuestion?.id} pdf={findQuestion?.answer} />
                                            </div>
                                        ) : !studentAnswer ? (
                                            <WaitingButton text="Upload Answer Sheet" />
                                        ) : (
                                            <WaitingButton text="Waiting for Evaluation" />
                                        )
                                    ) : null
                                }

                            </div>
                        </div>
                    }
                    else {
                        _single[c.name] = <div style={{ display: "flex", justifyContent: "center", minWidth: "200px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <WaitingButton text="Waiting for Paper" />
                                {
                                    // section == 'question' ?
                                    //     <TableDotDropdown state={state} questionId={findQuestion?.id} /> : null
                                }

                            </div>
                        </div>
                    }
                }
                else {
                    _single[c.name] = <div style={{ display: "flex", justifyContent: "center", minWidth: "200px" }}>
                        {s.subjectType != 'elective' || s.parentId ?
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div >
                                    <BuyNowButton text={"Buy Now"} onClick={() => navigate("/plans/customize/" + state.courseId + "/" + state.sessionId + "/" + state.syllabusId)} />
                                </div>
                                {
                                    // section == 'question' ?
                                    //     <TableDotDropdown state={state} questionId={findQuestion?.id} /> : null
                                }
                            </div>
                            : ""
                        }
                    </div>
                }
            }
        })
        // console.log(_single);
        _all.push(_single)

    })
    // console.log(_all);
    return _all;

}

export const generateForMobile = (columns, rows) => {
    let data = []
    rows.map((r) => {
        let single = {
            name: r.subject,
            links: [],
            open: false,
        }
        let links = []
        columns.map((c) => {
            links.push({ title: c.label, button: r[c.name], to: "#" },)
        })
        single.links = [...links]
        data.push(single)
    })
    return data;
}