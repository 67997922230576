import React, { useState } from "react";
import logo1 from "../../../assets/img/prepca-logo-new.png";
import classes from "./styles.module.scss";
import HeadphonesIcon from '@mui/icons-material/Headphones';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Hamburger from "./hamburger";
import { NavLink } from "react-router-dom";
import GlobalModal from "../../../components/globalModal";
import ImageCarousel from "../../../components/imageCarousel";
import { useEffect } from "react";
import SignpostOutlinedIcon from '@mui/icons-material/SignpostOutlined';
import { Avatar } from "@mui/material";
import { deepPurple, green } from "@mui/material/colors";
import { useSelector } from "react-redux";
import VideoCallIcon from '@mui/icons-material/VideoCall';

const TopBar = (props) => {
    const [globalModal, setGlobalModal] = useState(false);
    useEffect(() => {
        const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
        if (!hasVisitedBefore) {
            setGlobalModal(true);
            localStorage.setItem('hasVisitedBefore', 'true');
        }
    }, []);


    const profileData = useSelector((state) => state.profile)

    const isMentorShipStudent = useSelector((state) => state.Auth?.authDetails?.isMentorShipStudent)


    return (
        <div className={classes.container}>
            {
                globalModal ?
                    <GlobalModal small={true} setIsOpen={setGlobalModal} open={globalModal} close={() => setGlobalModal(false)}>
                        <ImageCarousel setIsOpen={setGlobalModal} />
                    </GlobalModal> : ""
            }
            <div className={classes.content}>

                <div className={classes.logo}>
                    <NavLink to="/dashboard/question">
                        <img className={classes.logo_main} src={logo1} />
                    </NavLink>
                </div>

                <div className={classes.list_item}>
                    <ul className={classes.list}>
                        <li className={classes.cart}>
                            <NavLink to="/dashboard/buy-counselling">
                                <VideoCallIcon sx={{ fontSize: "32px" }} />
                                <span >{isMentorShipStudent ? "Buy Mentorship" : "Buy Counseling"}</span>
                            </NavLink>
                        </li>
                        <li className={classes.support}>
                            <a href="https://support.prepca.com/" target="_blank">
                                <HeadphonesIcon />
                                <span >Support</span>
                            </a>
                        </li>

                        <li className={classes.cart}>
                            <NavLink to="/plans/bundle">
                                <ShoppingCartIcon />
                                <span >Buy Now</span>
                            </NavLink>
                        </li>
                        {/* <li className={classes.support}>
                            <a style={{ backgroundColor: "#fddc00", borderRadius: "5px", padding: "0px 5px 0px 5px" }} href="javascript:void(0);" onClick={() => setGlobalModal(true)}>
                                <span>Take Tour</span>
                            </a>
                        </li> */}
                        {/* <li className={classes.bell}>
                            <NavLink to="/dashboard/notification">
                                <div>
                                    <NotificationsIcon />
                                    <div className={classes.bell_dot}></div>
                                </div>
                                <span>.</span>
                            </NavLink>
                        </li> */}

                        <li className={classes.account}>
                            {profileData?.allProfiles?.firstName ? (
                                <Avatar onClick={props.handleClick} sx={{ bgcolor: deepPurple[500], width: 38, height: 38 }}>
                                    {profileData?.allProfiles?.firstName.length >= 2 ? profileData?.allProfiles?.firstName.substring(0, 2) : ''}
                                </Avatar>
                            ) : (
                                <AccountCircleIcon onClick={props.handleClick} />
                            )}
                        </li>

                    </ul>
                    <div className={classes.hamburger_menu}>
                        <Hamburger />
                    </div>
                </div>
            </div>
            {
                props.children
            }
        </div >
    )
}

export default TopBar;