import React from "react";
import classes from "./styles.module.scss";

const BuyNowButton = (props) => {
    return(
        <div className={classes.buynow_button} onClick={props.onClick}>
            <button  className={classes.button}>{props?.text}</button>
        </div>
    )
}

export default BuyNowButton;