import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import PageHeading from "../../components/pageHeading";
import PageTable from "../../container/pageTable";
import { onLoad, onDownloadAll } from "../questions/actions";
import { generateColumn, generateRows, generateRowsEvaluated, generateForMobile } from "../../shared/questions";
import InputSelect from "../../components/inputSelect";
import { Grid, StepLabel } from '@mui/material/';
import { fetchCourse, fetchCourseSyllabus, getSubjectAndPortion, fetchSession } from "../../pages/profile/action";
import { actionNotifier } from "../../components/ui/toast";
import PrimaryButton from "../../components/button";
import classes from "../questions/styles.module.scss";
import { onGetProfile } from "../profile/action";
import { getLatestSessionId } from "../../shared/getLatestSession";
const Evaluated = (props) => {
    //console.log(props);
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])
    const [mobileRows, setMobileRows] = useState([])
    const [uCourse, setUCourse] = useState([])
    const [uSession, setUSession] = useState([])
    const [uCourseSyllabus, setUCourseSyllabus] = useState([])
    const [uSubject, setUSubject] = useState([])
    const [openBundleElectiveModal, setOpenBundleElectiveModal] = useState(false)

    const [state, setState] = useState({
        "courseId": null,
        "sessionId": null,
        "syllabusId": null
    });

    useEffect(() => {
        if (state.syllabusId && state.sessionId && state.courseId) {
            let data = {
                "courseId": Number(state.courseId),
                "sessionId": Number(state.sessionId),
                "syllabusId": Number(state.syllabusId)
            };
            props.onLoad(data)
        }
    }, [state, props.onLoad]);

    useEffect(() => {
        if (!state.courseId || !state.sessionId || !state.syllabusId) {
            setState({
                "courseId": props?.profile?.allProfiles?.Syllabus?.courseId,
                "sessionId": props?.profile?.allProfiles?.currentSession?.id,
                // sessionId:3,
                "syllabusId": props?.profile?.allProfiles?.syllabusId
            });
        }
    }, [props?.profile?.allProfiles, state.courseId, state.sessionId, state.syllabusId]);

    useEffect(() => {
        props.onGetProfile()
    }, []);


    useEffect(() => {
        if (props?.question?.allOrders?.questions?.length > 0)
            generateQuestions(props?.question?.allOrders?.questions)
    }, [props.question.allOrders, openBundleElectiveModal])
    const navigate = useNavigate()
    // useEffect(() => {
    //     if (props?.question?.allOrders?.questions?.length > 0)
    //         generateQuestions(props?.question?.allOrders?.questions)
    // }, [props.question.allOrders])
    const generateQuestions = (allOrders, electiveSubject) => {
        //console.log(allOrders)
        let subjects = [];
        let portions = [];
        allOrders.forEach((f) => {
            subjects.push(...f.Subjects)
            portions = f.Portions
        })
        let columns = generateColumn(portions)
        columns.sort((a, b) => {
            return a.name - b.name
        })
        let newColumns = [
            {
                name: "subject",
                label: "Subject"
            },
            ...columns
        ]
        setColumns(newColumns)

        // let rows = generateRows(newColumns, subjects, navigate, "answer", state)
        // setRows(rows)


        let rows = generateRowsEvaluated(newColumns, subjects, navigate, "answer", state)
        setRows(rows)

        let mobileRows = generateForMobile(columns, rows)
        setMobileRows(mobileRows)
    }

    //console.log(openBundleElectiveModal);


    const handleChange = (name, value) => {
        let _state = { ...state };
        _state[name] = value;
        setState(_state)
    }
    const fetchCourseData = async () => {
        let res = await fetchCourse()
        if (res.status) {
            setUCourse(res.data)
        }
    }
    const onDownloadAll = async () => {
        let data = {
            "sessionId": Number(state.sessionId),
            "syllabusId": Number(state.syllabusId),
            //"subjectId": 1,
            //"portionId": 1
        }
        props.onDownloadAll(data, 'answer')
    }

    const fetchSyllabus = async (e) => {
        // alert(e)
        let res = await fetchCourseSyllabus(e)
        //console.log(res)
        if (res.status) {
            setUCourseSyllabus(res.data)
        }
    }
    const fetchSessionData = async (e) => {
        // alert(e)
        let res = await fetchSession(e)
        if (res.status) {
            if (res.data.length > 0) {
                // const sessionId = getLatestSessionId(res.data)
                // setState(prev => ({ ...prev, sessionId: sessionId }));
                setUSession(res.data)
            }
            else
                actionNotifier.error("This paper is not in your order !");
        }
    }
    useEffect(() => {
        fetchCourseData()
    }, [])
    // useEffect(() => {
    //     fetchSessionData(Number(state?.courseId))
    //     fetchSyllabus(Number(state?.courseId))
    // }, [state?.courseId])

    // const onGetSubjectAndPortion = async (e) => {
    //     // alert(e)
    //     let res = await getSubjectAndPortion(e)
    //     // console.log(res)
    //     if (res.status) {
    //         let _subject = []
    //         let _portion = [];
    //         res.data.map((r) => {
    //             _subject.push(...r.Subjects)
    //             _portion = [...r.Portions]
    //         })
    //         setUSubject(_subject)
    //     }
    // }
    useEffect(() => {
        if (state.courseId) {
            fetchSessionData(Number(state?.courseId))
            fetchSyllabus(Number(state?.courseId))
            // onGetSubjectAndPortion()
        }
    }, [state.courseId])

    // useEffect(() => {
    //     onGetSubjectAndPortion(Number(state?.syllabusId))
    // }, [state?.syllabusId])
    return (
        <div>
            <PageHeading head="Evaluated Answer Sheets" />
            <Grid container spacing={2} className={classes.top_content}>
                <Grid item xs={12} sm={3} className={classes.top_content_heading}>
                    <h5 className={classes.margin_Top}>{props?.question?.allOrders?.session?.name} {props?.question?.allOrders?.syllabus?.Course?.name} {props?.question?.allOrders?.syllabus?.name}</h5>
                </Grid>
                <Grid item xs={12} sm={2} className={classes.top_content_filter}>
                    <InputSelect
                        required={true}
                        options={uCourse}
                        value={state.courseId}
                        name="courseId"
                        placeholder="Select Course"
                        label="Course"
                        onChange={(e) => handleChange("courseId", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={2} className={classes.top_content_filter}>
                    <InputSelect
                        required={true}
                        options={uCourseSyllabus}
                        value={state.syllabusId}
                        name="syllabusId"
                        placeholder="Select Syllabus"
                        label="Exam Level"
                        onChange={(e) => handleChange("syllabusId", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={2} className={classes.top_content_filter}>
                    <InputSelect
                        required={true}
                        options={uSession}
                        value={state.sessionId}
                        name="sessionId"
                        placeholder="Select Session"
                        label="Attempt"
                        onChange={(e) => handleChange("sessionId", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={3} className={classes.download_all_button}>
                    {/* <button onClick={() => onDownloadAll()}>Download All Papers</button> */}
                </Grid>

            </Grid>
            {/* <h5>{props?.question?.allOrders?.session?.description} {props?.question?.allOrders?.syllabus?.Course?.name} {props?.question?.allOrders?.syllabus?.name}</h5> */}
            <PageTable
                columns={columns}
                rows={rows}
                mobileRows={mobileRows}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        question: state.Questions,
        profile: state.profile,
    }
}
const mapDispatchToProps = { onLoad, onDownloadAll, onGetProfile }
export default connect(mapStateToProps, mapDispatchToProps)(Evaluated);