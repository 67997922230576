import React, { lazy, useEffect, useState, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import AppRoute from "./route";
import Layout from "../container/layout"
import Evaluated from "../pages/evaluated";
import BuyCounsellingPage from "../pages/councelling/buyCounselling";


// ---------- Routes ---------- //
const Counseling = lazy(() => import("../pages/councelling"));
const ApplyCounselling = lazy(() => import("../pages/councelling/applyCounselling"));
const CounsellingSchedule = lazy(() => import("../pages/councelling/applyCounselling/schedule"));
const CounsellingDetails = lazy(() => import("../pages/councelling/counsellingDetails"));

const Sample = lazy(() => import("../pages/sample"));
const Login = lazy(() => import("../pages/login"));
const PlanPage = lazy(() => import("../pages/planPage"));
const LayoutPage = lazy(() => import("../container/layout"));
const Question = lazy(() => import("../pages/questions"));
const Answer = lazy(() => import("../pages/answers"))
const Invoice = lazy(() => import("../pages/invoice"));
const ReferralsGift = lazy(() => import("../pages/referralsGift"));
const Feedback = lazy(() => import("../pages/feedback"));
const MyOrders = lazy(() => import("../pages/myOrders"));
const SupportPage = lazy(() => import("../pages/supportPage"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const UploadAnswerPage = lazy(() => import("../pages/uploadAnswerPage"));

const Notification = lazy(() => import("../pages/notification"));
const RightBar = lazy(() => import("../container/layout/rightBar"));
const Registration = lazy(() => import("../pages/registration"));
const SyllabusPage = lazy(() => import("../pages/dashboard/syllabusPage"));
const Checkout = lazy(() => import("../pages/checkout"));
const DownloadCenter = lazy(() => import("../pages/downloadCenter"));
const Planner = lazy(() => import("../pages/planner"));
const DownloadCenterFiles = lazy(() => import("../pages/downloadCenter/downloadCenterFiles"));
const Logout = lazy(() => import("../pages/logout"));
const ResetPassword = lazy(() => import("../pages/resetPassword"));
const UploadAnswer = lazy(() => import("../pages/uploadAnswer"));
const CompleteProfile = lazy(() => import("../pages/completeProfile"));
const openRoutes = [
    // { path: "/", exact: true, component: Sample },
    { path: "/registration/profile", exact: true, component: CompleteProfile },
    { path: "/registration/profile/:mobileNo", exact: true, component: CompleteProfile },
    // { path: "/plans", exact: true, component: PlanPage },
    // { path: "/plans/:type", exact: true, component: PlanPage },
    // { path: "/plans/:type/:courseId/:sessionId/:syllabusId", exact: true, component: PlanPage },
    { path: "/", exact: true, component: Login },
    { path: "/user-authentication/:loginType", exact: true, component: Login },
    { path: "/user-authentication/:loginType/:mobileNo", exact: true, component: Login },
    { path: "/invoice", exact: true, component: Invoice },
    { path: "/invoice/:id", exact: true, component: Invoice },
    { path: "/order/details/key/:key", exact: true, component: Invoice },
    { path: "/download-center", exact: true, component: DownloadCenter },
    { path: "/planner", exact: true, component: Planner },
    { path: "/checkout", exact: true, component: Checkout },
    { path: "/checkout/:type", exact: true, component: Checkout },
    { path: "/logout", exact: true, component: Logout },
    { path: "/registration", exact: true, component: Registration },
    { path: "/reset-password", exact: true, component: ResetPassword },
    { path: "/*", exact: true, component: Login },
    // { path: "/plans/:type/:orderId/:token", exact: true, component: PlanPage },
    // { path: "/*", navigate: "/" },
    // { path: "/logout", exact: true, navigate: "/" },

];


const privateRoute = [
    // { path: "/", navigate: "/" },
    { path: "/plans", exact: true, component: PlanPage },
    { path: "/*", exact: true, navigate: "/dashboard/question" },
    // { path: "/", exact: true, component: Question },
    { path: "/plans/:type", exact: true, component: PlanPage },
    { path: "/plans/:type/:courseId/:sessionId/:syllabusId", exact: true, component: PlanPage },
    { path: "/invoice", exact: true, component: Invoice },
    { path: "/invoice/:id", exact: true, component: Invoice },
    { path: "/order/details/key/:key", exact: true, component: Invoice },
    { path: "/download-center", exact: true, component: DownloadCenter },
    { path: "/checkout", exact: true, component: Checkout },
    { path: "/checkout/:type", exact: true, component: Checkout },
    { path: "/logout", exact: true, component: Logout },
    { path: "/registration/profile", exact: true, component: CompleteProfile },
    { path: "/registration/profile/:mobileNo", exact: true, component: CompleteProfile },
    { path: "/plans/:type/:orderId/:token", exact: true, component: PlanPage },
]

const dashboardRoutes = [
    { path: "/dashboard/counseling", exact: true, component: Counseling },
    { path: "/dashboard/apply-counselling/:type", exact: true, component: ApplyCounselling },
    { path: "/dashboard/schedule-counselling/:type", exact: true, component: CounsellingSchedule },
    { path: "/dashboard/counseling-details/:id", exact: true, component: CounsellingDetails },
    { path: "/dashboard/counseling/:status", exact: true, component: Counseling },
    { path: "/dashboard/buy-counselling", exact: true, component: BuyCounsellingPage },
    // { path: "/dashboard/counseling/:status/:counselling/:courseId?/:syllabusId?/:syllabusId?/:sessionId?", exact: true, component: Counseling },

    { path: "/*", exact: true, navigate: "/dashboard/question" },
    { path: "/dashboard/question", exact: true, component: Question },
    { path: "/dashboard/upload-answer", exact: true, component: UploadAnswerPage },
    { path: "/dashboard/evaluated", exact: true, component: Evaluated },
    { path: "/dashboard/answer", exact: true, component: Answer },
    { path: "/dashboard/refer", exact: true, component: ReferralsGift },
    { path: "/dashboard/feedback", exact: true, component: Feedback },
    { path: "/dashboard/invoice/:id", exact: true, component: Invoice },
    { path: "/dashboard/download-center", exact: true, component: DownloadCenter },
    { path: "/dashboard/planner", exact: true, component: Planner },
    { path: "/dashboard/download-center/files", exact: true, component: DownloadCenterFiles },
    { path: "/dashboard/orders", exact: true, component: MyOrders },
    { path: "/dashboard/support", exact: true, component: SupportPage },
    { path: "/dashboard/dash", exact: true, component: Dashboard },
    { path: "/dashboard/notification", exact: true, component: Notification },
    { path: "/dashboard/account", exact: true, component: RightBar },
    { path: "/dashboard/dash/syllabus", exact: true, component: SyllabusPage },
    { path: "/dashboard/question/upload", exact: true, component: UploadAnswer },
    { path: "/dashboard/question/upload/:questionId/:sessionId/:portionId", exact: true, component: UploadAnswer },
    { path: "/dashboard/question/upload/:from/:portionId/:questionId/:sessionId", exact: true, component: UploadAnswer }
    //{ path: "/question", exact: true, component: Question },
];

const PagesRoute = (props) => {
    const location = useLocation()
    const [allRoutes, setAllRoutes] = useState([])
    const [isDashboard, setDashboard] = useState(false)

    useEffect(() => {
        if ((props.auth?.token?.access_token && isDashboard)) {
            setAllRoutes([...dashboardRoutes, ...privateRoute])
        }
        else if (props.auth?.token?.access_token) {
            setAllRoutes([...privateRoute])
        }
        else if (!props.auth?.token?.access_token) {
            setAllRoutes([...openRoutes])
        }

    }, [props.auth?.token?.access_token, isDashboard])

    useEffect(() => {
        if (location.pathname.includes("dashboard")) {
            setDashboard(true)
        }
        else {
            setDashboard(false)
        }
    }, [location.pathname])

    const generateRoute = (allRoutes) => {
        let _data = [];
        allRoutes.map(({ path, component: Component, navigate, ...route }, i) => {
            _data.push(<Route
                path={path}
                key={i}
                exact={true}
                element={
                    navigate ? <Navigate replace to={navigate} /> :
                        <Suspense fallback={<></>}>
                            <Component />
                        </Suspense>
                }
            />)
        })
        return <Routes>
            {_data}
        </Routes>

    }

    return (
        <div>
            {
                ((isDashboard && props.auth?.token?.access_token))
                    ?
                    <Layout>
                        {/* {dashboardRoutes.map((route, idx) => (
                            <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={true} />

                        ))} */}
                        {generateRoute(allRoutes)}
                    </Layout>
                    :
                    <>


                        {/* {allRoues.map((route, idx) => (
                            <AppRoute path={route.path} component={route.component} key={idx} isAuthProtected={true} />
                        ))} */}
                        {generateRoute(allRoutes)}
                    </>

            }
        </div>
    );
};

export default PagesRoute;
