export const generateCustomizeInvoice = (portion, order, service) => {
    let _allData = {}
    let syllabus = new Set();
    let quantity = 0;
    order.forEach((o, i) => {
        // OrderedCustomPlanRecords
        // k.OrderedCustomPlanRecords
        // k.OrderedCustomPlanRecords?.find(obj => obj.Group.Syllabus.id === c.id)
        // console.log(order);
        quantity += o.OrderedCustomPlanRecords.length

        o.OrderedCustomPlanRecords.forEach((c, j) => {
            let hsnRate = getHNSPerPlan(o.OrderedCustomPlanPriceServiceWise, c.Group.Syllabus.id, c.Session.id)
            syllabus.add(c.Group.Syllabus.name)

            let _single = {
                paper: c.Subject?.name,
                session: c.Session?.name,
                level: c.Group.Syllabus.Course.name + " " + c.Group.Syllabus.name,
                program: o.Plan?.name,
                rate: Math.round(hsnRate._price),
                hsnsac: hsnRate.hsn.join("\n"),
                total_rate: hsnRate.rate.join("\n"),
                hsnRate:hsnRate,
            }
            if (!_allData[c.Portion?.name]) {
                _allData[c.Portion?.name] = []
            }

            _allData[c.Portion?.name].push(_single)
        })
    })
    // console.log(_allData);
    return {
        allData: _allData,
        quantity: quantity,
        syllabus: [...syllabus]
    };
}

const getSyllabusCourse = (groups) => {
    // console.log(groups);
    const syllabus = new Set();
    const course = new Set();
    const session = new Set();
    groups.forEach((e, i) => {
        syllabus.add(e.Groups.Syllabus.name);
        course.add(e.Groups.Syllabus.Course.name);
        session.add(e.Session.name);
    });
    return { syllabus: syllabus, course: course, session: session }
};
export const generateBundleInvoice = (order, stateId) => {

    let _allData = []
    let isIGST = false;

    order.forEach((c, i) => {
        let _single = {
            paper: <>
                <div style={{ display: "flex" }}>
                    <div>Name :&nbsp;</div>
                    <div>{getSyllabusCourse(c.OrderedBundlePlanGroups).course} {getSyllabusCourse(c.OrderedBundlePlanGroups).syllabus} ({c?.BundlePlanPrice?.BundlePlan?.name})<br />{getSyllabusCourse(c.OrderedBundlePlanGroups).session}</div>
                </div>
            </>,
            program: c.program,
            quantity: 1,
            discount: c.discountType === "flat" ? c.discount : c.discount + "%",
            hsnsac: c?.GST?.name,
            rate: <>
                {"₹ " + c.amountAfterDiscount}
                <br />
                {/* {(c.iGST ? "IGST: " + (c.iGST + c.iGST) + "%" : stateId == 21 ? "CGST+SGST: " + c.cGST + "%" : "IGSHJBT: " + c.cGST + "%")} */}
                {stateId === 21 ? `CGST+SGST: ${Number(c.cGST + c.sGST)}%` : `IGST: ${c.cGST ? Number(c.cGST + c.sGST) : c.iGST}%`}
            </>,
            total: <>{"₹ " + c.amountAfterDiscount}<br />{"₹ " + c.gstAmount.toFixed(2)}</>
        }
        if (c.iGST) {
            isIGST = true
        }
        _allData.push(_single)
    })
    return {
        allData: _allData,
        isIGST: isIGST
    };
}


const getHNSPerPlan = (service, syllabusId, sessionID) => {
    let hsn = []
    let rate = []
    let discount = []
    let _price = 0
    service.forEach((s, i) => {
        if (s.syllabusId === syllabusId && s.session === sessionID) {
            hsn.push(s.GST?.name)
            rate.push(s.price)
            discount.push(s.discount)
            _price = _price + s.price
        }
    })
    return {
        hsn,
        rate,
        _price,
        discount
    }

}