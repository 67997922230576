import React, { useState, useEffect, useRef } from "react";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import classes from "./styles.module.scss";
import InvoiceCustomizeContainer from "../../../../container/InvoiceContainer/invoiceCustomizeContainer";
import InvoiceBundleContainer from "../../../../container/InvoiceContainer/invoiceBundleContainer";
import BillHeader from "../../../invoiceComponent/billHeader";
import { invoiceData } from "../../../../container/InvoiceContainer/invoiceCustomizeContainer/data";
import { priceInWords } from "../../../../shared/priceToWord";
import { Container, Grid } from '@mui/material/';
const style = {
    position: 'absolute',
    top: '10%',
    left: '10%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    backgroundColor: '#eee',
    boxShadow: 24,
    color: "black !important",
    padding: 4,
    height: "100%",
    overflow: "auto",
    padding: "10px",
    color: "black !important"
};
export default function InvoiceModal(props) {
    //console.log(props);
    const getSyllabusCourse = () => {
        const syllabus = new Set();
        const course = new Set();

        props?.invoice?.orderData?.order?.OrderedCustomPlans.forEach((e, i) => {
            e.OrderedCustomPlanRecords.forEach((s, si) => {
                syllabus.add(s.Group.Syllabus.name);
                course.add(s.Group.Syllabus.Course.name);
            });
        });

        props?.invoice?.orderData?.order?.OrderedBundlePlans.forEach((e, i) => {
            e.OrderedBundlePlanGroups.forEach((s, si) => {
                syllabus.add(s.Groups.Syllabus.name);
                course.add(s.Groups.Syllabus.Course.name);
            });
        });
        return { syllabus: syllabus, course: course }
    };

    const componentRef = useRef();
    const [print, setPrint] = useState(false);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const closePrint = () => {
        setPrint(false);
    }
    const companyData = invoiceData.companyDetails;
    const billerData = invoiceData.billerDetails;
    const invoiceDetails = invoiceData.invoiceDetails;
    const planData = invoiceData.planDetails;

    const getRoundOff = (total, totalBeforeGST, totalTax) => {
        return (total - (totalBeforeGST + totalTax)).toFixed(2);
    }

    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            onClose={() => props.setIsOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >

            <Fade in={props.open}>
                <Box className={classes.boxStyle}>
                    {/* <Typography id="transition-modal-title" variant="h6" component="h2">
                        {props.head}
                        <i className="fa fa-shopping-cart"></i>
                    </Typography> */}
                    <div className={classes.total_body}>
                        <span></span>
                        <span><i className="fa fa-times" style={{ width: "10%" }} onClick={() => props.setIsOpen(false)}></i></span>
                    </div>
                    <Typography style={{ color: "black !important" }} id="transition-modal-description" sx={{ mt: 2 }}>
                        <Container maxWidth="lg">
                            <Grid item className={classes.invoice_body} >
                                <div ref={componentRef} style={{ padding: "20px" }}>
                                    <BillHeader addressDetails={props?.addressDetails} centerDetails={props.centerDetails} courseSyllabus={getSyllabusCourse()} selectedAddress={props.selectedAddress} profile={props.profile} handlePrint={handlePrint} order={props.invoice.orderData?.order} companyData={companyData} billerData={billerData} invoiceData={invoiceDetails} />
                                    {
                                        props.invoice.orderData?.order?.OrderedBundlePlans && props.invoice.orderData?.order?.OrderedBundlePlans.length > 0
                                            ?
                                            <InvoiceBundleContainer />
                                            : null

                                    }
                                    {
                                        props.invoice.orderData?.order?.OrderedCustomPlans && props.invoice.orderData?.order?.OrderedCustomPlans.length > 0
                                            ?
                                            <InvoiceCustomizeContainer />
                                            : null

                                    }
                                    <table className={classes.bundle_bill_table}>
                                        <tr><td colspan="5"></td></tr>
                                        <tr>
                                            <td colspan="3">
                                                <b>{priceInWords(Math.round(props.invoice.orderData?.order?.totalAmountAfterGST)) + " only"}</b >
                                            </td>



                                            <td>
                                                <p>Total Rate</p>
                                                {
                                                    props.invoice.orderData?.order?.totalDiscount ?
                                                        <p>Discount (-)</p> : null
                                                }
                                                <p>Sub Total</p>
                                                {/* <p>Total Discount</p> */}


                                                {
                                                    props?.invoice?.orderData?.order?.District?.stateId == 21 ?
                                                        <>
                                                            <p>CGST</p>
                                                            <p>SGST</p>
                                                        </> :
                                                        <><p>IGST</p></>
                                                }



                                                {/* <p>Total Tax</p> */}

                                                <p>Round Off (+)</p>
                                            </td>
                                            <td className={`${classes.font_bold}`}>
                                                <p>₹ {props.invoice.orderData?.order?.totalAmount?.toFixed(2)}/-</p>
                                                {
                                                    props.invoice.orderData?.order?.totalDiscount ?
                                                        <p>
                                                            ₹ {props.invoice.orderData?.order?.totalDiscount.toFixed(2)}/-
                                                        </p> : null
                                                }
                                                <p>₹ {props.invoice.orderData?.order?.totalAmountAfterDiscount?.toFixed(2)}/-</p>
                                                {/* <p>₹ {props.invoice.orderData?.order?.totalDiscount}/-</p> */}
                                                {
                                                    props.isIgst
                                                        ?
                                                        <>
                                                            <p>₹ {props.invoice.orderData?.order?.totalGST?.toFixed(2)}/-</p>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                props?.invoice?.orderData?.order?.District?.stateId == 21 ?
                                                                    <>
                                                                        <p>₹ {(props.invoice.orderData?.order?.totalGST / 2)?.toFixed(2)}/-</p>
                                                                        <p>₹ {(props.invoice.orderData?.order?.totalGST / 2)?.toFixed(2)}/-</p>
                                                                    </> :
                                                                    <><p>₹ {(props.invoice.orderData?.order?.totalGST)?.toFixed(2)}/-</p></>
                                                            }

                                                        </>
                                                }
                                                {/* <p>₹ {props.invoice.orderData?.order?.totalGST?.toFixed(2)}/-</p> */}
                                                <p>
                                                    ₹ {getRoundOff(props.invoice.orderData?.order?.totalAmountAfterGST, props.invoice.orderData?.order?.totalAmountAfterDiscount, props.invoice.orderData?.order?.totalGST,)}/-
                                                </p>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">

                                            </td>
                                            <td className={classes.total_amount}>
                                                Total Amount
                                            </td>
                                            <td className={`${classes.total_amount} ${classes.font_bold}`}>
                                                <b>₹ {props.invoice.orderData?.order?.totalAmountAfterGST.toFixed(2)}/-</b>
                                            </td>

                                        </tr>
                                    </table>
                                    {/* <p className={classes.term_condition}>
                                        <h3>Terms & Conditions</h3>
                                        <ul>
                                            <li>Fees once paid will not be refundable/carried forward under any circumstances other than {process.env.REACT_APP_WEBSITE_NAME} Assured policy.</li>
                                            <li>You can visit {process.env.REACT_APP_MONEY_BACK_URL} for {process.env.REACT_APP_WEBSITE_NAME} Assured Policy</li>
                                        </ul>
                                    </p> */}
                                </div>
                            </Grid>

                        </Container>
                    </Typography>
                </Box>
            </Fade>
        </Modal >

    );
}
