export const checkPath = (name) => {
    // console.log(name);
    if (name) {
        if (name.includes("https://") || name.includes("http://")) {
            return name;
        }
        else
            return process.env.REACT_APP_IMG_URL + name
    } else {
        return process.env.REACT_APP_IMG_URL + name
    }
}