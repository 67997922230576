export default function listenForOutsideClicks(
    listening,
    setListening,
    menuRef,
    setIsOpen
  ) {
    return () => {
      if (listening) return;
      if (!menuRef.current) return;
      setListening(true);
  
      const handleClickOutside = (evt) => {
        const cur = menuRef.current;
        const node = evt.target;
        if (cur?.contains(node)) return;
        setIsOpen(false);
        setListening(false); 
      };
  
      [`click`, `touchstart`].forEach((type) => {
        document.addEventListener(type, handleClickOutside);
      });
  
      return () => {
        [`click`, `touchstart`].forEach((type) => {
          document.removeEventListener(type, handleClickOutside);
        });
      };
    };
  }
  