import React, { useState } from 'react';
import { Button, MobileStepper, Paper, Typography, Stack } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import classes from "./styles.module.scss";
import step1 from "../../assets/images/Layer 1.png";
import step2 from "../../assets/images/Layer 2.png";
import step3 from "../../assets/images/Layer 3.png";
import step4 from "../../assets/images/Layer 4.png";
import step5 from "../../assets/images/Layer 5.png";
import step6 from "../../assets/images/Layer 6.png";
import tutorial from "../../assets/tutorial-new.pdf"
const images = [{ title: "Select Course, Exam Level, Attempt", description: "Students need to select their respective Course - Exam level - And the attempt they are giving", image: step1 },
{ title: "Select a plan bundle or customize", description: <> <b>Bundle plan</b> -Avail heavy discounts by purchasing combo test papers.<br /><b>Customized Plan</b> - Purchase test papers as per your requirement</>, image: step2 },
{ title: " If bundle - select Group, then select the pricing plan and hit add to cart", description: "", image: step3 },
{ title: "If customize- select  paper(s), select pricing plan, hit add to cart", description: "", image: step4 },
{ title: "Review your cart -  click next button- referred by, select your preference- Center / Home, Accept TnC, Click Confirm", description: "", image: step5 },
{ title: "Update address, confirm order, and make payment.", description: "", image: step6 }];

const ImageCarousel = (props) => {
    console.log(images);
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div className={classes.carouselContainer}>
            <Paper square elevation={0} className={classes.carouselCard}>
                <Typography className={classes.cardImageContainer}>
                    <img src={images[activeStep]?.image} />
                </Typography>
                <h3>{images[activeStep]?.title}</h3>
                <p style={{ fontSize: "13px" }}>{images[activeStep]?.description}</p>
            </Paper>
            <MobileStepper
                variant={null}
                steps={images.length}
                position="static"
                activeStep={activeStep}
                backButton={null} // Hide default back button
                nextButton={null} // Hide default next button
                className="custom-stepper"
            />
            <div className={classes.buttonsCard}>
                <Typography variant="subtitle1">
                    Step {activeStep + 1} of {images.length}
                </Typography>
                <div className={classes.buttons}>
                    <Button
                        className={classes.prev_buttons}
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                    >
                        Back
                    </Button>
                    <Button
                        className={classes.next_buttons}
                        size="small"
                        onClick={activeStep - 1 === images.length - 1 ? props.setIsOpen(false) : handleNext}
                        disabled={activeStep - 1 === images.length - 1}
                    >
                        {activeStep === images.length - 1 ? "Close" : "Next"}
                    </Button>
                </div>
            </div>
            <div className={classes.full_tutorial}><a href={tutorial} target='_blank'>Click here to view full tutorial</a></div>
        </div>
    );
};

export default ImageCarousel;
