import React, { useEffect, useState, useRef } from "react";
import classes from "./styles.module.scss";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import listenForOutsideClicks from "../../../shared/listen-for-outside-clicks";
import { onGetElectiveSubject, onSetElectiveSubject } from "../../questions/actions";
import { connect } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const BundleElectiveData = (props) => {
    // console.log(props)
    const [state, setState] = useState({
        "selectElectiveId": 0
    })
    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);
    const [show, toggleShow] = React.useState(false);
    useEffect(listenForOutsideClicks(listening, setListening, menuRef, toggleShow));
    const func = () => {
        // console.log(props.open);
        props.setIsOpen(!props.open)
    }
    useEffect(() => {
        props.onGetElectiveSubject(props.subjectId)
    }, [props.subjectId])
    const handleElectiveModal = (subjectId, show) => {
        props.onGetElectiveSubject(subjectId)
        toggleShow(!show)
    }
    const onSetElectiveSubject = (electiveSubjectId) => {
        let data = {
            "sessionId": Number(props.state.sessionId),
            "syllabusId": Number(props.state.syllabusId)
        }
        props.onSetElectiveSubject(electiveSubjectId, data)
    }
    const handleSetElective = (name, value) => {
        // console.log(props?.question?.electiveSubject, props?.subjectsInfo);
        let _state = { ...state };
        _state[name] = value;
        setState(_state)



        let electiveStr = ""
        props?.question?.electiveSubject && props?.question?.electiveSubject?.map((c, i) => {
            let find = props?.subjectsInfo?.find(f => f.id == Number(value))
            if (electiveStr != "")
                electiveStr += ", "
            electiveStr += find?.name
        })


        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h4>PREPCA message</h4>
                        <h5>" Hi you have chosen <u>{props?.subjectsInfo?.find(f => f.id == Number(value))?.name}</u> Subject as your elective paper , Kindly confirm "</h5>
                        <div className="react-confirm-alert-button-group">
                            <button onClick={() => { onClose(); }}>No</button>
                            <button id="btn-confirm-delete-cart"
                                onClick={() => {
                                    onSetElectiveSubject(_state.selectElectiveId)
                                    onClose();
                                }}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }
    return (
        <div ref={menuRef}>
            <span style={{ color: "#005ca8", cursor: "pointer" }} onClick={() => handleElectiveModal(props?.subjectId, show)}>Elective &#x203A;</span>
            {/*<button onClick={() => func()}>
                {props.open.toString()}
            </button>
             <Modal
                open={props.open}
                onClose={() => props.setIsOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Text in a modal
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </Typography>
                </Box>
            </Modal> */}
            {
                show && <div className={classes.elective_box}>
                    {
                        props?.question?.electiveSubject.map((el, i) => (
                            <label>
                                <input type="radio" name={"radio" + props.index} onClick={(e) => handleSetElective('selectElectiveId', el.id)} />
                                <span>{el.name}</span>
                            </label>
                        ))
                    }

                </div>
            }

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        question: state.Questions
    }
}
const mapDispatchToProps = { onGetElectiveSubject, onSetElectiveSubject }
export default connect(mapStateToProps, mapDispatchToProps)(BundleElectiveData);