import LoadingOverlay from 'react-loading-overlay';
import Loader from "../loader"

const MyLoader = ({ active, children }) => {
    //console.log(active)
    return (
        <LoadingOverlay
            active={active}
            spinner={<Loader />}
            text=""
            styles={{
                position: 'absolute',
                width: '100%',
                height: '100%'
            }}
        >
            {children}
        </LoadingOverlay>
    )
}

export default MyLoader;