import * as actionTypes from './actionTypes'

const initialState = {
  planDetails: [],
  customPlanDetails: [],
  bundleGroupPlanDetails: [],
  bundlePlanDetails: [],
  testCenterDetails: [],
  testPreferenceDetails: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PLAN_SERVICE:
      state = {
        ...state,
        [action.payload.name]: action.payload.data,
      }
      break;
    case actionTypes.UPDATE_CUSTOM_PLAN:
      state = {
        ...state,
        [action.payload.name]: action.payload.data,
      }
      break;
    case actionTypes.UPDATE_BUNDLE_GROUP_PLAN:
      state = {
        ...state,
        [action.payload.name]: action.payload.data,
      }
      break;
    case actionTypes.UPDATE_BUNDLE_PLAN_SERVICE:
      state = {
        ...state,
        [action.payload.name]: action.payload.data,
      }
      break;
    case actionTypes.UPDATE_TEST_CENTER_DETAILS:
      state = {
        ...state,
        [action.payload.name]: action.payload.data,
      }
      break;
    case actionTypes.UPDATE_TEST_PREFERENCE:
      state = {
        ...state,
        [action.payload.name]: action.payload.data,
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
}

export default reducer;
