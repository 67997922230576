import React from "react";
import classes from "./styles.module.scss";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const WaitingButton = (props) => {
    return (
        <div className={classes.waiting_button} style={{ cursor: "pointer !important" }} title={props.iconColor ? "Click Here !" : ""}>
            <button onClick={props.onClick}> <FiberManualRecordIcon style={{ color: props.iconColor ? props.iconColor : null }} />&nbsp;<span>{props.text}</span></button>
        </div>
    )
}

export default WaitingButton;