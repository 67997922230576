import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  counselingType: "",
  requestedCounselorId: "",
  subjectId: "",
  orderId: null,
  syllabusId: null,
  onDate: "",
  languageId: 1,
  counselingId: null,
  subType: "",
  groupId: null,
  subjectId: null,
  portionId: null,
  subjectData: {},
  groupId: ""
}

const counselorSlice = createSlice({
  name: 'counselor',
  initialState: initialState,
  reducers: {
    updateCounselorData: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearCounselorData: () => {
      return initialState;
    },
  },
});

export const { updateCounselorData, clearCounselorData } = counselorSlice.actions;
export default counselorSlice.reducer;

