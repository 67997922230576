import React from "react";
import classes from "./styles.module.scss";
import PageHeading from "../../../components/pageHeading";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BuyCounsellingContainer from "../../../container/buyCounsellingContainer";
import { useBuyCounselingMutation, useGetCounsellingTypePricesQuery, useGetOrdersQuery, useSaveCounselingBuyStatusMutation } from "../counsellingApiSlice";
import useApiTopLoading from "../../../hooks/useApiTopLoading";
import { useDispatch } from "react-redux";
import useApiLoadingOverlay from "../../../hooks/useApiLoadingOverlay";


const BuyCounsellingPage = () => {
    const dispatch = useDispatch()
    const { data: counsellingTypePricesData, isFetching: isGetCounsellingTypePricesFetching } = useGetCounsellingTypePricesQuery()
    const { data: orderData, isFetching: isGetOrdersFetching } = useGetOrdersQuery()
    const [buyCounseling, { isLoading: isLoadingBuyCounseling }] = useBuyCounselingMutation()
    const [saveCounselingBuyStatus, { isLoading: isLoadingSaveCounselingBuyStatus }] = useSaveCounselingBuyStatusMutation()

    useApiTopLoading([isGetCounsellingTypePricesFetching, isGetOrdersFetching], dispatch)
    useApiLoadingOverlay([isLoadingBuyCounseling, isLoadingSaveCounselingBuyStatus], dispatch)

    return (
        <div className={classes.my_order}>
            <Button variant="text" sx={{ m: 2, fontSize: 18, fontWeight: 'bold', color: '#000000', padding: 0, textTransform: 'none', marginBottom: 2, '& svg': { color: '#5c5c5c' } }} >
                Buy Counseling
            </Button>

            <BuyCounsellingContainer
                counsellingTypePricesData={counsellingTypePricesData?.find((d) => d.name === "POST_ORDER")}
                orderData={orderData}
                buyCounseling={buyCounseling}
                saveCounselingBuyStatus={saveCounselingBuyStatus}
            />

        </div>
    )
}


export default BuyCounsellingPage;