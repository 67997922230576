import React, { useState, useEffect } from "react";
import classes from "./styles.module.scss";

const Heading = (props) => {
    return (
        <div className={!props?.width ? classes.heading_part : classes.heading_half_part}>
            <h6 className={props?.width ? classes.h6_half : classes.h6}><i className="fa fa-square"></i>{props.text}</h6>
            {
                props.description ?
                    <div className={classes.headDescriptionBody}>

                        <div className={classes.headDescription}>{props.description}</div>
                    </div>
                    : ""
            }

        </div>
    );
};
export default Heading;
