import axios from "../../shared/axios";
import { getDataFromStore } from "../../store/getStore"
const errorMessage = "Something went wrong. Please try again";

export const getToken = () => {
    let auth = getDataFromStore("Auth");
    //console.log(auth);
    return auth?.authDetails?.token?.access_token
};
/*export const getToken = (section) => {
    // console.log(section, "amrit")

    if (section === "DEPARTMENT") {
        let auth = getDataFromDepartmentStore("Login");
        // console.log(auth.authDetails.token)
        return auth.authDetails.token
    }

    else return false

};*/

export const apiFunction = async (url, method, postData, section, token, extraConfig) => {
    // console.log("sadsadas")
    let config = {
        method: method,
        url: url,
        data: postData ? postData : {},
        headers: { institutionId: process.env.REACT_APP_INSTITUTION_ID }
    };
    let data;
    //console.log(token);
    if (token) {
        let token = getToken(section);
        //console.log(token);
        config = {
            ...config,
            headers: { Authorization: `Bearer ${token}`, institutionId: process.env.REACT_APP_INSTITUTION_ID }
        };
    }

    if (extraConfig == "blob") {
        config = {
            ...config,
            responseType: 'blob',
        }
    }

    if (extraConfig == "file") {
        config = {
            ...config,
            headers: { ...config.headers, 'content-type': 'multipart/form-data' },
        }
    }

    await axios({ ...config })
        .then((res) => {
            //console.log(res, extraConfig);
            if (extraConfig == "blob") {
                data = res.data
            }
            else {
                if (res.data) {
                    data = {
                        data: res.data,
                        status: true,
                        message: "Success",
                    };
                } else {
                    data = {
                        status: true,
                        message: "Success",
                    };
                }
            }
        })
        .catch((err) => {
            // console.log(err.response.data.message);
            //console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    // console.log(data);
    return data;
};

export const postFormDataAPI = async (url, postData, section) => {
    let token = getToken(section);
    let data;
    const config = {
        headers: {
            "content-type": "multipart/form-data",
            token: `${token}`,
        },
    };
    await axios
        .post(url, postData, config)
        .then((res) => {
            data = res.data;
        })

        .catch((err) => {
            // console.log(err.response.data.message);
            // console.log(err);
            data = {
                ...err.response.data,
                status: false,
            };
        });
    return data;
};
