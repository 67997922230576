import React, { useState, useEffect } from "react";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CartComponent from '../../components/customizePlanComponents/cartComponent'
import { height } from "@mui/system";
import classes from "./styles.module.scss";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#eee',
    boxShadow: 24,
    color: "black !important",
    padding: 4,
    height: "100%",
    overflow: "auto",
    padding: "10px",
    color: "black !important"
};
export default function GlobalModal(props) {
    //console.log(props)
    return (

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            onClose={() => props.setIsOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >

            <Fade in={props.open}>
                <Box className={props.small ? classes.boxStyleSmall : classes.boxStyle}>
                    {/* <Typography id="transition-modal-title" variant="h6" component="h2">
                        {props.head}
                        <i className="fa fa-shopping-cart"></i>
                    </Typography> */}
                    <div style={{ padding: "10px" }}>
                        {!props.small ? <i className="fa fa-times" style={{ float: "right" }} onClick={() => props.setIsOpen(false)}></i> : null}
                        <>
                            {props.children}
                        </>
                    </div>
                    {/* <Typography style={{ color: "black !important" }} id="transition-modal-description" sx={{ mt: 2 }}>
                       {props.children}
                    </Typography> */}
                </Box>
            </Fade>
        </Modal>

    );
}
