import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import InvoiceComponentTable from "../../invoiceComponent/invoiceCustomizeComponent/invoiceComponentsTable";
import BillHeader from "../../invoiceComponent/billHeader";
import classes from "./styles.module.scss";
import { Container, Grid } from '@mui/material/';
import logo from "../../../assets/customAssets/images/logo.png";
import { generateCustomizeInvoice } from "../../../shared/invoice";
import { priceInWords } from '../../../shared/priceToWord';
import CustomerTotal from '../../../container/InvoiceContainer/customerTotal';

const stateId = 21;

const InvoiceCustomizeComponent = (props) => {
    const companyData = props.invoiceData.companyDetails;
    const billerData = props.invoiceData.billerDetails;
    const invoiceData = props.invoiceData.invoiceDetails;
    const planData = props.invoiceData.planDetails;


    const [planName, setPlanName] = useState(null)
    const [rows, setRows] = useState(null)
    const [isIgst, setIgst] = useState(false)
    const [allSyllabus, setAllSyllabus] = useState([])
    const [particularData, setParticularData] = useState([])


    useEffect(() => {
        props.data && setParticularData(props.data.particulars)
    }, [props.data])

    useEffect(() => {
        if (props.invoice.orderData?.all_portions) {
            //console.log(props.invoice.orderData.order.OrderedCustomPlans);
            if (props.invoice.orderData.order?.OrderedCustomPlans?.length > 0) {
                let data = generateCustomizeInvoice(props.invoice.orderData.all_portions, props.invoice.orderData.order.OrderedCustomPlans, props.invoice.orderData.all_service)
                //console.log(data);
                setAllSyllabus({
                    syllabus: data.syllabus,
                    quantity: data.quantity
                })
                setRows(data.allData)
                setPlanName("custom")
            }
        }

    }, [props.invoice.orderData?.all_portions])
    //console.log(props)
    //console.log(props?.invoice?.orderData?.order?.OrderedCustomPlans);
    const getPlanPrice = () => {
        let priceObj = {};
        let gstPriceObj = {};
        let disPriceObj = {};
        let discountObj = {};
        let total = []
        // console.log(props?.invoice?.orderData?.order?.OrderedCustomPlans);
        props?.invoice?.orderData?.order?.OrderedCustomPlans.map((k, i) => {
            let _price = 0
            let _gstPrice = 0
            let _disPrice = 0
            let _discount = 0
            //let syllabusFilter=

            // OrderedCustomPlanRecords
            // k.OrderedCustomPlanRecords
            // k.OrderedCustomPlanRecords?.find(obj => obj.Group.Syllabus.id === c.id)

            // console.log(props?.invoice?.orderData?.order);

            const checkSubjectWithSameSyllabus = (allData, syllabus) => {
                let _data = allData.filter((f) => f?.Group?.Syllabus?.id == syllabus)
                if (_data.length > 0) {
                    return _data.length
                }
                return 1;
            }

            k.OrderedCustomPlanPriceServiceWise.map((p, ip) => {
                let paperPerSyllabusLength = checkSubjectWithSameSyllabus(k.OrderedCustomPlanRecords, p.syllabusId)
                _discount = p.discount * paperPerSyllabusLength
                _price = p.price * paperPerSyllabusLength
                _gstPrice = p.amountAfterGST * paperPerSyllabusLength
                _disPrice = p.amountAfterDiscount * paperPerSyllabusLength
                let priceId = 'price' + p.productAndServiceId
                if (!priceObj[priceId])
                    priceObj[priceId] = 0
                if (!gstPriceObj[priceId])
                    gstPriceObj[priceId] = 0
                if (!disPriceObj[priceId])
                    disPriceObj[priceId] = 0
                if (!discountObj[priceId])
                    discountObj[priceId] = 0
                discountObj[priceId] = discountObj[priceId] + _discount
                priceObj[priceId] = priceObj[priceId] + _price
                gstPriceObj[priceId] = gstPriceObj[priceId] + _gstPrice
                disPriceObj[priceId] = disPriceObj[priceId] + _disPrice
            })

        })
        total.push(priceObj)
        total.push(gstPriceObj)
        total.push(disPriceObj)
        total.push(discountObj)
        return total
    }

    // console.log(getPlanPrice());

    let taxPriceDetails = getPlanPrice()

    const getTotal = () => {
        let _totalAmount = 0
        props.invoice.orderData.all_service && props.invoice.orderData.all_service?.map((r, i) => {
            let pId = 'price' + r.id
            if (taxPriceDetails[2][pId]) {
                _totalAmount += taxPriceDetails[2][pId] + taxPriceDetails[2][pId] * (r.GST.cGst / 100)
            }
        })
        return _totalAmount
    }

    const getTaxRows = () => {
        let rows = []
        props.invoice.orderData.all_service && props.invoice.orderData.all_service?.map((r, i) => {
            let pId = 'price' + r.id
            //console.log(taxPriceDetails[0][pId], r.GST.sGst);
            // console.log(taxPriceDetails);
            rows.push({
                particular: <><b>{r.name}</b><br />HSN Code : {r.GST.name}; GST: {props?.invoice?.orderData?.order?.District?.stateId == stateId ? r.GST.sGst + r.GST.cGst : r.GST.iGst} %</>,
                discountPrice: taxPriceDetails[3][pId],
                beforeDis: taxPriceDetails[0][pId],
                taxAmount: taxPriceDetails[2][pId],
                stateId: props?.invoice?.orderData?.order?.District?.stateId,
                cGst: (taxPriceDetails[0][pId] * (r.GST.cGst / 100)),
                sGst: (taxPriceDetails[0][pId] * (r.GST.sGst / 100)),
                iGst: taxPriceDetails[2][pId] * (r.GST.iGst / 100),
                // cGst: (taxPriceDetails[0][pId] * (r.GST.cGst / 100)) / 2,
                // sGst: (taxPriceDetails[0][pId] * (r.GST.cGst / 100)) / 2,
                // iGst: taxPriceDetails[0][pId] * (r.GST.cGst / 100),
                total: taxPriceDetails[2][pId] + (props?.invoice?.orderData?.order?.District?.stateId == stateId ? taxPriceDetails[2][pId] * ((r.GST.cGst + r.GST.sGst) / 100) : taxPriceDetails[2][pId] * (r.GST.iGst / 100)),
                roundOff: ""
            })
        })
        return rows;
    }
    const taxRows = getTaxRows()
    const totalAmount = getTotal()


    const getTotalCustomize = () => {
        let totalBeforeTax = 0;
        let totalTax = 0, totalAfterTax = 0;
        taxRows.map((r) => {
            // console.log(r.taxAmount);
            totalBeforeTax += (r.taxAmount ? r.taxAmount : 0);
            totalAfterTax += (r.total ? r.total : 0);
            if (props?.invoice?.orderData?.order?.District?.stateId == stateId) {
                totalTax += (r.cGst ? r.cGst : 0) + (r.sGst ? r.sGst : 0)
            }
            else {
                totalTax += (r.iGst ? r.iGst : 0);
            }
        })

        return {
            totalBeforeTax,
            totalTax,
            totalAfterTax,
        }
    }



    const ParticularComponent = ({ r, stateId, props }) => (
        <>
            <b>{r.particular}</b><br />
            HSN Code: {r.hsn_code}; GST: {r.gst_rate}%
        </>
    );

    return (
        <>

            <InvoiceComponentTable
                rows={rows}
                planData={planData}
            />

            <CustomerTotal
                allSyllabus={allSyllabus}
                amount={getTotalCustomize()}
                stateId={props?.invoice?.orderData?.order?.District?.stateId} />
            {/* <hr /> */}
            {/* <h3 className={classes.w100}>Taxes</h3>
            <table className={classes.customize_bill_table}>

                {

                    particularData && particularData.length > 0 && particularData?.map((r, i) => (
                        !r.beforeDis ?
                            <React.Fragment key={r.particular}>
                                {
                                    i == 0 ?
                                        <tr>
                                            <th>Particulars</th>
                                            <th>Price.</th>

                                            {r.discount ? <th>Discount</th> : null}
                                            <th>Taxable Amt.</th>
                                            {
                                                props?.invoice?.orderData?.order?.District?.stateId == stateId ?
                                                    <>
                                                        <th>CGST</th>
                                                        <th>SGST</th>
                                                    </> :
                                                    <>
                                                        <th>IGST</th>
                                                    </>
                                            }


                                            <th>Total</th>

                                        </tr> : ""
                                }

                                
                                    {r.price != 0 &&
                                    <tr>
                                        <td> <ParticularComponent r={r} stateId={stateId} props={props} /></td>
                                        <td>₹ {r.price.toFixed(2)}</td>
                                        {r.discount ? <td>₹ {r.discount.toFixed(2)}</td> : null}
                                        <td>₹ {r.taxableAmount.toFixed(2)}</td>
                                        {
                                            props?.invoice?.orderData?.order?.District?.stateId == stateId ?
                                                <>
                                                    <td>₹ {r.cgst ? r.cgst?.toFixed(2) : 0}</td>
                                                    <td>₹ {r.sgst ? r.sgst?.toFixed(2) : 0}</td>
                                                </>
                                                :
                                                <>
                                                    <td>₹ {(Number(r.cgst) * 2).toFixed(2)}</td>
                                                </>
                                        }

                                        <td>₹ {r.total.toFixed(2)}</td>
                                    </tr>}
                            </React.Fragment> : ""
                    ))
                }


            </table> */}
            {/* <p className={classes.term_condition}><b>Total:</b><br /> <b>In Amount: <span className={classes.payment_status}>₹ {totalAmount}</span><br />In Words: <span className={classes.payment_status}>{priceInWords(totalAmount)}</span></b></p>
            <p className={classes.term_condition}>
                    <span><b>Payment Status: <span className={classes.payment_status}>Done</span></b></span>
                    <h3>Terms & Conditions</h3>
                    <ul>
                        <li>Fees once paid will not be refundable/carried forward under any circumstances other than {process.env.REACT_APP_WEBSITE_NAME} Assured policy.</li>
                        <li>You can visit {process.env.REACT_APP_MONEY_BACK_URL} for {process.env.REACT_APP_WEBSITE_NAME} Assured Policy.</li>
                    </ul>
                </p> */}

        </>
    );
};


const mapStateToProps = (state) => {
    return {
        invoice: state.Invoice,
    }
}

export default connect(mapStateToProps)(InvoiceCustomizeComponent);
