import { apiFunction } from "../../apiCalls/functions/department"
import { ORDER_BY_ID, INVOICE_PREVIEW } from "../../apiCalls/urls/order";
import { actionNotifier } from "../../components/ui/toast";
import { apiLoading, apiLoadingEnd } from "../../store/notification/action";
import * as actionTypes from './actionTypes'



export const fetchInvoicePreview = async () => {
    return await apiFunction(INVOICE_PREVIEW, 'get', {}, false, true)
}
export const fetchOrder = async (id) => {
    return await apiFunction(ORDER_BY_ID + id, 'get', {}, false, true)
}
export const fetchOrderMail = async (key) => {
    return await apiFunction(ORDER_BY_ID + "key/" + key, 'get', {}, false, true)
}
//AllAPIS
export function onLoad(id) {
    return async (dispatch) => {
        dispatch(onFetchOrderDetails(id))
    }
}
//API ACTIONS

export function onFetchInvoicePreview() {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchInvoicePreview();
        if (result.status) {
            dispatch(updateOrder(result.data))
        }
        else {
            //actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchOrderDetails(id) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchOrder(id);
        if (result.status) {
            dispatch(updateOrder(result.data))
        }
        else {
            //actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
export function onFetchOrderDetailsMail(key) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await fetchOrderMail(key);
        if (result.status) {
            dispatch(updateOrder(result.data))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}
//ACTIONS
export const updateOrder = (data, name) => {
    return {
        type: actionTypes.UPDATE_ORDERS,
        payload: { data, name }
    };
}
