export const calculateCustomizeDatas = (data) => {

    const EBOOK_TAX = 0.025;
    const SERVICE_TAX = 0.09;
    const AFTER_DECIMAL_DIGIT = 2;

    const totals = Object.values(data).flatMap(items =>
        items.map(item => {
            const { rate, hsn, discount } = item.hsnRate;
            const [ebookPrice, servicePrice, counsellingPrice] = Array.isArray(rate) ? rate : [0, 0, 0];
            const [hsnEbook, hsnService, hsncounselling] = Array.isArray(hsn) ? hsn : [null, null, null];
            const [discountEbook, discountService, discountcounselling] = Array.isArray(discount) ? discount : [0, 0, 0];
            return { ebookPrice, servicePrice, counsellingPrice, hsnEbook, hsnService, hsncounselling, discountEbook, discountService, discountcounselling };
        })
    );

    const sum = (arr, key) => arr.reduce((acc, obj) => {
        const value = obj[key];
        const numericValue = isNaN(value) ? 0 : parseFloat(value);
        return acc + numericValue;
    }, 0);

    const ebookPrice = sum(totals, 'ebookPrice');
    const servicePrice = sum(totals, 'servicePrice');
    const counsellingPrice = sum(totals, 'counsellingPrice');
    const discountEbook = sum(totals, 'discountEbook');
    const discountService = sum(totals, 'discountService');
    const discountCounselling = sum(totals, 'discountcounselling');

    const calculateTax = (price, taxRate, discount) => {
        const discountedPrice = Number(price) - (Number(discount));
        const taxableAmount = discountedPrice;
        const cgst = taxableAmount * (taxRate);
        const sgst = taxableAmount * (taxRate);
        const igst = taxableAmount * (taxRate * 2);
        const total = taxableAmount + cgst + sgst;
        return { taxableAmount, cgst, sgst, total, price, igst };
    };
    const EbookTax = calculateTax(ebookPrice, EBOOK_TAX, discountEbook);
    const ServiceTax = calculateTax(servicePrice, SERVICE_TAX, discountService);
    const counsellingTax = calculateTax(counsellingPrice, SERVICE_TAX, discountCounselling);



    const CustomizedArray = [
        { particular: 'Ebook', hsn_code: totals[0].hsnEbook, gst_rate: 5, ...EbookTax, discount: discountEbook },
        { particular: 'Prepca Services', hsn_code: totals[0].hsnService, gst_rate: 18, ...ServiceTax, discount: discountService },
        { particular: 'Video Counseling', hsn_code: totals[0].hsncounselling, gst_rate: 18, ...counsellingTax, discount: discountCounselling }
    ];

    const totalCustomizedCGST = CustomizedArray.reduce((acc, tax) => acc + tax.cgst, 0);
    const totalCustomizedSGST = CustomizedArray.reduce((acc, tax) => acc + tax.sgst, 0);
    const totalCustomizedIGST = CustomizedArray.reduce((acc, tax) => acc + tax.igst, 0);
    const taxableCustomizedTotal = CustomizedArray.reduce((acc, tax) => acc + tax.taxableAmount, 0);
    const priceCustomizedTotal = CustomizedArray.reduce((acc, tax) => acc + tax.price, 0);
    const grandCustomizedTotal = CustomizedArray.reduce((acc, tax) => acc + tax.total, 0);


    return {
        totalCustomizedIGST,
        totalRatesArray: totals.map(item => item.ebookPrice + item.servicePrice + item.counsellingPrice),
        totalCustomizedTax: priceCustomizedTotal,
        taxableCustomizedTotal,
        finalCustomizedTotal: grandCustomizedTotal,
        particulars: CustomizedArray
    };

}
