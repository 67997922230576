import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isRightProfileOpen: false,
  isLeftBarExtended: false
}

const commonSlice = createSlice({
  name: 'common',
  tagTypes: ['common'],
  initialState: initialState,
  reducers: {
    setIsRightProfileOpen: (state, action) => {
      state.isRightProfileOpen = action.payload;
      if(action.payload){
        state.isLeftBarExtended = false
      } 
    },
    setIsLeftBarExtended: (state, action) => {
      state.isLeftBarExtended = action.payload;
      if(action.payload){
        state.isRightProfileOpen = false
      } 
    },
    setCloseOtherExtended: (state, action) => {

      if (state.isRightProfileOpen) {
        state.isLeftBarExtended = false
      }

      if (state.isLeftBarExtended) {
        state.isRightProfileOpen = false
      }

    },


  },
});

export const { setIsRightProfileOpen, setIsLeftBarExtended, setCloseOtherExtended } = commonSlice.actions;
export default commonSlice.reducer;
