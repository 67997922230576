import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../Table'
import PlanButton from '../../../components/planButton'
import btnClasses from "../../planButton/styles.module.scss";
import classes from "./styles.module.scss";
import InputField from "../../inputField";
import InputSelect from "../../inputSelect";
import { Grid } from '@mui/material/';
import Heading from "../../heading";
import SelectCustom from "../../selectCustom";
import { onApplyCoupon, onUpdateCenterPreference, onFetchCartData, onGetCoupon, onEmptyCart, onConfirmEditOrder, onPaymentEditOrder } from "../../../store/cart/actions"
import { onOrderCheckout } from "../../../pages/checkout/actions"
import { onFetchOrderDetails, onFetchInvoicePreview } from "../../../pages/invoice/actions";
import { onGetProfile } from "../../../pages/profile/action";
import useRazorpay from "react-razorpay";
import { connect } from "react-redux";
import { actionNotifier } from "../../ui/toast";
import { getDataFromStore } from "../../../store/getStore"
import CustomCheckbox from "../customCheckbox";
//import view coupons modal
import ViewCouponModal from "./viewCouponModal";
import InvoiceModal from "./inoiceModal";
import GlobalModal from "../../globalModal";
import Terms from "./terms";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const CartComponent = (props) => {
    const params = useParams()
    // console.log(props);
    const div = useRef();

    useLayoutEffect(() => {
        if (props.from != 'checkout') {
            //console.log(div);
            const divAnimate = div.current.getBoundingClientRect().top +  15;
            const onScroll = () => {
                if (divAnimate <=  window.scrollY) {
                    //console.log("ok");
                    div.current.style.position = "fixed";
                    div.current.style.top = 0;
                    // div.current.style.width = "19vw";
                    div.current.style.height = "100vh";
                    //div.current.style.left = 0;

                } else {
                    div.current.style.position = "fixed";
                    div.current.style.height = "85vh";
                    // div.current.style.width = "19vw";
                    div.current.style.top = "auto";
                    div.current.style.overflowY = 'scroll'
                }
            };
            window.addEventListener("scroll", onScroll);
            return () => window.removeEventListener("scroll", onScroll);
        }
    }, [props.from, window.scrollY]);




    const Razorpay = useRazorpay();

    //view coupons
    const [showModal, setShowModal] = useState(false);
    const [globalModal, setGlobalModal] = useState(false);
    const [billModal, setBillModal] = useState(false);
    const [gstState, setGstState] = useState({
        gstNumber: null
    })
    const [gstSection, setGstSection] = useState(false);
    const [showCenterOption, setShowCenterOption] = useState(false);
    const [centerOption, setCenterOption] = useState(null);
    const navigate = useNavigate();
    //console.log(window.location.pathname)
    //console.log(window.location.href.substring(0, window.location.pathname.length))

    const [orderDetails, setOrderDetails] = useState({});
    const [couponDetails, setCouponDetails] = useState({})

    // useEffect(() => {
    //     if (params.orderId) {
    //         if (props?.cartData?.cartDetails?.length == 0) {
    //             navigate("/logout")
    //         }
    //     }
    // }, [props?.cartData?.cartDetails])
    // console.log(props?.cartData?.cartDetails?.length);
    const [profileState, setProfileState] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        courseId: "",
        syllabusId: "",
        stateId: "",
        cityId: "",
        districtId: "",
        mobileNo: ""
    })
    useEffect(() => {
        props.onGetProfile()
    }, [])
    useEffect(() => {
        //console.log(props.profile.allProfiles)
        setProfileState({
            firstName: props?.profile?.allProfiles?.firstName,
            middleName: props?.profile?.allProfiles?.middleName,
            lastName: props?.profile?.allProfiles?.lastName,
            email: props?.profile?.allProfiles?.email,
            courseId: props?.profile?.allProfiles?.courseId,
            syllabusId: props?.profile?.allProfiles?.syllabusId,
            stateId: props?.profile?.allProfiles?.District?.stateId,
            districtId: props?.profile?.allProfiles?.districtId,
            cityId: props?.profile?.allProfiles?.cityId,
            mobileNo: props?.profile?.allProfiles?.mobileNo
        })

    }, [props?.profile?.allProfiles]);

    let length = Object.keys(profileState).length
    let profileCount = 0;

    Object.entries(profileState).map(([key, value]) => {
        if (key != 'middleName' && key != 'lastName') {
            if (!value) {
                profileCount++
            }
        }

    })
    //console.log(profileCount, profileState, Math.round(100 - profileCount / length * 100))

    useEffect(() => {
        props.onFetchInvoicePreview()
    }, [])

    useEffect(() => {
        if (props?.cartDetails?.Coupon && Object.keys(props?.cartDetails?.Coupon).length > 0) {
            //console.log(props.orderDetails);
            setCouponDetails(props?.cartDetails?.Coupon)
        }
    }, [props?.cartDetails?.Coupon])
    //console.log(couponDetails)
    // useEffect(() => {

    //     if (props.orderDetails && Object.keys(props.orderDetails).length > 0) {
    //         //console.log(props.orderDetails);
    //         setOrderDetails(props.orderDetails)
    //     }
    // }, [props.orderDetails])

    // console.log(props)
    const [couponState, setCouponState] = useState({
        code: ""
    })
    const handleCouponChange = (name, value) => {
        let _couponState = { ...couponState };
        _couponState[name] = value;
        setCouponState(_couponState)
        setShowModal(false)
        //actionNotifier.info("Coupon Selected");
    }
    const handleApplyCoupon = () => {
        if (couponState.code == "") {
            actionNotifier.error("Enter Coupon Code");
            return;
        }
        props.onApplyCoupon(couponState)
    }
    
    const onCartEmpty = () => {
        props.onEmptyCart()
        props.onFetchCartData()

    }
    const cityOption = () => {
        let cityOption = []
        props?.testCenterDetails.map((k, i) => {
            if (!cityOption.find((f) => f.id == k.cityId))
                cityOption.push({ id: k.cityId, name: k.city.name })
        })
        return cityOption
    }
    const cityCenterOption = (cityId) => {
        //console.log(cityId, props?.testCenterDetails?.filter((f) => f.cityId == cityId));
        let _cityCenterOption = []
        props?.testCenterDetails?.filter((f) => f.cityId == cityId)?.map((k, i) => {
            if (!_cityCenterOption.find((f) => f.id == k.id))
                _cityCenterOption.push({ id: k.id, name: k.name })
        })
        return _cityCenterOption
    }



    //const total = props?.cartProduct?.CartCustomPlanRecords.reduce((accumulator,current) => accumulator + current.price)
    //console.log(total)

    // console.log(props);
    const findTotal = () => {

        let customTotal = 0
        let bundleTotal = 0
        let customTotalPrice = 0
        let bundleTotalPrice = 0

        props?.customCartData?.forEach((k, i) => {
            let sessions = [];
            // console.log(k.CartCustomPlanRecords);
            k.CartCustomPlanRecords.forEach((p, ip) => {
                let sessionIndex = sessions.findIndex((s) => s.sessionId === p.Group.Session.id);
                if (sessionIndex === -1) {
                    sessions.push({ sessionId: p.Group.Session.id, syllabus: [] });
                    sessionIndex = sessions.length - 1;
                }

                let syllabusIndex = sessions[sessionIndex].syllabus.findIndex((a) => a.syllabusId === p.Group.syllabusId);
                if (syllabusIndex === -1) {
                    sessions[sessionIndex].syllabus.push({ syllabusId: p.Group.syllabusId });
                }
            });

            let _price = 0;
            let _totalPrice = 0;
            let filteredRecords;
            // console.log(sessions);
            sessions.forEach((s) => {
                s.syllabus.forEach((st) => {
                    let filterPrice = k.Plan.ProductAndServicePrices.filter((f) => f.syllabusId == st.syllabusId && f.sessionId == s.sessionId);
                    let syllabusPrice = 0;
                    let totalSyllabusPrice = 0;

                    filterPrice.forEach((p, ip) => {
                        syllabusPrice = syllabusPrice + p.price;
                        totalSyllabusPrice = totalSyllabusPrice + (p.discountType == "flat" ? p.price - p.discount : p.price - p.price * (p.discount / 100));
                    });

                    filteredRecords = k.CartCustomPlanRecords.filter(p => p.Group.Session.id === s.sessionId && p.Group.Syllabus.id === st.syllabusId);

                    _price = _price + syllabusPrice * filteredRecords.length;
                    _totalPrice = _totalPrice + totalSyllabusPrice * filteredRecords.length;
                    // console.log();
                });

            });

            customTotal = customTotal + Math.round(_price);
            customTotalPrice = customTotalPrice + Math.round(_totalPrice);
            // console.log(customTotal, customTotalPrice);
        });

        props?.bundleCartProduct?.map((k, i) => {
            bundleTotal = bundleTotal + k.BundlePlanPrice.price
            bundleTotalPrice = bundleTotalPrice + (k.BundlePlanPrice.discountType == "flat" ? k.BundlePlanPrice.price - k.BundlePlanPrice.discount : k.BundlePlanPrice.price - k.BundlePlanPrice.price * (k.BundlePlanPrice.discount / 100))
        })


        let _total = {
            'customTotal': customTotal, 'bundleTotal': bundleTotal, 'customTotalPrice': customTotalPrice, 'bundleTotalPrice': bundleTotalPrice
        }

        // console.log(_total);
        return _total
    }

    //console.log(findTotal())
    //let auth = getDataFromStore("Auth");
    const [confirmState, setConfirmState] = useState({
        centreInCityId: null,
        testPreferenceId: 1,
        referralId: 99999,
        referredFromOther: null,
        contributionAmount: null,
        cityId: "",
        testPreferenceIdCenter: null
    })
    useEffect(() => {
        setConfirmState({
            centreInCityId: props?.cartDetails?.testPreferenceId == 2 ? props?.cartDetails?.CentreInCity?.id : null,
            testPreferenceId: props?.cartDetails?.testPreferenceId ? props?.cartDetails?.testPreferenceId : 1,
            referralId: couponState.code ? confirmState.referralId : props?.cartDetails?.referralId,
            testPreferenceIdCenter: props?.cartDetails?.testPreferenceId ? props?.cartDetails?.testPreferenceId : null,
            cityId: props?.cartDetails?.centreInCityId ? props?.cartDetails?.CentreInCity?.city?.id : null
        })
        if (props?.cartDetails?.testPreferenceId == 2)
            setShowCenterOption(true)
        if (props?.cartDetails?.centreInCityId)
            setCenterOption(cityCenterOption(props?.cartDetails?.CentreInCity?.city?.id))
    }, [props.cartDetails]);
    //console.log(centerOption);
    // console.log(props.cartDetails)
    const [otherReferredCheck, setOtherReferredCheck] = useState(false);
    const [termCondition, setTermCondition] = useState(false);


    const handleTermCondition = (e) => {
        setTermCondition(e.target.checked)
    }
    const handleGstSection = (e) => {
        setGstSection(e.target.checked)
        let _gstState = { ...gstState }
        _gstState.gstNumber = null
        setGstState(_gstState)
    }

    const handleTest = (testVal, centerCityVal) => {
        // console.log(testVal, centerCityVal);
        let _confirmState = { ...confirmState }
        _confirmState.testPreferenceId = testVal
        _confirmState.centreInCityId = centerCityVal ? Number(centerCityVal) : 0
        setConfirmState(_confirmState)
        // console.log(_confirmState)
    }
    // console.log(confirmState);
    const handleChange = (name, value) => {
        let _confirmState = { ...confirmState };
        if (name == 'cityId') {
            _confirmState.cityId = value
            _confirmState.centreInCityId = null;
            setCenterOption(cityCenterOption(value))
        }

        if (name == 'cityId') {
            //setConfirmTestSelect(false)
            _confirmState['testPreferenceId'] = null;
            //_confirmState[name] = Number(value);
        }
        if (name == 'testPreferenceIdCenter') {
            _confirmState['testPreferenceId'] = null;
            _confirmState[name] = Number(value);
            setShowCenterOption(true);
        }
        if (name == 'testPreferenceId') {
            _confirmState['cityId'] = null;
            _confirmState['centreInCityId'] = null;
            _confirmState['testPreferenceIdCenter'] = null;
            setShowCenterOption(false);
        }
        if (name == "referralId") {
            // console.log(value)
            if (value == '99999')
                setOtherReferredCheck(true)
            else if (value === "") {
                _confirmState['referralId'] = null;
                setOtherReferredCheck(false)
            } else {
                setOtherReferredCheck(false)
            }
        }
        if (name == 'referredFromOther') {
            _confirmState[name] = value;
        } else {
            _confirmState[name] = Number(value);
        }
        // console.log(_confirmState);
        setConfirmState(_confirmState)
    }

    const handleNext = () => {
        if (props?.customCartData?.length === 0 && props?.bundleCartProduct?.length === 0) {
            actionNotifier.error("Select Plan First");
            return;
        }
        props.setNext(true)
    }


    // const handleEditConfirm = (orderID, token) => {
    //     props.onConfirmEditOrder(orderID, token)
    // }
    // const handleEditSendPayment = (orderID, token) => {
    //     props.onPaymentEditOrder(orderID, token)
    // }


    const handleConfirm = () => {
        // console.log(confirmState, showCenterOption)
        //console.log(termCondition)        
        // if (profileCount != 0) {
        //     actionNotifier.warning("Complete your profile before order !");
        //     return;
        // }
        if (confirmState.referralId == null || confirmState.referralId == 0) {
            actionNotifier.error("Select Referred !");
            return;
        }
        if (otherReferredCheck) {
            if (confirmState.referredFromOther == "") {
                actionNotifier.error("Enter Value of Referred By!");
                return;
            }
        }
        //centreInCityId

        if (showCenterOption == true) {
            if (confirmState.cityId == null || confirmState.cityId == 0) {
                actionNotifier.error("Select city!");
                return;
            }
            if (confirmState.centreInCityId == null || confirmState.centreInCityId == 0) {
                actionNotifier.error("Select center !");
                return;
            }
        }


        if (!termCondition) {
            actionNotifier.error("Accept terms and condition !");
            return;
        }



        //console.log(confirmState)
        //console.log(auth?.authDetails?.student?.id)

        props.onUpdateCenterPreference(confirmState, props.type, navigate)

        //navigate(props.type == 'bundle' ? "/checkout/bundle" : "/checkout/custom");

    }
    // console.log(confirmState)
    const handleConfirmCheckout = (masterLogin) => {
        //console.log(props?.studentAddressId)
        // console.log(masterLogin);


        if (props?.studentAddressId == null) {
            actionNotifier.error("Select Address !");
            return;
        }
        else {
            //console.log(gstState.gstNumber);
            if (gstSection) {

                if (gstState.gstNumber == null) {
                    actionNotifier.error("Enter GST Number");
                    return;
                }
                if (gstState.gstNumber.length != 15) {
                    actionNotifier.error("GST should be at least 15 digits");
                    return;
                }

                const GST_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
                const isValidGST = GST_REGEX.test(gstState.gstNumber);

                if (!isValidGST) {
                    actionNotifier.warning("Invalid GST number");
                    return;
                }
            }

            props.onOrderCheckout(props?.studentAddressId, gstState.gstNumber, navigate, Razorpay, masterLogin)
        }

    }


    const getPriceDetails = (c, syllabusId, sessionId) => {
        //console.log(c)
        let priceDetails = {}
        let _price = 0
        let _totalPrice = 0
        let filterPrice = c.Plan.ProductAndServicePrices.filter((f) => f.syllabusId == syllabusId && f.sessionId == sessionId)
        // console.log(c.Plan.ProductAndServicePrices);
        filterPrice.map((p, ip) => {
            _price = _price + p.price
            _totalPrice = _totalPrice + (p.discountType == "flat" ? p.price - p.discount : p.price - p.price * (p.discount / 100))
        })
        priceDetails['price'] = Math.round(_price)
        priceDetails['totalPrice'] = Math.round(_totalPrice)
        //console.log(_price)
        //console.log(_totalPrice)
        return priceDetails;
    }


    const getPlanDetails = (record) => {
        let data = record.CartCustomPlanRecords
        let group = []
        data.map((p, ip) => {
            let check = group.findIndex((f) => f.group.id == p.Group.id)
            let price = getPriceDetails(record, p.Group.Syllabus.id, p.Group.Session.id).price
            let totalPrice = getPriceDetails(record, p.Group.Syllabus.id, p.Group.Session.id).totalPrice
            //console.log(price,totalPrice);
            if (check == -1) {
                let find = data.filter((f) => f.Group.id == p.Group.id)
                //console.log(find);
                let portion = []
                find.map((po, poi) => {
                    let portionCheck = portion.findIndex((cp) => cp.id == po.Portion.id)
                    let subjectData = data.filter((f) => f.Group.id == p.Group.id && f.Portion.id == po.Portion.id)
                    let subject = []
                    subjectData.map((fi, ifi) => {
                        subject.push({ name: fi.Subject.name, id: fi.Subject.id, price: price, totalPrice: totalPrice })
                    })
                    if (portionCheck == -1)
                        portion.push({ name: po.Portion.name, id: po.Portion.id, subject: subject })
                })
                group.push({ group: { name: p.Group.name, id: p.Group.id, syllabus: p.Group.Syllabus.name, syllabusId: p.Group.Syllabus.id, sessionName: p.Group.Session.name }, portion: portion })
            }

        })
        //console.log(group);
        return group
    }
    const findDeleteId = (c, groupId, portionId, subjectId) => {
        let deleteId = c.find((cp) => cp.Group.id == groupId && cp.Portion.id == portionId && cp.Subject.id == subjectId)
        return deleteId.id
    }
    const findCount = (cp) => {
        //console.log(cp);
        let obj = {}

        let subject = []
        let portion = []
        let subjectPortion = []
        cp.portion.map((a, i) => {
            if (portion.findIndex((su) => su.portionId == a.id) == -1)
                portion.push({ portionId: a.id })
            a.subject.map((s, is) => {
                subjectPortion.push({ portionId: a.id, subjectId: s.id })
                if (subject.findIndex((su) => su.subjectId == s.id) == -1)
                    subject.push({ subjectId: s.id })
            })
        })
        obj['test'] = portion.length
        obj['subject'] = subject.length
        obj['paper'] = subjectPortion.length
        return obj

    }

    const handleGstChange = (name, value) => {
        let _gstState = { ...gstState };
        _gstState[name] = value;
        setGstState(_gstState)
    }
    const handleGstSave = () => {
        if (gstState.gstNumber == "") {
            actionNotifier.error("Enter GST Number");
            return;
        }

        //console.log(gstState)
        props.onSaveGSTNumber(gstState, setGstState)
    }




    let discountTypeVar = props?.cartData?.cartDetails?.data?.Coupon?.discountType
    let findTotalVar = findTotal().customTotalPrice + findTotal().bundleTotalPrice
    let discountValue = props?.cartData?.cartDetails?.data?.Coupon?.value
    let discountAmount = (findTotal().customTotal + findTotal().bundleTotal) - (findTotal().customTotalPrice + findTotal().bundleTotalPrice)
    let totalAmount = props?.cartData?.cartDetails?.data?.Coupon ? discountTypeVar == 'flat' ? findTotalVar - discountValue : findTotalVar - ((findTotal().customTotal + findTotal().bundleTotal) * (discountValue / 100)) : findTotal().customTotalPrice + findTotal().bundleTotalPrice
    let subTotal = findTotal().customTotal + findTotal().bundleTotal
    //console.log(props.customCartData);

    const onConfirmAddToCart = (cartId, token, navigate) => {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert-body">
                        <h4>Cart message</h4>
                        <h5>" Hi you have update your cart , Kindly confirm "</h5>
                        <div className="react-confirm-alert-button-group">
                            <button onClick={() => { onClose(); }}>No</button>
                            <button id="btn-confirm-delete-cart"
                                onClick={() => {
                                    props.onConfirmEditOrder(cartId, token, navigate)
                                    onClose();
                                }}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }




    const totalBundlePapersArray = props?.bundleCartProduct?.map((c, i) => {
        const totalSubjects = (c?.BundlePlanPrice?.BundlePlan?.Groups || []).reduce((sub, test) => sub + (test.Subjects || []).length, 0);
        const totalTests = (c?.BundlePlanPrice?.BundlePlan?.Groups || []).reduce((noOfTests, test) => noOfTests + (test.noOfTests || 0), 0);

        return totalSubjects * totalTests;
    });

    const totalCustomizePapersArray = props?.customCartData?.flatMap((c) =>
        getPlanDetails(c).map((cp) => findCount(cp).paper)
    );

    const totalBundlePapersCount = totalBundlePapersArray?.reduce((acc, count) => acc + count, 0);
    const totalCustomizePapersCount = totalCustomizePapersArray?.reduce((acc, count) => acc + count, 0);

    return (
        <>
            {
                globalModal ?
                    <GlobalModal setIsOpen={setGlobalModal} open={globalModal} close={() => setGlobalModal(false)}>
                        <Terms />
                    </GlobalModal> : ""
            }


            {
                billModal ? <InvoiceModal addressDetails={props.addressDetails} centerDetails={{ preference: props?.cartData?.cartDetails?.data?.TestPreference?.name, centerName: props?.cartData?.cartDetails?.data?.CentreInCity }} selectedAddress={props.selectedAddress} profile={props.profile.allProfiles} invoice={props.invoice} open={billModal} setIsOpen={setBillModal} /> : ""
            }

            <div className={classes.cart_container} ref={div}>
                <h6 className={classes.cartHeading} >{props.next && props.from != 'checkout' ? <i onClick={() => props.setNext(false)} className="fa fa-arrow-left"></i> : ""}Your Cart  {props?.bundleCartProduct?.length + props?.customCartData?.length && props.from != 'checkout' && !props.next ? <i onClick={onCartEmpty} className="fa fa-trash" title='Empty Cart' style={{ float: "right" }}></i> : ""}</h6>
                <div className={classes.body} >

                    {
                        !props.next ? <>
                            {/*********** before next div  ************ */}
                            <div id="nextDiv">
                                <div className={`${classes.dataScroll}`} >


                                    {
                                        props?.bundleCartProduct?.map((c, i) => (
                                            <div className={`${classes.data}`} >

                                                <span className={classes.data_heading}>
                                                    {props.from != 'checkout' ?
                                                        <span>
                                                            <i onClick={() => props?.handleDelete(c.id, "bundle", props?.cartDetails?.id)} className={`${classes.cartDelete} fa fa-minus-square-o`}></i>
                                                        </span>
                                                        : <span style={{ marginTop: "-2px" }}>&#9632;</span>}
                                                    <span>
                                                        {c.BundlePlanPrice.BundlePlan.Groups[0].Syllabus?.Course?.name} {c.BundlePlanPrice.BundlePlan.Groups[0].Syllabus?.name} {props?.bundleCartProduct?.length != 0 ? <span className={classes.plan_type}>( Bundle Plans )</span> : ""}
                                                    </span>
                                                    <span></span>
                                                </span>
                                                {/* <span className={classes.data_heading}>
                                                    <span style={{ color: "#93C01C" }}>{c.BundlePlanPrice.BundlePlan.Groups[0].Syllabus?.Course?.name} {c.BundlePlanPrice.BundlePlan.Groups[0].Syllabus?.name}</span>

                                                    {props.from != 'checkout' ?
                                                        <i onClick={() => props?.handleDelete(c.id, "bundle")} className={`${classes.cartDelete} fa fa-minus-square-o`}></i>
                                                        : ""}
                                                </span> */}
                                                <div className={classes.data_body}>
                                                    <span>
                                                        <span style={{ fontSize: "12px", fontWeight: "700" }}>{c.Session.name}</span><br />
                                                        <span style={{ fontSize: "10px", fontWeight: "700" }}>{c.BundlePlanPrice.BundlePlan.name}</span><br />
                                                        <b>{c.BundlePlanPrice.Plan.name}</b><br />
                                                        <span className={classes.portion_name}>{
                                                            c.BundlePlanPrice.BundlePlan.Groups.map((e, i) => (
                                                                e.Portions.map((p, ip) => (
                                                                    p.name + (ip != e.Portions.length - 1 ? "," : "")
                                                                ))
                                                            ))
                                                        }</span>

                                                        {/* No of test : {c.BundlePlanPrice.BundlePlan.Groups.reduce((noOfTests, test) => noOfTests + test.noOfTests, 0)}<br />
                                                        No of Subject : {c.BundlePlanPrice.BundlePlan.Groups.reduce((sub, test) => sub + test.Subjects.length, 0)}<br />
                                                        No of Paper : {(c.BundlePlanPrice.BundlePlan.Groups.reduce((sub, test) => sub + test.Subjects.length, 0)) * c.BundlePlanPrice.BundlePlan.Groups.reduce((noOfTests, test) => noOfTests + test.noOfTests, 0)} */}
                                                    </span>
                                                    <span><s className={classes.price}>₹ {c.BundlePlanPrice.price} /-</s> <br />₹ {(c.BundlePlanPrice.discountType == "flat" ? c.BundlePlanPrice.price - c.BundlePlanPrice.discount : c.BundlePlanPrice.price - c.BundlePlanPrice.price * (c.BundlePlanPrice.discount / 100))} /-</span>

                                                </div>
                                                <ul className={classes.plan_subject} >
                                                    {
                                                        c.BundlePlanPrice.BundlePlan.Groups.map((e, i) => (
                                                            e.Subjects.map((p, ip) => (
                                                                <li><span>&#9632;</span><span>{p.name}</span><span></span></li>
                                                            ))
                                                        ))
                                                    }
                                                </ul>
                                                <span className={classes.plan_papers}>
                                                    <div className={classes.total_papers}>
                                                        <div className={classes.total_body}>
                                                            <span>No of test / Subject</span>
                                                            <span>{c.BundlePlanPrice.BundlePlan.Groups.reduce((noOfTests, test) => noOfTests + test.noOfTests, 0)}</span>
                                                        </div>
                                                        <div className={classes.total_body}>
                                                            <span>No of Subject</span>
                                                            <span>{c.BundlePlanPrice.BundlePlan.Groups.reduce((sub, test) => sub + test.Subjects.length, 0)}</span>
                                                        </div>
                                                        <div className={classes.total_body}>
                                                            <span>No of Paper</span>
                                                            <span>{(c.BundlePlanPrice.BundlePlan.Groups.reduce((sub, test) => sub + test.Subjects.length, 0)) * c.BundlePlanPrice.BundlePlan.Groups.reduce((noOfTests, test) => noOfTests + test.noOfTests, 0)}</span>
                                                        </div>
                                                    </div>
                                                </span>

                                            </div>
                                        ))
                                    }

                                    {
                                        props?.customCartData?.map((c, i) => (
                                            <div className={`${classes.data}`} >

                                                {
                                                    getPlanDetails(c).map((cp, ip) => (
                                                        <div style={{ lineHeight: "1.2" }}>
                                                            <span className={classes.plan_type}> {c?.CartCustomPlanRecords?.[0]?.Group?.Syllabus?.Course?.name} {cp.group.syllabus} <span>(Customize Plan)</span></span>
                                                            <br /><span style={{ fontSize: "12px", fontWeight: "700" }}>{cp.group.sessionName}</span>
                                                            <br /><span style={{ fontSize: "10px", fontWeight: "700" }}>{cp.group.name}</span><br />
                                                            <span className={classes.data_heading}>
                                                                {props.from != 'checkout' ?
                                                                    <span>
                                                                        <i onClick={() => props.handleDeleteByPlan(c.id, props?.cartDetails?.id)} className={`${classes.cartDelete} fa fa-minus-square-o`}></i>
                                                                    </span>
                                                                    : <span style={{ marginTop: "-2px" }}>&#9632;</span>}
                                                                <span>
                                                                    <b>{c.Plan.name}</b>
                                                                </span>
                                                                <span></span>
                                                            </span>
                                                            {
                                                                cp.portion.map((po, ipo) => (
                                                                    <>
                                                                        <span className={classes.portion_name}>{po.name}</span><br />
                                                                        {
                                                                            po.subject.map((su, isu) => (
                                                                                <div className={props.from != 'checkout' ? classes.data_body_custom : classes.data_body_custom_checkout}>
                                                                                    {
                                                                                        props.from != 'checkout' ?
                                                                                            <span><i onClick={() => props.handleDelete(findDeleteId(c.CartCustomPlanRecords, cp.group.id, po.id, su.id), "custom", props?.cartDetails?.id)} className={`${classes.cartDelete} fa fa-minus-square-o`}></i></span>
                                                                                            : ""
                                                                                    }
                                                                                    <span className={classes.detailsSpan}>{su.name}</span>
                                                                                    <span>
                                                                                        ₹ {su.price == su.totalPrice ? su.totalPrice + " /-" : <>
                                                                                            <s className={classes.price}>₹ {su.price} /-</s>
                                                                                            <br />₹ {su.totalPrice} /-
                                                                                        </>
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </>
                                                                ))
                                                            }
                                                            {/* {
                                                                ip != getPlanDetails(c).length - 1 ? <><hr /></> : ""
                                                            }<br /> */}
                                                            <span style={{ fontSize: "12px", fontWeight: "700" }}>
                                                                <div className={classes.total_papers}>
                                                                    <div className={classes.total_body}>
                                                                        <span>No of test / Subject</span>
                                                                        <span>{findCount(cp).test}</span>
                                                                    </div>
                                                                    <div className={classes.total_body}>
                                                                        <span>No of Subject</span>
                                                                        <span>{findCount(cp).subject}</span>
                                                                    </div>
                                                                    <div className={classes.total_body}>
                                                                        <span>No of Paper</span>
                                                                        <span>{findCount(cp).paper}</span>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    /*c.CartCustomPlanRecords.map((cp, ip) => (
                                                        <div className={classes.data_body_custom} >
                                                            <span>{cp.Group.name} -  {cp.Portion.name} -  {cp.Subject.name}</span>
                                                            <span className={classes.priceSpan}>
                                                                {
                                                                    getPriceDetails(c).price == getPriceDetails(c).totalPrice
                                                                        ?
                                                                        <>
                                                                            {getPriceDetails(c).totalPrice}  /-
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <s className={classes.price}>{getPriceDetails(c).price}/-</s>
                                                                            <br />{getPriceDetails(c).totalPrice}  /-
                                                                        </>
                                                                }
                
                                                            </span>
                                                            {props.from != 'checkout' ?
                                                                <span><i onClick={() => props.handleDelete(cp.id, "custom")} className={`${classes.cartDelete} fa fa-minus-square-o`}></i></span>
                                                                : ""}
                                                        </div>
                                                    ))*/
                                                }
                                            </div>
                                        ))
                                    }

                                </div>
                                {
                                    props.from != 'checkout' ?
                                        <>
                                            {
                                                props.customCartData.length > 0 || props.bundleCartProduct.length > 0 ?
                                                    <>
                                                        <hr />
                                                        <span className={classes.plan_papers}>
                                                            <div className={classes.total_papers}>
                                                                {/* <div className={classes.total_body}>
                                                        <span>No of test / Subject</span>
                                                        <span>{findSubjectPaperTestCount().testCount}</span>
                                                    </div>
                                                    <div className={classes.total_body}>
                                                        <span>No of Subject</span>
                                                        <span>{findSubjectPaperTestCount().subjectCount}</span>
                                                    </div> */}
                                                                <div className={classes.total_body}>
                                                                    <span>Total no of papers</span>
                                                                    <span>{totalBundlePapersCount + totalCustomizePapersCount}&nbsp;&nbsp;&nbsp;</span>
                                                                </div>
                                                            </div>
                                                        </span>
                                                        <div className={classes.total_price}>
                                                            {
                                                                findTotal().customTotal + findTotal().bundleTotal != findTotal().customTotalPrice + findTotal().bundleTotalPrice ?
                                                                    <>
                                                                        <div className={classes.total_body}>
                                                                            <span>Total</span>
                                                                            <span>₹ {findTotal().customTotal + findTotal().bundleTotal} /-</span>
                                                                        </div>
                                                                        <div className={classes.total_body}>
                                                                            <span>Bundle Discount</span>
                                                                            {/* <span className={classes.offprice}>{((findTotal().customTotal + findTotal().bundleTotal) - (findTotal().customTotalPrice + findTotal().bundleTotalPrice)) / (findTotal().customTotal + findTotal().bundleTotal) * 100} % off<br />₹ {findTotal().customTotalPrice + findTotal().bundleTotalPrice} /-</span> */}
                                                                            <span className={classes.offprice}>₹ {((findTotal().customTotal + findTotal().bundleTotal) - (findTotal().customTotalPrice + findTotal().bundleTotalPrice)).toFixed(2)} /-</span>
                                                                        </div>
                                                                    </> : ""
                                                            }

                                                            <div className={classes.total_body}>
                                                                <span>Total Amount { }</span>
                                                                {/* <span className={classes.offprice}>{((findTotal().customTotal + findTotal().bundleTotal) - (findTotal().customTotalPrice + findTotal().bundleTotalPrice)) / (findTotal().customTotal + findTotal().bundleTotal) * 100} % off<br />₹ {findTotal().customTotalPrice + findTotal().bundleTotalPrice} /-</span> */}
                                                                <span className={classes.offprice}>₹ {findTotal().customTotalPrice + findTotal().bundleTotalPrice} /-</span>
                                                            </div>
                                                        </div>
                                                        <div className={classes.confirm_button_container}>
                                                            {/* {} */}
                                                            {
                                                                params.token ?
                                                                    <>
                                                                        <button onClick={() => onConfirmAddToCart(props?.cartDetails?.id, params.token, navigate)} className={`${btnClasses.btn_green} ${btnClasses.fullwidth}`} width="100%">Confirm Order</button>
                                                                        {/* {(findTotal().customTotalPrice + findTotal().bundleTotalPrice) > props?.cartDetails?.previousOrderAmountNoGst ?
                                                                            <button onClick={() => props.onPaymentEditOrder(props?.cartDetails?.id, params.token, navigate)} className={`${btnClasses.btn_green} ${btnClasses.fullwidth}`} width="100%">Send Payment Link</button>
                                                                            : null} */}

                                                                    </> : <button onClick={handleNext} className={`${btnClasses.btn_green} ${btnClasses.fullwidth}`} width="100%">Next</button>
                                                            }
                                                        </div>
                                                    </> : ""
                                            }

                                        </>
                                        : ""
                                }
                            </div>
                            {/*********** End before next Div  ************ */}
                        </> :
                            <>
                                {
                                    props.from != 'checkout' ?
                                        <div>
                                            <div className={classes.total_price}>
                                                {
                                                    totalAmount != subTotal ? <div className={classes.total_body}>
                                                        <span>Sub Total</span>
                                                        <span>₹ {subTotal} /-</span>
                                                    </div> : ""
                                                }

                                                {
                                                    discountAmount !== 0 ? <>

                                                        <div className={classes.total_body}>
                                                            <span>Bundle Discount</span>
                                                            {/* <span className={classes.offprice}>{Math.ceil(((findTotal().customTotal + findTotal().bundleTotal) - (findTotal().customTotalPrice + findTotal().bundleTotalPrice)) / (findTotal().customTotal + findTotal().bundleTotal) * 100)} % off<br />₹ {findTotal().customTotalPrice + findTotal().bundleTotalPrice} /-</span> */}
                                                            <span className={classes.offprice}>₹ {((findTotal().customTotal + findTotal().bundleTotal) - (findTotal().customTotalPrice + findTotal().bundleTotalPrice)).toFixed(2)} /-</span>
                                                        </div></> : ""
                                                }





                                                {
                                                    props?.cartData?.cartDetails?.data?.Coupon ?
                                                        <>
                                                            <div className={classes.total_body}>
                                                                {/* {props?.cartData?.cartDetails?.data?.Coupon?.discountType} */}

                                                                <span>Coupon Discount</span>
                                                                {/* <span>₹ {discountTypeVar == 'flat' ? findTotalVar - discountValue : findTotalVar - findTotalVar * (discountValue / 100)} /-</span> */}
                                                                <span>₹ {discountTypeVar == 'flat' ? discountValue : ((findTotal().customTotal + findTotal().bundleTotal) * (discountValue / 100)).toFixed(2)} /-</span>
                                                            </div>
                                                        </> : ""
                                                }
                                                <div className={classes.total_body}>
                                                    <span>Total Amount</span>
                                                    <span className={classes.offprice}>₹ {totalAmount} /-</span>
                                                </div>
                                            </div>
                                            {
                                                props.cartData.cartReferral && props.cartData.cartReferral?.length ?
                                                    <InputSelect
                                                        emptyValue={true}
                                                        value={Number(confirmState.referralId)}
                                                        options={props.cartData.cartReferral}
                                                        name="referredBy"
                                                        placeholder="Referred By"
                                                        label="Referred By"
                                                        onChange={(e) => handleChange("referralId", e.target.value)}
                                                    /> : ''
                                            }

                                            {
                                                otherReferredCheck ?
                                                    <InputField
                                                        type="text"
                                                        value={confirmState.referredFromOther}
                                                        name="referredFromOther"
                                                        placeholder="Please Specify"
                                                        label="Please Specify"
                                                        onChange={(e) => handleChange("referredFromOther", e.target.value)}
                                                    /> : ""
                                            }
                                            <Grid container justifyContent="center" alignItems="center">
                                                <Grid item xs={9} sm={9}>
                                                    <InputField
                                                        type="text"
                                                        value={couponState.code}
                                                        name="name"
                                                        placeholder=""
                                                        noMargin={true}
                                                        label="Enter Coupon Code"
                                                        onChange={(e) => handleCouponChange("code", e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} sm={3}>
                                                    <button onClick={handleApplyCoupon} className={classes.btn_apply}>Apply</button>
                                                </Grid>
                                                {
                                                    Object.keys(couponDetails).length != 0 && props?.cartDetails?.Coupon != null ?
                                                        <Grid item xs={12} sm={12}>
                                                            <div className={classes.couponBody}>
                                                                <div className={classes.total_body}>
                                                                    <span>COUPON APPLIED</span>
                                                                    <span title={couponDetails?.description} className={classes.couponValue}>{couponDetails?.name}</span>
                                                                    <span><i onClick={() => props.handleCouponDelete()} className={`${classes.cartDelete} fa fa-minus-square-o`}></i></span>
                                                                </div>
                                                            </div>
                                                        </Grid> : ""
                                                }
                                                {/* <Grid item xs={12} rowSpacing={0}>
                                                    <div className={classes.view_coupons}>
                                                        <p onClick={() => { setShowModal(true); props.onGetCoupon('cartCoupon') }}>View Coupons</p>
                                                    </div>
                                                    <ViewCouponModal handleCouponChange={handleCouponChange} couponData={props?.cartData?.cartCoupon} open={showModal} close={() => setShowModal(false)} />
                                                </Grid> */}


                                                <Grid item xs={12}>
                                                    <Heading width={'half'} text={'Select Your Preference :'} />
                                                </Grid>
                                                <Grid container item justifyContent="center" alignItems="center">
                                                    {
                                                        props?.testPreferenceDetails.map((c, i) => (
                                                            c.name == 'home' ?
                                                                <>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <button value={c.id} onClick={(e) => handleChange("testPreferenceId", c.id)} className={`${classes.w100} ${confirmState.testPreferenceId == c.id ? btnClasses.btn_gray : btnClasses.btn_gray_empty}`}>{confirmState.testPreferenceId == c.id ? <i className="fa fa-check-circle"></i> : ""} Test From Home</button>
                                                                    </Grid>

                                                                    {
                                                                        props?.testPreferenceDetails.length == 2 ? <div><span style={{ alignItems: "center", fontSize: "13px" }}>or</span></div> : ""
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <button value={c.id} onClick={(e) => handleChange("testPreferenceIdCenter", c.id)} className={`${classes.w100} ${confirmState.testPreferenceIdCenter == c.id ? btnClasses.btn_gray : btnClasses.btn_gray_empty}`}>{confirmState.testPreferenceIdCenter == c.id ? <i className="fa fa-check-circle"></i> : ""} Test From Center</button>

                                                                    {
                                                                        showCenterOption ?
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={6} sm={6}>

                                                                                    <InputSelect
                                                                                        emptyValue={true}
                                                                                        align="center"
                                                                                        selected={confirmState.cityId}
                                                                                        value={Number(confirmState.cityId)}
                                                                                        options={cityOption()}
                                                                                        name="cityId"
                                                                                        placeholder="Select City"
                                                                                        label="Select City"
                                                                                        onChange={(e) => {
                                                                                            handleChange('cityId', e.target.value);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6}>
                                                                                    <InputSelect
                                                                                        emptyValue={true}
                                                                                        align="center"
                                                                                        selected={confirmState.centreInCityId}
                                                                                        value={Number(confirmState.centreInCityId)}
                                                                                        options={centerOption ? centerOption : []}
                                                                                        name="centreInCityId"
                                                                                        placeholder="Select Center"
                                                                                        label="Select Center"
                                                                                        onChange={(e) => {
                                                                                            handleTest(c.id, e.target.value);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid> : ""
                                                                    }



                                                                </>
                                                        ))
                                                    }

                                                </Grid>
                                            </Grid>


                                        </div>
                                        : ""
                                }
                                {
                                    props.from != 'checkout' ?
                                        <>
                                            <label className={classes.checkbox_label}>
                                                {/* <input type="checkbox" onClick={(e) => { handleTermCondition(e) }} /> */}
                                                <CustomCheckbox size="small" onClick={(e) => { handleTermCondition(e) }} text="I agree with the" />
                                            </label>
                                            <span onClick={() => setGlobalModal(true)} className={classes.term_condition}> Terms & Conditions</span>
                                        </>
                                        : ""
                                }
                                <div className={classes.confirm_button_container}>
                                    {
                                        props.from != 'checkout' ? <button onClick={handleConfirm} className={`${btnClasses.btn_green} ${btnClasses.fullwidth}`} width="100%">Confirm Order</button> : ""
                                    }
                                </div>
                            </>
                    }



                    {/*
                        <div>
                            <span className={classes.cart_offer}><i className="fa fa-tag"></i> Offer Available</span>
                        </div>
                    */ }
                    {
                        props.from == 'checkout' ?
                            <>
                                <span className={classes.plan_papers}>
                                    <div className={classes.total_papers}>
                                        {/* <div className={classes.total_body}>
                                                        <span>No of test / Subject</span>
                                                        <span>{findSubjectPaperTestCount().testCount}</span>
                                                    </div>
                                                    <div className={classes.total_body}>
                                                        <span>No of Subject</span>
                                                        <span>{findSubjectPaperTestCount().subjectCount}</span>
                                                    </div> */}
                                        <div className={classes.total_body}>
                                            <span>Total no of papers</span>
                                            <span>{totalBundlePapersCount + totalCustomizePapersCount}&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                    </div>
                                </span>
                                {props?.orderDetails?.totalAmount ?
                                    <div className={classes.total_price}>
                                        <div className={classes.total_body}>
                                            <span>Sub Total</span>
                                            {/* <span>{props?.orderDetails?.totalAmount != props?.orderDetails?.totalAmountAfterDiscount ? <><s className={classes.price}>₹ {props?.orderDetails?.totalAmount}</s><br /><span className={classes.offprice}>{Math.ceil(((props?.orderDetails?.totalAmount) - (props?.orderDetails?.totalAmountAfterDiscount)) / (props?.orderDetails?.totalAmount) * 100)} % off</span><br /></> : ""}₹ {props?.orderDetails?.totalAmountAfterDiscount}</span> */}
                                            <span>₹ {props?.orderDetails?.totalAmount} /-</span>
                                        </div>
                                        {
                                            props?.orderDetails?.totalDiscount ?
                                                <>
                                                    <div className={classes.total_body}>
                                                        <span>Discounts</span>
                                                        <span>₹ {((props?.orderDetails?.totalAmount) - (props?.orderDetails?.totalAmountAfterDiscount)).toFixed(2)} /-</span>
                                                    </div>
                                                    <div className={classes.total_body}>
                                                        <span>Taxable Amount</span>
                                                        {/* <span>{props?.orderDetails?.totalAmount != props?.orderDetails?.totalAmountAfterDiscount ? <><s className={classes.price}>₹ {props?.orderDetails?.totalAmount}</s><br /><span className={classes.offprice}>{Math.ceil(((props?.orderDetails?.totalAmount) - (props?.orderDetails?.totalAmountAfterDiscount)) / (props?.orderDetails?.totalAmount) * 100)} % off</span><br /></> : ""}₹ {props?.orderDetails?.totalAmountAfterDiscount}</span> */}
                                                        <span>₹ {props?.orderDetails?.totalAmount - props?.orderDetails?.totalDiscount} /-</span>
                                                    </div>
                                                </> : null

                                        }

                                        <div className={classes.total_body}>
                                            <span>GST Amount</span>
                                            <span>₹ {props?.orderDetails?.totalGST.toFixed(2)} /-</span>
                                        </div>
                                        <div className={classes.total_body}>
                                            <span>Total</span>
                                            <span>₹ {props?.orderDetails?.totalAmountAfterGST.toFixed(2)} /-</span>
                                        </div>
                                    </div>
                                    : ""}
                                <div>
                                    <span className={classes.cart_offer} onClick={() => { setBillModal(true) }}><i className="fa fa-print"></i> View proforma Invoice</span><br />
                                    <CustomCheckbox size="small" onClick={(e) => { handleGstSection(e) }} text="Use GST Invoice" />
                                    {/* <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={classes.gstNumber}>27AADCL6768N1ZQ</span> */}
                                    {
                                        gstSection ?
                                            <Grid container spacing={1} justifyContent="center" alignItems="center">
                                                <Grid item xs sm>
                                                    <InputField
                                                        type="text"
                                                        value={gstState.gstNumber}
                                                        name="gstNumber"
                                                        placeholder=""
                                                        label="Enter GST Number"
                                                        onChange={(e) => handleGstChange("gstNumber", e.target.value)}
                                                        maxLength={15}
                                                    />
                                                </Grid>
                                                {/* <Grid item xs sm={3}>
                                                    <button onClick={handleGstSave} className={classes.btn_apply}>Save</button>
                                                </Grid> */}
                                            </Grid>
                                            : ""
                                    }

                                </div>
                            </>
                            : ""
                    }

                    {
                        props.from == 'checkout' ?
                            <div className={classes.otherDetails}>
                                <div className={classes.total_body}>
                                    <span>Referred By</span>
                                    <span>{props?.cartData?.cartDetails?.data?.referredFromOther ? props?.cartData?.cartDetails?.data?.referredFromOther : props?.cartData?.cartDetails?.data?.Referral?.name}</span>
                                </div>
                                <div className={classes.total_body}>
                                    <span>Test Preference</span>
                                    <span>{props?.cartData?.cartDetails?.data?.CentreInCity ? <>{props?.cartData?.cartDetails?.data?.CentreInCity?.city.name} ({props?.cartData?.cartDetails?.data?.CentreInCity?.name})</> : <>{props?.cartData?.cartDetails?.data?.TestPreference?.name}</>}</span>
                                </div>
                                {
                                    props?.cartData?.cartDetails?.Coupon != null ? <div className={classes.total_body}>
                                        <span>Coupon</span>
                                        <span>{props?.cartData?.cartDetails?.Coupon?.name}</span>
                                    </div> : ""
                                }

                            </div>
                            : ""
                    }



                    <div className={classes.confirm_button_container}>
                        {
                            props.from == 'checkout' ?
                                <>
                                    <button onClick={() => handleConfirmCheckout(null)} className={`${btnClasses.btn_green} ${btnClasses.fullwidth}`} width="100%">Confirm Order</button>
                                    {
                                        localStorage.getItem('masterLogin') && localStorage.getItem('masterLogin') !== 'false' ?
                                            <button onClick={() => handleConfirmCheckout(localStorage.getItem('masterLogin'))} className={`${btnClasses.btn_gray_empty} ${btnClasses.fullwidth}`} style={{ width: "100%", marginTop: "10px" }}>Confirm Order With COD</button> : ""
                                    }
                                </>

                                : ""
                        }

                    </div>
                </div >

            </div >
        </>
    );
};


const mapStateToProps = (state) => {
    return {
        cartData: state.CartData,
        profile: state.profile,
        invoice: state.Invoice
    }
}
const mapDispatchToProps = { onFetchOrderDetails, onFetchInvoicePreview, onApplyCoupon, onGetCoupon, onGetProfile, onUpdateCenterPreference, onOrderCheckout, onFetchCartData, onEmptyCart, onConfirmEditOrder, onPaymentEditOrder }
export default connect(mapStateToProps, mapDispatchToProps)(CartComponent);