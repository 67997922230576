import moment from 'moment';

export const todaysDate = () => {
    return moment().format("DD-MM-YYYY");
}

export const dateStandard = (date) => {
    return moment(date).format("DD-MM-YYYY");
}

export const dateIndian = (date) => {
    return date ? moment(date).format("DD/MM/YYYY") : "";
}
export const monthIndian = (month) => {
    return month ? moment(month).format("MM/YYYY") : month;
}

export const dateBasicFormant = (date) => {
    var _date = moment(date, "YYYY-MM-DD");

    return _date._d;
}

export const dateTimeBasicFormant = (date) => {
    var _date = moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY hh:mmA");
    return _date;
}


export const dateUSStandard = (date) => {
    return moment(date).format("MM-DD-YYYY");
}

export const dateSlashFormant = (date) => {
    return moment(date).format("YYYY/MM/DD");
}

export const dateArithmetic = (date, qty) => {
    return moment(date, "YYYY-MM-DD").add(qty, 'days').format("YYYY-MM-DD");
}

export const dateDifference = (start, end) => {
    start = moment(start, "YYYY-MM-DD");
    end = moment(end, "YYYY-MM-DD");
    return end.diff(start, 'days');
}

export const dateMonthFormat = (date) => {
    return moment(date).format("DD MMMM, YYYY");
}

export const changeTzToNormal = (date) => {
    if (date) {
        let sDate = moment(date);
        sDate = sDate.format('DD-MM-YYYY hh:mm A')
        return sDate
    }
    else {
        return ""
    }

}

export const changeTzToNormalDefault = (date) => {
    if (date) {
        let sDate = moment(date);
        sDate = sDate.format('YYYY-MM-DD HH:mm')
        // console.log(sDate)
        // console.log(sDate)
        return {
            date: sDate.split(" ")[0],
            time: sDate.split(" ")[1]
        }
    }
    else {
        return {
            date: "",
            time: ""
        }
    }

}

export const singleDate = (date) => {
    return date.date + " " + date.time
}


export const TimeDiff = (startTime, endTime) => {

    var todayDate = moment(new Date()).format("MM-DD-YYYY");
    var startDate = new Date(`${todayDate} ${startTime}`);
    var endDate = new Date(`${todayDate} ${endTime}`);
    var timeDiff = endDate.getTime() - startDate.getTime();
    // console.log(timeDiff);
    if (timeDiff <= 0) {
        var nextDay = moment(new Date()).add(1, 'days').format("MM-DD-YYYY");
        endDate = new Date(`${nextDay} ${endTime}`);
    }

    // console.log(endDate);
    timeDiff = endDate.getTime() - startDate.getTime();

    var hh = Math.floor(timeDiff / 1000 / 60 / 60);
    hh = ('0' + hh).slice(-2)

    timeDiff -= hh * 1000 * 60 * 60;
    var mm = Math.floor(timeDiff / 1000 / 60);
    mm = ('0' + mm).slice(-2)

    timeDiff -= mm * 1000 * 60;
    var ss = Math.floor(timeDiff / 1000);
    ss = ('0' + ss).slice(-2)

    return {
        h: hh,
        m: mm
    }
}


//Time
export const changeTime = (time) => {
    let _time = time.split(':');
    return _time[0] + ":" + _time[1];
}

export const timeFormat = (time) => {
    // console.log(time)
    let _time = moment(time, "HH:mm:ss").format("hh:mmA").slice(0, -1);
    // console.log(_time)
    return _time
}

export const timeFormatWithoutSeconds = (time) => {
    let _time = moment(time, "HH:mm:ss").format("HH:mm");
    return _time
}


export const timeFormatWithData = (date) => {
    // console.log(date)
    let _time = changeTzToNormal(date);
    // console.log(_time);
    return timeFormat(_time.time + ":00")
}

export const timeFormatFull = (time) => {

    time = time.split("T");
    time = time[1].split(".")

    let _time = moment(time[0], "HH:mm:ss").format("hh:mmA");
    return _time
}

export const dateTimeFormatFull = (time) => {
    try {
        if (time) {
            if (time.indexOf("T") == -1) {
                return {
                    date: dateStandard(time),
                    time: '',
                }
            }
            let date = time.split("T");
            time = date[1].split(".")

            return {
                date: dateStandard(date[0]),
                time: moment(time[0], "HH:mm:ss").format("HH:mm"),
            }
        }
        return {
            date: "",
            time: ""
        }
    } catch (e) {
        return {
            date: "",
            time: ""
        }
    }


}

//week and month
export const weekDate = () => {
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var start = dateStandard(new Date(curr.setDate(first)));
    var end = dateStandard(new Date(curr.setDate(last)));
    return {
        start: start,
        end: end,
    }
}

export const monthDate = () => {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    return {
        start: dateStandard(firstDay),
        end: dateStandard(lastDay),
    }
}

//compare teo time 
export const compareTwoTime = (start, end) => {
    if (start && end) {
        var beginningTime = moment(start, 'HH:mm');
        var endTime = moment(end, 'HH:mm');
        return beginningTime.isBefore(endTime);
    }
    return false;

}

export const updateShiftHours = (startDate, endDate) => {
    // console.log(startDate, endDate)
    if (endDate.length == 0 || startDate.length == 0) return

    // console.log(moment(endDate, 'YYYY-MM-DD hh:mm'))

    const duration = moment.duration(moment(endDate, 'YYYY-MM-DD hh:mm').diff(moment(startDate, 'YYYY-MM-DD hh:mm')));

    const totalHours = `${parseInt(duration.asHours())}.${parseInt(duration.asMinutes()) % 60}`

    return totalHours

}


export const generateTimeSlots = (startDate, endDate, slotDurationMinutes) => {
    const timeslots = [];

    try {
        const currentDate = new Date(startDate);

        while (currentDate < endDate) {
            const startTime = new Date(currentDate);

            const format = {
                hour: "2-digit",
                minute: "2-digit"
            }

            const startTime12Format = startTime.toLocaleTimeString("en-US", {
                hour12: true,
                ...format
            });

            const endTime = new Date(startTime.getTime() + slotDurationMinutes * 60000);

            const endTime12Format = endTime.toLocaleTimeString("en-US", {
                hour12: true,
                ...format
            });

            const startTimeStringOnly = startTime.toLocaleTimeString("en-US", {
                hour12: false,
                ...format
            });

            const endTimeStringOnly = endTime.toLocaleTimeString("en-US", {
                hour12: false,
                ...format
            });

            const title = `${startTime12Format} to ${endTime12Format}`;

            timeslots.push({
                id: timeslots.length + 1,
                title,
                startTime: startTimeStringOnly,
                endTime: endTimeStringOnly,
            });

            currentDate.setTime(endTime.getTime());
        }
    } catch (error) {
        console.error("An error occurred:", error.message);
    }

    return timeslots;
};

export const formatUTCtoUTCDateMnameYearTime = (meetingTime) => {
    // #EXAMPLE "2023-11-17T10:00:00Z" TO 17 Nov 23, 10:00AM
    const localMeetingTime = moment.utc(meetingTime);
    return localMeetingTime.format("DD MMM YY, hh:mmA");
};

export const dateFormatUTCtoLocal = (dateStr) => {

    // GMT TO Example: "16 Dec 23, 04:00PM"
    const date = moment(dateStr);

    const formattedDate = date.format('DD MMM YY, hh:mmA');

    return formattedDate;
};

export const dateFormatLocaleTLocale = (inputDate) => {
    // inputDate: '12/15/2023, 3:02:14 PM'
    // newDate: '15 Dec 23, 3:02PM'
    if (!inputDate) {
        return null
    }

    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const [datePart, timePart] = inputDate.split(', ');
    const [month, day, year] = datePart.split('/');
    const [hours, minutes, seconds] = timePart.replace(/(AM|PM)/, '').split(':');

    const newDate = `${parseInt(day)} ${months[parseInt(month) - 1]} ${year.slice(-2)}, ${hours}:${minutes}${timePart.includes('AM') ? 'AM' : 'PM'}`;
    return newDate;
};