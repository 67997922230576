import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import classes from './styles.module.scss';
import InputField from '../inputField';
import Select from "react-select";
import PrimaryButton from '../button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect } from 'react';
import { useState } from 'react';
import { onLoad, fetchState, fetchStateDistrict, fetchDistrictCity, fetchCourse, fetchCourseSyllabus, onEditProfile, onEmailSubmit, onEmailOTP } from "../../pages/profile/action";
import { connect } from "react-redux";
import InputSelect from '../inputSelect';
import TextArea from "../textArea";
import CloseIcon from '@mui/icons-material/Close';
import $ from 'jquery';
import { actionNotifier } from "../../components/ui/toast";
import { useNavigate, useParams } from 'react-router-dom';

const BasicModal = (props) => {
    const navigate = useNavigate();
    //console.log(props)


    const [state, setState] = useState({
        "firstName": null,
        "middleName": null,
        "lastName": null,
        "districtId": null,
        "cityId": null,
        "institutionId": null,
        "genderId": null,
        "syllabusId": null,
        "courseId": null,
        "password": null,
        "confirmPassword": null,
        "addressId": null,
        "addressName": "Home",
        "addressPhone": null,
        "address": null,
        "pincode": null,
        "landmark": null,
        "email": null,
        "otp": null
    })


    //console.log(profileCount, length)
    const [uState, setUState] = useState([])
    const [uStateDistrict, setUStateDistrict] = useState([])
    const [uStateDistrictCity, setUStateDistrictCity] = useState([])
    const [uCourse, setUCourse] = useState([])
    const [uCourseSyllabus, setUCourseSyllabus] = useState([])


    // State
    const fetchInitialData = async () => {
        let res = await fetchState()
        if (res.status) {
            setUState(res.data)
        }
    }
    useEffect(() => {
        fetchInitialData()
    }, [])

    //District
    const fetchDistrict = async (e) => {
        if (e) {
            let res = await fetchStateDistrict(e)
            if (res.status) {
                setUStateDistrict(res.data)
            }
        }
    }
    useEffect(() => {
        fetchDistrict(Number(state?.stateId))
    }, [state?.stateId])


    //City
    const fetchCity = async (e) => {
        if (e) {
            let res = await fetchDistrictCity(e)
            if (res.status) {
                setUStateDistrictCity(res.data)
            }
        }
    }
    useEffect(() => {
        fetchCity(Number(state?.districtId))
    }, [state?.districtId])

    //Course
    const fetchOriginData = async () => {
        let res = await fetchCourse()
        if (res.status) {
            setUCourse(res.data)
        }
    }
    useEffect(() => {
        fetchOriginData()
    }, [])

    //Syllabus
    const fetchSyllabus = async (e) => {
        // alert(e)
        if (e) {
            let res = await fetchCourseSyllabus(e)
            //console.log(res)
            if (res.status) {
                setUCourseSyllabus(res.data)
            }
        }
    }
    useEffect(() => {
        fetchSyllabus(Number(state?.courseId))
    }, [state?.courseId])
    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleChange = (name, value) => {
        let maxLength;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        switch (name) {
            case 'addressPhone':
                maxLength = 10;
                break;
            case 'pincode':
            case 'otp':
                maxLength = 6;
                if (value !== '') {
                    value = +value;
                }
                break;
            default:
                break;
        }

        if (maxLength && value.length > maxLength) {
            value = value.slice(0, maxLength);
        }

        setState({
            ...state,
            [name]: value
        });
    };
    const checkPassword = (e) => {
        let _state = { ...state };

        if (e.target.value != "" && e.target.value !== state.password) {
            actionNotifier.warning("Password not matched");
            e.target.focus();
            _state.password("")
            _state.confirmPassword("")
            setState(_state)
        }
    }
    const checkPasswordLength = (e) => {
        let _state = { ...state };
        if (e.target.value != "") {
            if (e.target.value.length < 7) {
                actionNotifier.warning("password must be larger than or equal to 7 characters");
                e.target.focus();
                _state.password("")
                setState(_state)
            }
        }
    }


    const validateName = (name, fieldName) => {
        if (name === '' || name === null || name === undefined) {
            if (fieldName === "First Name") {
                actionNotifier.warning(`${fieldName} can't be null`);
                return false;
            }
            return true;
        }
    
        if (name === '') {
            actionNotifier.warning(`${fieldName} can't be null`);
            return false;
        }
    
        const NAME_REGEX = /^[a-zA-Z]+$/;
        const isValidName = NAME_REGEX.test(name);
    
        if (!isValidName) {
            actionNotifier.warning(`Invalid characters in ${fieldName}`);
            return false;
        }
    
        const MIN_NAME_LENGTH = 3;
        const MAX_NAME_LENGTH = 10; 
    
        if (name.length < MIN_NAME_LENGTH || name.length > MAX_NAME_LENGTH) {
            actionNotifier.warning(`${fieldName} must be between ${MIN_NAME_LENGTH} and ${MAX_NAME_LENGTH} characters`);
            return false;
        }
    
        return true;
    };
    

    const handleSubmit = (e) => {
        if (
            !validateName(state.firstName, "First Name") ||
            !validateName(state.middleName, "Middle Name") ||
            !validateName(state.lastName, "Last Name")
        ) { return; }
        
        if (state.password) {
            if (state.password.length <= 7) {
                actionNotifier.warning("Password must be larger than or equal to 7 characters");
                return;
            }
            if (!state.confirmPassword) {
                actionNotifier.warning("Enter Confirm Password");
                return;
            }
            if (state.confirmPassword != state.password) {
                actionNotifier.warning("Password not matched");
                return;
            }

        }
        e.preventDefault();
        props.onSingUp(state, props.setShowModal);
    }

    // const [otpLogin, setOtpLogin] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const [isRunning, setIsRunning] = useState(false);
    const handleReset = () => {
        setIsRunning(false);
        setCountdown(60);
    };
    useEffect(() => {
        let interval = null;

        if (isRunning) {
            interval = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown > 1) {
                        return prevCountdown - 1;
                    } else {
                        clearInterval(interval);
                        setIsRunning(false);
                        handleReset()
                        return 0;
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isRunning]);

    // console.log(isRunning, countdown);

    // const handleEmailSubmit = (e) => {
    //     //console.log(state)
    //     e.preventDefault();
    //     if (!state.otp) {
    //         actionNotifier.warning("Enter OTP!");
    //         return;
    //     }
    //     props.onEmailSubmit({ email: state.email, otp: state.otp }, props.setShowModal)
    //     //props.onLoad()
    // }


    const handleEmailSubmit = (e) => {
        e.preventDefault();

        const { email, otp } = state;
        // console.log(state);
        if (!otp) {
            actionNotifier.warning("Enter OTP!");
            return;
        }

        props.onEmailSubmit({ email, otp }, props.setShowModal);
    };
    const handleEmailOTP = (e) => {
        //console.log(state)
        e.preventDefault();
        if (!state.email) {
            actionNotifier.warning("Enter your email!");
            return;
        }
        if (!isEmailValid(state.email)) {
            actionNotifier.warning("Invalid email!");
            return;
        }

        if (!isRunning && countdown === 60) {
            setIsRunning(true);
            //setOtpLogin(true)
            props.onEmailOTP({ email: state.email })
        }

        //props.onLoad()
    }

    useEffect(() => {

        //console.log(props.profile.allProfiles)
        setState({
            firstName: props?.profile?.allProfiles?.firstName,
            middleName: props?.profile?.allProfiles?.middleName,
            lastName: props?.profile?.allProfiles?.lastName,
            addressPhone: props?.profile?.allProfiles?.mobileNo ? props?.profile?.allProfiles?.mobileNo?.replace(/\D/g, '').slice(-10) : "",
            email: props?.profile?.allProfiles?.email,
            courseId: props?.profile?.allProfiles?.courseId,
            syllabusId: props?.profile?.allProfiles?.syllabusId,
            // stateId: props?.profile?.allProfiles?.District?.stateId,
            // districtId: props?.profile?.allProfiles?.districtId,
            // cityId: props?.profile?.allProfiles?.cityId,
            mobileNo: props?.profile?.allProfiles?.mobileNo,
            // address: props?.profile?.allProfiles?.address?.address,
            // pincode: props?.profile?.allProfiles?.address?.pincode,
            // addressId: props?.profile?.allProfiles?.address?.id,

        })

    }, [props?.profile?.allProfiles]);
    return (
        <div>
            <Modal
                style={{ overflowX: "auto" }}
                open={props.open}
                onClose={props.close}
            >
                <Box className={classes.profileBox}>
                    <div className={classes.content}>
                        <div className={classes.header}>
                            <p><span>Complete Your Profile</span>{/*<span onClick={props.close}><CloseIcon /></span>*/}</p>
                        </div>
                        <div className={classes.form}>
                            <div className={classes.form_content}>
                                <div className={classes.first_content}>
                                    <div className={classes.first_content_left}>
                                        <InputField
                                            required={true}
                                            type="text"
                                            width="100%"
                                            height="36px"
                                            placeholder="first name"
                                            label="First Name"
                                            value={state.firstName}
                                            onChange={(e) => handleChange("firstName", e.target.value)}
                                        />

                                        <InputField
                                            required={false}
                                            type="text"
                                            width="100%"
                                            height="36px"
                                            placeholder="last name"
                                            label="Last Name"
                                            value={state.lastName}
                                            onChange={(e) => handleChange("lastName", e.target.value)}
                                        />
                                        <InputField
                                            required={true}
                                            type="password"
                                            width="100%"
                                            height="36px"
                                            placeholder="**************************"
                                            label="Password"
                                            value={state.password}
                                            onBlur={(e) => checkPasswordLength(e)}
                                            onChange={(e) => handleChange("password", e.target.value)}
                                        />

                                        <div className={classes.select_field}>
                                            <InputSelect
                                                required={true}
                                                value={state.syllabusId}
                                                options={uCourseSyllabus}
                                                name="syllabusId"
                                                placeholder="Enter Syllabus"
                                                label="Syllabus"
                                                onChange={(e) => handleChange("syllabusId", e.target.value)}
                                            />
                                        </div>

                                    </div>

                                    <div className={classes.first_content_right}>
                                        <InputField
                                            required={false}
                                            type="text"
                                            width="100%"
                                            height="36px"
                                            placeholder="middle name"
                                            label="Middle Name"
                                            value={state.middleName}
                                            onChange={(e) => handleChange("middleName", e.target.value)}
                                        />

                                        <InputField
                                            disabled={true}
                                            type="number"
                                            width="100%"
                                            height="36px"
                                            placeholder="Mobile No"
                                            label="Mobile No"
                                            value={state.addressPhone}
                                        />
                                        <InputField
                                            required={true}
                                            type="password"
                                            width="100%"
                                            height="36px"
                                            placeholder="**************************"
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            value={state.confirmPassword}
                                            onBlur={(e) => checkPassword(e)}
                                            onChange={(e) => handleChange("confirmPassword", e.target.value)}
                                        />
                                        <div className={classes.select_field}>
                                            <InputSelect
                                                required={true}
                                                value={state.courseId}
                                                options={uCourse}
                                                name="course"
                                                placeholder="Enter Course"
                                                label="Course"
                                                onChange={(e) => handleChange("courseId", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>


                                {/* <div className={classes.second_content}>
                                    <p>Address</p>
                                    <TextArea
                                        required={true}
                                        value={state.address}
                                        name="address"
                                        placeholder="Enter Complete Address"
                                        label="Complete Address"
                                        onChange={(e) => handleChange("address", e.target.value)}
                                    />
                                    <div className={classes.second_sub_content}>
                                        <div className={classes.second_sub_content_left}>
                                            <InputSelect
                                                // required={true}
                                                value={state.stateId}
                                                options={uState}
                                                name="stateId"
                                                placeholder="Enter State"
                                                label="State"
                                                onChange={(e) => handleChange("stateId", e.target.value)}
                                            />
                                            <InputSelect
                                                // required={true}
                                                options={uStateDistrictCity}
                                                value={state.cityId}
                                                name="cityId"
                                                placeholder="Enter City"
                                                label="City"
                                                onChange={(e) => handleChange("cityId", e.target.value)}
                                            />
                                        </div>
                                        <div className={classes.second_sub_content_right}>
                                            <InputSelect
                                                // required={true}
                                                value={state.districtId}
                                                options={uStateDistrict}
                                                name="districtId"
                                                placeholder="Enter District"
                                                label="District"
                                                onChange={(e) => handleChange("districtId", e.target.value)}
                                            />
                                            <InputField
                                                required={false}
                                                type="number"
                                                placeholder="eg. 123456"
                                                label="PIN Code"
                                                value={state.pincode}
                                                onChange={(e) => handleChange("pincode", e.target.value)}
                                            />

                                        </div>
                                    </div>



                                </div> */}



                                <PrimaryButton
                                    buttonText={"save"}
                                    fontSize="12px"
                                    width="100%"
                                    height="38px"
                                    color="#fff"
                                    backgroundColor="#005ca8"
                                    onClick={handleSubmit}
                                />

                                <InputField
                                    disabled={isRunning}
                                    required={true}
                                    type="email"
                                    marginTop="0px"
                                    width="100%"
                                    height="36px"
                                    placeholder="eg abcd@abcd.com"
                                    label="Email"
                                    value={state.email}
                                    name="email"
                                    onChange={(e) => handleChange("email", e.target.value)}
                                />
                                <div className={classes.resend_button}>
                                    <PrimaryButton
                                        float={"right"}
                                        buttonText={isRunning ? "Resend OTP " + (isRunning ? "(" + countdown + ")" : "") : "Send OTP"}
                                        fontSize="12px"
                                        width="30%"
                                        height="18px"
                                        color="#fff"
                                        backgroundColor="#005ca8"
                                        onClick={handleEmailOTP}
                                        disabled={isRunning ? true : false}
                                        marginTop="5px"
                                    />
                                </div>
                                <InputField
                                    required={true}
                                    type="number"
                                    marginTop="0px"
                                    width="100%"
                                    height="36px"
                                    placeholder="eg XXXXXX"
                                    label="OTP"
                                    name='otp'
                                    value={state.otp}
                                    onChange={(e) => handleChange("otp", e.target.value)}
                                /><br />
                                <PrimaryButton
                                    buttonText={"Update Email"}
                                    fontSize="12px"
                                    width="100%"
                                    height="38px"
                                    color="#fff"
                                    backgroundColor="#005ca8"
                                    onClick={handleEmailSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

// export default BasicModal;

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = { onEditProfile, onEmailSubmit, onEmailOTP }

export default connect(mapStateToProps, mapDispatchToProps)(BasicModal);
