import { Base64 } from 'js-base64';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { checkPath } from './social';
export const commaSeparate = (data) => {
    // console.log(data)
    return data ? data.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',') : 0;
}

export const openInNewTab = (url, questionId) => {
    url = `${checkPath(url)}`
    window.open(url, "_blank");
    //props.onUpdateLastView(questionId)
}
export const encryptString = (text) => {
    return Base64.encode(text);
}

export const decryptString = (text) => {
    return Base64.decode(text);
}
export const confirmRequest = (text, action) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h4>PREPCA message</h4>
                    <h5>{text}</h5>
                    <div className="react-confirm-alert-button-group">
                        <button onClick={() => { onClose(); }}>No</button>
                        <button id="btn-confirm-delete-cart" onClick={() => { action(); onClose(); }}>
                            Confirm
                        </button>
                    </div>
                </div>
            );
        }
    });
}