import React, { useState } from "react";
import classes from "./styles.module.scss";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const MobileTableList = ({ items }) => {
    const { name, name2, name3, links, open } = items;
    const [isOpen, setOpen] = useState(open);
    const openSideNav = () => {
        setOpen(!isOpen)
    }
    return (
        <div className={classes.table_list}>
            <div>
                <p className={classes.list_head} onClick={() => openSideNav()}>
                    <span>
                        {items?.FeedbackQuestion?.question && <div>{items?.FeedbackQuestion?.question}</div>}
                        <div className={classes.second_text}>{name}</div>
                        <div className={classes.right_texts}>
                            {name2 && <div className={classes.second_text2}>{name2}</div>}
                            {name3 && <div className={classes.second_text3}>{name3}</div>}
                        </div>

                    </span>
                    <ArrowForwardIosIcon />
                </p>
                <div className={classes.list_section}>
                    {isOpen && links.map((link, index) => {
                        const { title, title_2, title_3, to, button } = link;
                        return (
                            <div className={classes.list_items} key={index}>
                                <a href={to}>
                                    <div>
                                        <p><span>{title}</span></p>
                                    </div>
                                    <span>{button}</span>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default MobileTableList;