import React from "react";
import MobileTableList from "../../components/mobileTableList";
import classes from "./styles.module.scss";

const PageTableMobile = (props) => {
   
    return(
        <div className={classes.mobile_view}>
            {props.rows.map((navItem, index)=>{
                return(
                    <MobileTableList key={index} items={navItem} />
                )
            })}
        </div>
    )
}

export default PageTableMobile;