import { apiFunction } from "../../apiCalls/functions/department"
import { ORDER_LIST, ORDER_BY_ID, MAIL_INVOICE } from "../../apiCalls/urls/order";
import { actionNotifier } from "../../components/ui/toast";
import { getDataFromStore } from "../../store/getStore";
import { apiLoading, apiLoadingEnd } from "../../store/notification/action";
import { apiLoadingOverlay, apiLoadingOverlayEnd } from "../../store/overlayloader/overlayLoadingSlice";
import * as actionTypes from './actionTypes'

export const fetchOrder = async (data, page, items) => {
    return await apiFunction(ORDER_LIST + `?page=${page}&items=${items}`, 'post', data, false, true)
}
export const sendMail = async (data) => {
    return await apiFunction(MAIL_INVOICE, 'post', data, false, true)
}
//AllAPIS
export function onLoad() {
    return async (dispatch) => {
        dispatch(onFetchOrder())
    }
}
export function onSendMail(data) {
    return async (dispatch) => {
        dispatch(apiLoadingOverlay())
        let result = await sendMail(data);        
        if (result.status) {
            actionNotifier.success('Invoice send to your registered E-Mail id.');
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingOverlayEnd())
    }
}
//API ACTIONS
export function onFetchOrder() {
    return async (dispatch) => {
        dispatch(apiLoading())
        let orderReducer = getDataFromStore("OrderDetails")
        //console.log(orderReducer);
        let result = await fetchOrder(orderReducer.filters, orderReducer.page, orderReducer.items);
        //let result = await fetchOrder();
        if (result.status) {
            dispatch(updateOrder(result.data))
        }
        else {
            actionNotifier.error(result.message)
        }
        dispatch(apiLoadingEnd())
    }
}

//ACTIONS
export const updateOrder = (data, name) => {
    return {
        type: actionTypes.UPDATE_ORDERS,
        payload: { data, name }
    };
}
export const updateFilters = (data) => {
    return {
        type: actionTypes.UPDATE_FILTER,
        payload: data
    };
}

export const updateReducer = (data, item) => {
    return {
        type: actionTypes.UPDATE_REDUCER,
        payload: { data, item }
    };
}
