import * as actionTypes from './actionTypes';
import { apiFunction } from "../../apiCalls/functions/department"
import { LOGIN, OTP_SIGNUP, RESEND_OTP, OTP_LOGIN, THIRD_PARTY_LOGIN, SIGN_UP, UPDATE_PASSWORD_OTP, FORGOT_PASSWORD_OTP } from "../../apiCalls/urls/login"
import { apiLoading, apiLoadingEnd } from "../notification/action";
import { actionNotifier } from "../../components/ui/toast";
import { cipher } from '../../shared/encDec';
import { onGetProfile } from '../../pages/profile/action';

//AllAPIS
export const fetchLoginDetails = async (data) => {
    return await apiFunction(LOGIN, 'post', data, false, false)
}
export const otpLogin = async (data) => {
    return await apiFunction(OTP_LOGIN, 'post', data, false, false)
}
export const otpSignup = async (data) => {
    return await apiFunction(OTP_SIGNUP, 'post', data, false, false)
}
export const thirdPartyLoginSignup = async (data) => {
    return await apiFunction(THIRD_PARTY_LOGIN, 'post', data, false, false)
}
export const forgotPassword = async (data) => {
    return await apiFunction(THIRD_PARTY_LOGIN, 'post', data, false, false)
}
export const signUp = async (data) => {
    return await apiFunction(SIGN_UP, 'PUT', data, false, true)
}
export const forgotPasswordOTP = async (data) => {
    return await apiFunction(FORGOT_PASSWORD_OTP, 'post', data, false, false)
}
export const updatePasswordOTP = async (data) => {
    return await apiFunction(UPDATE_PASSWORD_OTP, 'post', data, false, false)
}
export const resendOTP = async (data) => {
    return await apiFunction(RESEND_OTP, 'post', data, false, false)
}
export function onResendOTP(data) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await resendOTP(data);
        if (result.status) {
            actionNotifier.success("OTP send.")
        } else {
            if (result.statusCode == 403)
                actionNotifier.error("Mobile number not registered!")
            else
                actionNotifier.error(result.message)
        }
        // console.log(result)
        dispatch(apiLoadingEnd())
    }
}
export function onForgotPasswordOTP(data, setOtpSection) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await forgotPasswordOTP(data);
        if (result.status) {
            actionNotifier.success("OTP send.")
            setOtpSection(false)
        } else {
            if (result.statusCode == 403)
                actionNotifier.error("Mobile number not registered!")
            else
                actionNotifier.error(result.message)
        }
        // console.log(result)
        dispatch(apiLoadingEnd())
    }
}
export function onUpdatePasswordOTP(data, emptyState) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await updatePasswordOTP(data);
        // console.log(result);
        if (result.status) {
            actionNotifier.success("Password update.")
            emptyState()
        } else {
            actionNotifier.error(result.message)
        }
        // console.log(result)
        dispatch(apiLoadingEnd())
    }
}
export function onSingUp(data, setShowModal) {
    return async (dispatch) => {
        dispatch(apiLoading())
        // console.log(data);
        let obj = {
            "firstName": data.firstName,
            "middleName": data.middleName,
            "lastName": data.lastName,
            "districtId": Number(data.districtId),
            "cityId": Number(data.cityId),
            "institutionId": Number(process.env.REACT_APP_INSTITUTION_ID),
            "genderId": null,
            "syllabusId": data.syllabusId ? Number(data.syllabusId) : null,
            "courseId": data.courseId ? Number(data.courseId) : null,
            "password": data.password,
            "addressId": data.addressId,
            "addressName": "Home",
            "addressPhone": data.addressPhone,
            "address": data.address,
            "pincode": data.pincode,
            "landmark": null,
            "email": data.email
        }
        let result = await signUp(obj);
        // console.log(result);
        if (!result.status)
            actionNotifier.error("Problem in registration")
        else {
            dispatch(onGetProfile())
            setShowModal(false)
            actionNotifier.success("Profile Update")
        }
        // console.log(result)
        dispatch(apiLoadingEnd())
    }
}
export function onSendSignupOtp(mobileNo, firstName, lastName, types, setOtpLogin, navigate) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let data = {
            "mobileNo": mobileNo,
            "firstName": firstName,
            "lastName": lastName,
            types
        }
        //let encMobile = btoa(data.mobileNo)
        let result = await otpSignup(data);
        if (!result.status) {
            navigate("/")
            actionNotifier.error(result.message)
        }
        else
            setOtpLogin(true)
        // console.log(result)
        dispatch(apiLoadingEnd())
    }
}
export function onOtpSignup(mobileNo, otp, setOtpLogin, navigate) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let data = {
            "mobileNo": mobileNo,
            "otp": otp
        }
        let result = await otpLogin(data);
        // console.log(result)
        if (result.statusCode == 400) {
            actionNotifier.error(result.message.length > 1 ? result.message[1] : result.message[0])
        } else if (result.statusCode == 403) {
            actionNotifier.error(result.message)
        }
        else {
            setOtpLogin(false)
            actionNotifier.success("Registration Successful")
            localStorage.setItem("prepca-login-data", JSON.stringify(result.data))
            dispatch(updateLoginDetails(result.data))
            //navigate('/registration/profile/' + btoa(data.mobileNo));            
            navigate('/plans/bundle');
        }
        dispatch(apiLoadingEnd())
    }
}
export function onThirdPartyLoginSignup(data, navigate) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let result = await thirdPartyLoginSignup(data);
        // console.log(result)
        if (result.status) {
            localStorage.setItem("prepca-login-data", JSON.stringify(result.data))
            dispatch(updateLoginDetails(result.data))
            navigate('/plans/bundle');
        }
        else {
            actionNotifier.error("Invalid credentials.")
        }

        dispatch(apiLoadingEnd())
    }
}
export function onSendOtp(mobileNo, types, navigate) {
    return async (dispatch) => {
        dispatch(apiLoading())
        //alert("action")
        let data = {
            "mobileNo": mobileNo,
            types
        }
        let encMobile = btoa(data.mobileNo)
        let result = await otpSignup(data);
        if (result.data?.state == 'signup') {
            navigate('/user-authentication/signup/' + encMobile);
            actionNotifier.success(result.message)
        } else if (result.data?.state == 'login') {
            navigate('/user-authentication/login/' + encMobile);
            actionNotifier.success(result.message)
        }
        else {
            // actionNotifier.error(result.message)
            navigate("/registration", { state: { mobileNo: data.mobileNo } })
        }
        dispatch(apiLoadingEnd())
    }
}
export function onOtpLoginSignup(mobileNo, otp, navigate) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let data = {
            "mobileNo": mobileNo,
            "otp": otp
        }
        let result = await otpLogin(data);
        // console.log(result)
        let encMobile = btoa(mobileNo)
        if (result.statusCode == 400) {
            actionNotifier.error(result.message.length > 1 ? result.message[1] : result.message[0])
        } else if (result.statusCode == 403) {
            actionNotifier.error(result.message)
        }
        else {
            localStorage.setItem("prepca-login-data", JSON.stringify(result.data))
            dispatch(updateLoginDetails(result.data))
            // console.log(result.data.hasOrder);
            if (result.data) {
                if (otp === "123456") {
                    localStorage.setItem("masterLogin", true)
                } else {
                    localStorage.setItem("masterLogin", false)
                }
                if (result.data.hasOrder)
                    navigate('/dashboard/question');
                else
                    navigate('/dashboard/question');
            }
            // if (result.data.student.firstName) {
            //     navigate('/plans/bundle');
            // } else {
            //     navigate('/registration/profile/' + encMobile);
            // }
        }
        dispatch(apiLoadingEnd())
    }
}
export function onLogin(email, password, navigate) {
    return async (dispatch) => {
        dispatch(apiLoading())
        let data = {
            "email": email,
            "password": password
        }
        let result = await fetchLoginDetails(data);
        if (result.data) {
            localStorage.setItem("prepca-login-data", JSON.stringify(result.data))
            dispatch(updateLoginDetails(result.data))
            if (result.data.hasOrder)
                navigate('/dashboard/question');
            else
                navigate('/dashboard/question');
        }
        else {
            // console.log(result.message)
            actionNotifier.error("Invalid credentials.")
        }
        dispatch(apiLoadingEnd())
    }
}

export const onLogout = (navigate) => {
    return async (dispatch) => {
        // console.log(localStorage);
        localStorage.removeItem("prepca-login-data")
        localStorage.removeItem("masterLogin")
        dispatch(updateLoginDetails([]))
        window.location.href = "/"
    }
}


// ACTIONS
export const updateLoginDetails = (data) => {
    return {
        type: actionTypes.UPDATE_LOGIN_DETAILS,
        payload: data
    };
}

export const importStudentDataFromLocal = () => {
    return (dispatch) => {
        //console.log("cewcwecwecw");
        let data = localStorage.getItem("prepca-login-data")
        //console.log(data)
        if (data) {
            data = JSON.parse(data)
            dispatch(updateLoginDetails(data))
            //navigate('/plans/bundle');
        } else {
            //navigate("/");
        }
    }
}