import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EmailIcon from '@mui/icons-material/Email';
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';





export const HamburgerData = [
    {
        title: "Question",
        path: "/dashboard/question",
        icon: <AssignmentIcon />,
        cName: "nav_text"
    },
    {
        title: "Upload Answers",
        path: "/dashboard/upload-answer",
        icon: <CloudUploadIcon />,
        cName: "nav_text"
    },
    {
        title: "Suggested Answers",
        path: "/dashboard/answer",
        icon: <CalendarMonthIcon />,
        cName: "nav_text"
    },
    {
        title: "Evaluated Answer Sheets",
        path: "/dashboard/evaluated",
        icon: <PictureAsPdfIcon />,
        cName: "nav_text"
    },
    // {
    //     title: "My Feedback",
    //     path: "/dashboard/feedback",
    //     icon: <RateReviewRoundedIcon />,
    //     cName: "nav_text"
    // },
    {
        title: "Download Center",
        path: "/dashboard/download-center",
        icon: <SimCardDownloadIcon />,
        cName: "nav_text"
    },
    {
        title: "Planner",
        path: "/dashboard/planner",
        icon: <CalendarTodayIcon fontSize="small" />,
        cName: "nav_text"
    },
    {
        title: "My Subscription",
        path: "/dashboard/orders",
        icon: <AssignmentTurnedInIcon />,
        cName: "nav_text"
    },
    {
        title: "Counseling",
        path: "/dashboard/counseling",
        icon: <PeopleAltIcon />,
        cName: "nav_text"
    },
    {
        title: "Support",
        path: "https://support.prepca.com/portal/en/home",
        icon: <HeadphonesIcon />,
        cName: "nav_text"
    },
    // {
    //     title: "Referrals Gift",
    //     path: "/dashboard/refer",
    //     icon: <PeopleAltIcon />,
    //     cName: "nav_text"
    // },
    // {
    //     title: "Notifications",
    //     path: "/dashboard/notification",
    //     icon: <NotificationsIcon />,
    //     cName: "nav_text"
    // },
    {
        title: "Profile",
        path: "/dashboard/account",
        icon: <AccountCircleIcon />,
        cName: "nav_text"
    },
    {
        title: "Logout",
        path: "/logout",
        icon: <LogoutIcon />,
        cName: "nav_text"
    },
]